import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { Button, Content, ContentBody, ContentHeader, Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle, usePrismic } from "@buildwise/ui";
import EditProjectModal from "../../components/EditProjectModal/EditProjectModal";
import { deleteProject, getProjects } from "../../adapters/ProjectsAdapter";
import ProjectTable from "../../components/ProjectsTable/ProjectsTable";
import { AppContext } from "../../context/AppContextProvider";
import { config } from "../../_configuration/configuration";

const Projects = () => {
    const { state, dispatch } = useContext(AppContext);
    const [project, setProject] = useState(null);
    const [warning, setWarning] = useState({ visible: false });
    const navigate = useNavigate();

    const [document] = usePrismic(config.prismic.documentType);

    useEffect(() => {
        if (state.projects?.length > 0) return;
        getProjects().then(storeProjects);
    }, []);

    const storeProjects = (projects) => {
        if (projects) dispatch({ type: "SET_PROJECTS", payload: projects });
    };

    const onViewProject = (project) => navigate(`projects/${project.id}`);

    const onEditProject = (project) => setProject(project);

    const onDeleteProject = (project, confirmed = false, result = false) => {
        if (!confirmed) {
            setWarning({
                visible: true,
                title: document.data.calculation_common_remove_title,
                text: document.data.calculation_common_remove_description.replace("{{name}}", project.name),
                callback: (bool) => onDeleteProject(project, true, bool),
            });

            return;
        }

        setWarning({ visible: false });

        if (!result) return;

        deleteProject(project.id).then((success) => removeProject(success, project.id));
    };

    const removeProject = (success, id) => {
        if (!success) return;

        dispatch({ type: "REMOVE_PROJECT", payload: id });
    };

    return (
        <Content>
            <ContentHeader>
                <h1>{document.data.projects_overview_page_title}</h1>
            </ContentHeader>
            <ContentBody>
                <ProjectTable data={state.projects} onView={onViewProject} onEdit={onEditProject} onDelete={onDeleteProject} />
            </ContentBody>

            <EditProjectModal isOpen={Boolean(project)} project={project} onClose={() => setProject(null)} />

            <Modal open={warning.visible} backdrop onClose={() => setWarning({ visible: false })}>
                <ModalHeader closeButton>
                    <ModalTitle>{document.data.calculation_common_remove_title}</ModalTitle>
                </ModalHeader>
                <ModalBody>{warning.text}</ModalBody>
                <ModalFooter>
                    <Button id="projects-warning-cancel-button" variant="tertiary" onClick={() => setWarning({ visible: false })}>
                        {document.data.button_common_cancel}
                    </Button>
                    <Button id="projects-warning-confirm-button" onClick={warning.callback}>{document.data.calculation_common_yes}</Button>
                </ModalFooter>
            </Modal>
        </Content>
    );
};

export default Projects;
