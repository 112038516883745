import { useContext, useEffect, useLayoutEffect, useRef, useState } from "react";
import { useParams, useNavigate, Link, generatePath } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import { Download, Edit, RefreshCw, Upload } from "react-feather";
import { PrismicRichText } from "@prismicio/react";
import {
    Box,
    BoxBody,
    BoxTitle,
    Breadcrumb,
    Button,
    Checkbox,
    Content,
    ContentActions,
    ContentBody,
    ContentFooter,
    ContentHeader,
    ContentInfo,
    Panel,
    PanelBody,
    PanelFooter,
    PanelHeader,
    Separator,
    Spinner,
    useAuthentication,
    useLanguage,
    usePrismic,
} from "@buildwise/ui";
import { AppContext } from "../../context/AppContextProvider";
import CreateCalculationModal from "../../components/CreateCalculationModal/CreateCalculationModal";
import EditCalculationModal from "../../components/EditCalculationModal/EditCalculationModal";
import { getProjects } from "../../adapters/ProjectsAdapter";
import {
    getCalculation,
    getCalculations,
    updateCalculation,
    downloadExcelTemplate,
    importExcelTemplate,
    performCalculation,
} from "../../adapters/GlassThicknessCalculatorAdapter";
import { parseZone } from "./Result";

import { config } from "../../_configuration/configuration";
import styles from "./GlassThickness.module.css";

const GlassThicknessImport = () => {
    const { state, dispatch } = useContext(AppContext);
    const { isAuthenticated } = useAuthentication();
    const [isLoading, setIsLoading] = useState(true);
    const [hasAccess, setHasAccess] = useState(true);
    const navigate = useNavigate();
    const params = useParams();

    const [hasUploadedList, setHasUploadedList] = useState(false);
    const [calculation, setCalculation] = useState();
    const [isEdittingCalculation, setIsEdittingCalculation] = useState(false);

    const [isSaving, setIsSaving] = useState(false);
    const [canSave, setCanSave] = useState(false);

    const [result, setResult] = useState([]);
    const [determinations, setDeterminations] = useState([]);
    const [hasValidFile, setHasValidFile] = useState(false);
    const [selectAll, setSelectAll] = useState(false);

    const [previousCalcId, setPreviousCalcId] = useState(0);
    const fileInputRef = useRef();

    const [isCreatingCalculation, setIsCreatingCalculation] = useState(false);
    const [parsedOutput, setParsedOutput] = useState([]);

    const { language } = useLanguage();
    const [document] = usePrismic(config.prismic.documentType);

    useLayoutEffect(() => {
        ReactTooltip.rebuild();
    });

    useLayoutEffect(() => {
        const fileBrowser = window.document.getElementById("file-upload");
        fileBrowser.addEventListener("change", (e) => onUploadFile(e));
    }, []);

    useLayoutEffect(() => {
        if (isAuthenticated && state.projects.length === 0) getProjects().then(storeProjects);
        else if (!isAuthenticated) setIsLoading(false);
    }, [isAuthenticated]);

    useLayoutEffect(() => {
        if (isAuthenticated) {
            if (params.calcId && params.calcId !== "import") {
                if (!Boolean(state.calculations[params.projectId])) getCalculations(params.projectId).then(storeCalculations);
                else loadCalculation(state.calculations[params.projectId]);

                setPreviousCalcId(params.calcId);
            } else {
                if (previousCalcId > 0) {
                    setCalculation(null);
                    setHasUploadedList(false);
                    setDeterminations([]);
                    setParsedOutput([]);
                }

                setIsLoading(false);
            }
        }
    }, [isAuthenticated, params.projectId, params.calcId, state.calculatorDefinitions]);

    useEffect(() => {
        setSelectAll(determinations.every((x) => x.active === true));
    }, [determinations]);

    useEffect(() => {
        if (calculation) {
            const modified = state.glassThicknessCalculations[params.projectId].find((x) => x.id === calculation.id);
            if (modified)
                setCalculation((x) => ({
                    ...x,
                    title: modified.title,
                    description: modified.description,
                    modificationDate: modified.modificationDate,
                }));
        }
    }, [state.glassThicknessCalculations]);

    useEffect(() => {
        if (parsedOutput.length === 0) {
            setCanSave(false);
            return;
        }

        setCanSave(!isSaving);
    }, [parsedOutput, isSaving]);

    const storeProjects = (projects) => {
        if (projects) {
            dispatch({ type: "SET_PROJECTS", payload: projects });
            if (!params.calcId) {
                if (!projects.find((x) => x.id === Number(params.projectId)))
                    navigate(generatePath(config.routes.newGlassImportCalculation[language], { ...params }));
            }
        }

        setIsLoading(false);
    };

    const storeCalculations = (calculations) => {
        if (calculations) {
            dispatch({
                type: "SET_GLASSTHICKNESS_CALCULATIONS",
                payload: { projectId: params.projectId, calculations: calculations },
            });

            loadCalculation(calculations);
        }
    };

    const loadCalculation = () => {
        getCalculation(params.projectId, params.calcId).then((calculation) => {
            if (calculation) {
                setHasUploadedList(true);
                setIsLoading(false);
                setHasValidFile(true);
                setCalculation(calculation);
                setDeterminations(JSON.parse(calculation.uiBlob));
                setResult(calculation.results);
                setParsedOutput(calculation.results);
            } else {
                setIsLoading(false);
                setHasAccess(false);
            }
        });
    };

    const onSave = () => {
        setIsSaving(true);

        if (!params.calcId) {
            setIsCreatingCalculation(true);
            return;
        }

        updateCalculation(params.projectId, {
            ...calculation,
            calculationResults: result,
        }).then(updateCalculations);
    };

    const updateCalculations = (succes) => {
        if (succes) getCalculation(params.projectId, params.calcId).then(updateStoredCalculation);

        setIsSaving(false);
    };

    const updateStoredCalculation = (calculation) => {
        dispatch({
            type: "UPDATE_GLASSTHICKNESS_CALCULATION",
            payload: {
                projectId: params.projectId,
                calculation: calculation,
            },
        });
    };

    const onEdit = () => {
        setIsEdittingCalculation(true);
    };

    const onCancel = () => {
        if (params.projectId) navigate(generatePath(config.routes.project[language], { ...params, id: params.projectId }));
        else navigate(generatePath(config.routes.landing[language], { ...params }));
    };

    const onUpload = () => {
        fileInputRef.current.click();
    };

    const onDownloadTemplate = () => {
        downloadExcelTemplate(language);
    };

    const onUploadFile = (e) => {
        let files = e.target.files;
        if (!files || !files[0] || !files[0].name) return;

        importExcelTemplate(files[0]).then(handleImportedData);
    };

    const onToggleSelectAll = (checked) => {
        const toggledItems = determinations.map((item) => {
            item.active = checked;

            return item;
        });

        setSelectAll(checked);
        setDeterminations(toggledItems);
    };

    const onCalculate = () => {
        performCalculation(determinations.filter((x) => x.active)).then(handleCalculationResults);
    };

    const handleCalculationResults = async (results) => {
        setResult(results);
        setParsedOutput(parseResults(results));
    };

    const parseResults = (data) => {
        const output = data.calculated.map((x) => ({
            ...x,
            order: findIndex(x.windowName, x.windowId),
            isFailed: false,
        }));

        data.failed.forEach((failedEntry) => {
            output.push({
                windowId: failedEntry.windowId,
                windowName: failedEntry.windowName,
                order: findIndex(failedEntry.windowName, failedEntry.windowId),
                isFailed: true,
            });
        });

        return output.sort((a, b) => a.order - b.order);
    };

    const findIndex = (name, id) => {
        return determinations.findIndex((x) => x.windowId === id && x.windowName === name);
    };

    const handleImportedData = async (data) => {
        fileInputRef.current.value = null;
        setHasUploadedList(true);

        if (data.length > 0) {
            setDeterminations(data);
            setHasValidFile(true);
        }
    };

    const selectRow = (index, checked) => {
        const myList = [...determinations];
        myList[index].active = checked;

        setDeterminations(myList);
        setSelectAll(myList.every((x) => x.active));
    };

    const renderButtons = () => {
        return (
            <>
                <Button id="glass-thickness-import-cancel-button" variant="tertiary" className=" windows-calculation-add" onClick={() => onCancel()} style={{ margin: "5px" }}>
                    {document.data.button_common_cancel}
                </Button>
                {hasUploadedList && (
                    <>
                        {params.calcId && (
                            <Button id="glass-thickness-import-edit-details-button"  disabled={!hasUploadedList} onClick={() => onEdit()} style={{ margin: "5px" }} startIcon={<Edit />}>
                                {document.data.button_common_edit_calculation_details}
                            </Button>
                        )}
                        <Button
                            id="glass-thickness-import-save-button"
                            disabled={(isAuthenticated && !canSave) || isSaving}
                            onClick={() => isAuthenticated && onSave()}
                            style={{ margin: "5px" }}
                            className={isAuthenticated ? "windows-calculation-save" : "windows-calculation-save transparent"}
                            data-for="tooltip"
                            data-tip={document.data.calculation_common_user_feature}
                            data-tip-disable={isAuthenticated}
                        >
                            {isSaving
                                ? document.data.button_common_busy_saving
                                : params.calcId === null
                                ? document.data.button_common_save_as
                                : document.data.button_common_save}
                        </Button>
                    </>
                )}
            </>
        );
    };

    return (
        <>
            <Breadcrumb>
                {Boolean(state.projects.find((x) => x.id === Number(params.projectId))) && (
                    <Link id="glass-thickness-import-breadcrumb-link" to={generatePath(config.routes.project[language], { ...params, id: params.projectId })}>
                        {state.projects.find((x) => x.id === Number(params.projectId)).name}
                    </Link>
                )}
            </Breadcrumb>
            {!Boolean(params.calcId) && <Breadcrumb>{document.data.calculation_glass_thickness_title}</Breadcrumb>}
            {calculation && calculation.title && <Breadcrumb>{calculation.title}</Breadcrumb>}
            <input type="file" id="file-upload" style={{ display: "none" }} accept=".xls,.xlsx" ref={fileInputRef} />
            {isLoading ? (
                <Content>
                    <Spinner />
                </Content>
            ) : hasAccess ? (
                <>
                    <Content>
                        <ContentHeader className={styles.header}>
                            <h1>{calculation ? calculation.title : document.data.calculation_glass_thickness_title}</h1>
                            <ContentActions className={styles.actions}>{renderButtons()}</ContentActions>
                        </ContentHeader>
                        <Separator color="purple">
                            {document.data.project_overview_glass_thickness_title} - {document.data.calculation_glass_thickness_with_list}
                        </Separator>
                        <ContentInfo style={{ flexWrap: "wrap" }}>
                            <div className={styles.projectinfo}>
                                {calculation && calculation.id > 0 ? (
                                    <>
                                        <h4>{document.data.project_overview_description_title}</h4>
                                        <p>{calculation.description}</p>
                                    </>
                                ) : (
                                    <p>{document.data.calculation_glass_thickness_description}</p>
                                )}
                            </div>
                            <div>
                                {calculation && calculation.id > 0 && (
                                    <>
                                        <p>
                                            <strong>{document.data.calculation_common_creation_date}</strong>{" "}
                                            {new Date(calculation.creationDate).toLocaleDateString("nl-BE")}
                                        </p>
                                        <p>
                                            <strong>{document.data.calculation_common_modification_date}</strong>{" "}
                                            {new Date(calculation.modificationDate).toLocaleDateString("nl-BE")}
                                        </p>
                                    </>
                                )}
                            </div>
                        </ContentInfo>
                        <ContentBody className={styles.importinfo}>
                            {!hasUploadedList ? (
                                <>
                                    <Panel className={styles.split}>
                                        <PanelHeader>
                                            <h2 style={{ fontSize: "28px" }}>{document.data.calculation_glass_thickness_import_determination_with_list}</h2>
                                        </PanelHeader>
                                        <PanelBody>
                                            <p>{document.data.calculation_glass_thickness_import_determination_with_list_description}</p>
                                        </PanelBody>
                                        <PanelFooter>
                                            <Button id="glass-thickness-import-upload-list-button" startIcon={<Upload />} onClick={() => onUpload()}>
                                                {document.data.button_calculation_glass_thickness_import_list}
                                            </Button>
                                        </PanelFooter>
                                    </Panel>
                                    <Panel className={styles.split}>
                                        <PanelHeader>
                                            <h2 style={{ fontSize: "28px" }}>{document.data.calculation_glass_thickness_first_time_import}</h2>
                                        </PanelHeader>
                                        <PanelFooter>
                                            <Button id="glass-thickness-import-download-template-button" variant="secondary" startIcon={<Download />} onClick={() => onDownloadTemplate()}>
                                                {document.data.button_calculation_glass_thickness_download_list}
                                            </Button>
                                        </PanelFooter>
                                    </Panel>
                                </>
                            ) : (
                                <>
                                    <Panel>
                                        {hasValidFile ? (
                                            <>
                                                <PanelBody className={styles.tableActions}>
                                                    <Checkbox
                                                        label={document.data.checkbox_calculation_glass_thickness_select_all_rows}
                                                        disabled={!hasValidFile}
                                                        onChange={(e) => onToggleSelectAll(e.target.checked)}
                                                        checked={selectAll}
                                                    />

                                                    <Button
                                                        id="glass-thickness-import-calculate-button"
                                                        onClick={() => onCalculate()}
                                                        disabled={!hasValidFile || determinations.filter((x) => x.active).length === 0}
                                                    >
                                                        {document.data.button_calculation_glass_thickness_determine_selection}
                                                    </Button>

                                                    <Button
                                                        id="glass-thickness-import-upload-updated-list-button"
                                                        variant="secondary"
                                                        style={{ marginLeft: "auto" }}
                                                        disabled={!hasValidFile}
                                                        startIcon={<RefreshCw />}
                                                        onClick={() => onUpload()}
                                                    >
                                                        {document.data.button_calculation_glass_thickness_import_list_update}
                                                    </Button>
                                                </PanelBody>

                                                <PanelBody className={styles.tableContainer}>
                                                    <table className={styles.table}>
                                                        <thead>
                                                            <tr>
                                                                <th></th>
                                                                <th>{document.data.calculation_glass_thickness_import_data_table_header_name}</th>
                                                                <th>{document.data.calculation_glass_thickness_import_data_table_header_id}</th>
                                                                <th>{document.data.calculation_glass_thickness_import_data_table_header_postal_code}</th>
                                                                <th>{document.data.calculation_glass_thickness_import_data_table_header_roughness}</th>
                                                                <th>{document.data.calculation_glass_thickness_import_data_table_header_building_height_h}</th>
                                                                <th>{document.data.calculation_glass_thickness_import_data_table_header_building_width_b}</th>
                                                                <th>{document.data.calculation_glass_thickness_import_data_table_header_building_depth_d}</th>
                                                                <th>{document.data.calculation_glass_thickness_import_data_table_header_glass_type}</th>
                                                                <th>{document.data.calculation_glass_thickness_import_data_table_header_glass_height}</th>
                                                                <th>{document.data.calculation_glass_thickness_import_data_table_header_glass_width}</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {determinations.length > 0 &&
                                                                determinations.map((item, i) => (
                                                                    <tr key={i}>
                                                                        <th>
                                                                            <Checkbox
                                                                                onChange={(e) => selectRow(i, e.target.checked)}
                                                                                checked={item.active ?? false}
                                                                            />
                                                                        </th>

                                                                        <td>{item.windowName}</td>
                                                                        <td>{item.windowId}</td>
                                                                        <td>{item.postalCode}</td>
                                                                        <td>{item.roughnessClass}</td>
                                                                        <td>{item.buildingHeight}m</td>
                                                                        <td>{item.facadeWidth}m</td>
                                                                        <td>{item.facadeWidthPerpendicular}m</td>
                                                                        <td>{document[`glass_type_${item.glassType}`]}</td>
                                                                        <td>{item.windowHeight}m</td>
                                                                        <td>{item.windowWidth}m</td>
                                                                    </tr>
                                                                ))}
                                                        </tbody>
                                                    </table>
                                                </PanelBody>
                                            </>
                                        ) : (
                                            <PanelBody className={styles.tableActions}>
                                                <p
                                                    style={{
                                                        color: "red",
                                                        fontStyle: "italic",
                                                    }}
                                                >
                                                    <PrismicRichText field={document.data.calculation_common_error_message_import} />
                                                </p>

                                                <div style={{ display: "flex", marginTop: "20px" }}>
                                                    <Button id="glass-thickness-import-calculate-list-button" onClick={() => onUpload()} startIcon={<Upload />} style={{ marginRight: "10px" }}>
                                                        {document.data.button_calculation_glass_thickness_import_list}
                                                    </Button>

                                                    <Button id="glass-thickness-import-download-template-button" variant="secondary" onClick={() => onDownloadTemplate()} startIcon={<Download />}>
                                                        {document.data.button_calculation_glass_thickness_download_list}
                                                    </Button>
                                                </div>
                                            </PanelBody>
                                        )}
                                    </Panel>

                                    {parsedOutput.length > 0 && (
                                        <Panel>
                                            <PanelBody className={styles.tableContainer}>
                                                <table className={styles.resulttable}>
                                                    <thead>
                                                        <tr>
                                                            <th>{document.data.calculation_glass_thickness_result_data_table_header_name}</th>
                                                            <th>{document.data.calculation_glass_thickness_result_data_table_header_id}</th>
                                                            <th>{document.data.calculation_glass_thickness_result_data_table_header_result_glass_thickness}</th>
                                                            <th>{document.data.calculation_glass_thickness_result_data_table_header_zone_1}</th>
                                                            <th>{document.data.calculation_glass_thickness_result_data_table_header_zone_2}</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {parsedOutput.map((item, i) => (
                                                            <tr key={i}>
                                                                <th>{item.windowName}</th>
                                                                <td>{item.windowId}</td>
                                                                {item.isFailed ? (
                                                                    <td colSpan={3}>{document.data.calculation_common_error_title_invalid_values}</td>
                                                                ) : (
                                                                    <>
                                                                        <td>
                                                                            {`e < 5d = ${
                                                                                result.elt5d
                                                                                    ? document.data.calculation_glass_thickness_true
                                                                                    : document.data.calculation_glass_thickness_true
                                                                            }`}
                                                                        </td>
                                                                        <td>{parseZone(item.zone1)}</td>
                                                                        <td>{parseZone(item.zone2)}</td>
                                                                    </>
                                                                )}
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </PanelBody>
                                            <PanelBody style={{ marginTop: "32px" }}>
                                                <Box useAccent style={{ margin: "unset" }}>
                                                    <BoxTitle>{document.data.calculation_glass_thickness_result_remarks_title}</BoxTitle>
                                                    <BoxBody className={styles.results}>
                                                        {document.data.calculation_glass_thickness_result_remarks_description}
                                                    </BoxBody>
                                                </Box>
                                            </PanelBody>
                                        </Panel>
                                    )}
                                </>
                            )}
                        </ContentBody>
                        <ContentFooter>
                            <ContentActions className={styles.actions}>{renderButtons()}</ContentActions>
                        </ContentFooter>
                    </Content>

                    {isAuthenticated && (
                        <>
                            {isCreatingCalculation && (
                                <CreateCalculationModal
                                    isOpen={isCreatingCalculation}
                                    onClose={() => {
                                        setIsCreatingCalculation(false);
                                        setIsSaving(false);
                                    }}
                                    calculation={{
                                        calculationAppId: 3,
                                        calculationResults: result.calculated,
                                        isMatrix: true,
                                        uiBlob: JSON.stringify(determinations),
                                    }}
                                />
                            )}

                            {isEdittingCalculation && (
                                <EditCalculationModal
                                    isOpen={isEdittingCalculation}
                                    onClose={() => {
                                        setIsEdittingCalculation(false);
                                    }}
                                    calculation={{ ...calculation, calculationAppId: "GlassThickness" }}
                                />
                            )}
                        </>
                    )}
                </>
            ) : (
                <Content>
                    <ContentBody>
                        <Panel>
                            <PanelHeader>
                                <h2>{document.data.calculation_common_calculation_not_found}</h2>
                            </PanelHeader>
                            <PanelBody>{document.data.calculation_common_calculation_not_found_details}</PanelBody>
                        </Panel>
                    </ContentBody>
                </Content>
            )}
        </>
    );
};

export default GlassThicknessImport;
