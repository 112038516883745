import { PrismicRichText } from "@prismicio/react";
import { Trash2, Edit } from "react-feather";
import { Box, BoxBody, BoxTitle, Button, PanelBody, PanelFooter, usePrismic } from "@buildwise/ui";

import { config } from "../../_configuration/configuration";
import styles from "./GlassThickness.module.css";

export const parseZone = (value) => {
    if (value.indexOf("+") === -1) return value;

    const split = value.split("+");
    if (split.length === 2) return split.join("-16-");
    if (split.length === 3) return split.join("-12-");
};

const Result = ({ result, glassTypes, onEdit, onRemove }) => {
    const [document] = usePrismic(config.prismic.documentType);

    return (
        <>
            <PanelBody>
                <Box useAccent>
                    <BoxTitle>{document.data.calculation_glass_thickness_result_description}</BoxTitle>
                    <BoxBody className={styles.results} style={{ flexWrap: "wrap-reverse" }}>
                        <div style={{ flex: "50%" }}>
                            <div className={styles.block}>
                                <p className={styles.input}>
                                    <span className={styles.key}>{document.data.calculation_glass_thickness_result_glass_thickness}</span>

                                    <span className={styles.value}>{`e < 5d = ${
                                        result.elt5d ? document.data.calculation_glass_thickness_true : document.data.calculation_glass_thickness_false
                                    }`}</span>
                                </p>
                            </div>

                            <div className={styles.block}>
                                <p className={styles.input}>
                                    <span className={styles.key}>{document.data.calculation_glass_thickness_zone_1}</span>
                                    <span className={styles.value}>{parseZone(result.zone1)}</span>
                                </p>
                            </div>

                            <div className={styles.block}>
                                <p className={styles.input}>
                                    <span className={styles.key}>{document.data.calculation_glass_thickness_zone_2}</span>
                                    <span className={styles.value}>{parseZone(result.zone2)}</span>
                                </p>
                            </div>
                        </div>
                        <div style={{ flex: "50%" }}>
                            <img
                                src={document.data.calculation_glass_thickness_building_data_result_image.url}
                                style={{ minWidth: "300px", maxWidth: "calc(100% - 10px)", margin: 5, border: "1px solid #93aab2", borderRadius: 3 }}
                                alt=""
                            />
                        </div>
                    </BoxBody>
                </Box>

                <Box useAccent>
                    <BoxTitle>{document.data.calculation_glass_thickness_general_remark}</BoxTitle>
                    <BoxBody>
                        <PrismicRichText field={document.data.calculation_glass_thickness_general_remark_description} />
                    </BoxBody>
                </Box>

                <Box>
                    <BoxTitle>{document.data.calculation_glass_thickness_based_on_properties}</BoxTitle>
                    <BoxBody className={styles.params}>
                        <p>
                            <strong>{document.data.calculation_glass_thickness_glass_type}</strong>
                            <span>{document.data[`glass_type_${result.glassType}`]}</span>
                        </p>
                        <p>
                            <strong>{document.data.calculation_glass_thickness_visible_glass_width}</strong>
                            <span>{result.windowWidth} m</span>
                        </p>
                        <p>
                            <strong>{document.data.calculation_glass_thickness_visible_glass_height}</strong>
                            <span>{result.windowHeight} m</span>
                        </p>
                        <p>
                            <strong>{document.data.calculation_glass_thickness_location}</strong>
                            <span>
                                {result.postalCode} {result.city}: {result.referenceSpeed} m/s
                            </span>
                        </p>
                        <p>
                            <strong>{document.data.calculation_glass_thickness_roughness_category}</strong>
                            <span>{result.roughnessClass}</span>
                        </p>
                        <p>
                            <strong>{document.data.calculation_glass_thickness_building_height_h}</strong>
                            <span>{result.buildingHeight} m</span>
                        </p>
                        <p>
                            <strong>{document.data.calculation_glass_thickness_building_width_b}</strong>
                            <span>{result.facadeWidth} m</span>
                        </p>
                        <p>
                            <strong>{document.data.calculation_glass_thickness_building_depth_d}</strong>
                            <span>{result.facadeWidthPerpendicular} m</span>
                        </p>
                    </BoxBody>
                </Box>
            </PanelBody>
            <PanelFooter>
                <Button id="glass-thickness-determination-delete-button" variant="tertiary" onClick={onRemove} startIcon={<Trash2 />}>
                    {document.data.button_common_delete_determination}
                </Button>
                <Button id="glass-thickness-determination-edit-button" variant="secondary" onClick={onEdit} startIcon={<Edit />} style={{ marginLeft: "auto" }}>
                    {document.data.button_common_edit_determination}
                </Button>
            </PanelFooter>
        </>
    );
};

export default Result;
