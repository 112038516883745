import { useState, useEffect, useRef } from "react";
import { ArrowLeft, ArrowRight, Edit, Trash2 } from "react-feather";
import { PrismicRichText } from "@prismicio/react";
import { asText } from "@prismicio/helpers";
import {
    Button,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    ModalTitle,
    Panel,
    PanelBody,
    PanelFooter,
    PanelHeader,
    QuestionBox,
    QuestionBoxBody,
    QuestionBoxImage,
    QuestionBoxTitle,
    Radio,
    SelectGroup,
    SelectGroupItem,
    SelectGroupItemDescription,
    SelectGroupItemImage,
    SelectGroupItemTitle,
    Step,
    Stepper,
    Text,
    usePrismic,
} from "@buildwise/ui";
import Result from "./Result";
import { performCalculation } from "../../adapters/CalculatorAdapter";

import { config } from "../../_configuration/configuration";
import styles from "./Panels.module.css";

const Calculator = (props) => {
    const [warning, setWarning] = useState({ visible: false });

    const [step, setStep] = useState(0);

    const [isGlass, setIsGlass] = useState(true);
    const [couldFallThroughWhenOpened, setCouldFallThroughWhenOpened] = useState(false);
    const [protectionHeight, setProtectionHeight] = useState(90);
    const [heightAboveInteriorFloor, setHeightAboveInteriorFloor] = useState("");
    const [heightAboveExteriorGround, setHeightAboveExteriorGround] = useState("");
    const [interiorFallHeight, setInteriorFallHeight] = useState(1);
    const [exteriorFallHeight, setExteriorFallHeight] = useState(1);
    const [width, setWidth] = useState("");
    const [height, setHeight] = useState("");
    const [surface, setSurface] = useState("");

    const [interiorUseClass, setInteriorUseClass] = useState("");
    const [otherInteriorShocksPossible, setOtherInteriorShocksPossible] = useState(false);
    const [otherExteriorShocksPossible, setOtherExteriorShocksPossible] = useState(false);
    const [isInteriorRailingPresent, setIsInteriorRailingPresent] = useState(false);
    const [isExteriorRailingPresent, setIsExteriorRailingPresent] = useState(false);
    const [isExteriorPubliclyAccessible, setIsExteriorPubliclyAccessible] = useState(false);

    const [result, setResult] = useState({});
    const tempIdRef = useRef(1);
    const headerRef = useRef();

    const [document] = usePrismic(config.prismic.documentType);

    useEffect(() => {
        headerRef.current = window.document.getElementById(`calc-${props.i}`);
    }, []);

    const goToStep = (step) => {
        const validation = validateInput(step);
        if (!validation.valid) {
            setWarning({
                visible: true,
                prompt: false,
                title: document.data.calculation_common_error_title_invalid_properties,
                text: (
                    <>
                        <p>{document.data.calculation_common_error_message_invalid_properties}</p>
                        <ul>
                            {validation.errors.map((error, index) => (
                                <li key={index}>
                                    <span
                                        dangerouslySetInnerHTML={{
                                            __html: error,
                                        }}
                                    />
                                </li>
                            ))}
                        </ul>
                    </>
                ),
                callback: (bool) => setWarning({ visible: false }),
            });

            return false;
        }

        setStep(step);
        headerRef.current.scrollIntoView();
    };

    useEffect(() => {
        if (Object.keys(props.result).length > 1) {
            setStep(2);
            setResult(props.result);

            if (!props.result.inputParameters || props.result.inputParameters.length === 0) return;

            const input = props.result.inputParameters.sort((a, b) => (a.parameterId > b.parameterId ? 1 : -1));

            setIsGlass(input[2].value === "True");
            setProtectionHeight(input[3].value / 10);
            setInteriorUseClass(input[4].value);
            setIsExteriorPubliclyAccessible(input[5].value === "True");
            setOtherInteriorShocksPossible(input[6].value === "True");
            setOtherExteriorShocksPossible(input[7].value === "True");
            setWidth(input[8].value / 10);
            setHeight(input[9].value / 10);
            setSurface(input[10].value / 1000000);
            setHeightAboveInteriorFloor(input[11].value / 10);
            setHeightAboveExteriorGround(input[12].value / 10);
            setExteriorFallHeight(Number(input[13].value));
            setIsInteriorRailingPresent(input[14].value === "True");
            setIsExteriorRailingPresent(input[15].value === "True");
            setCouldFallThroughWhenOpened(input[16].value === "True");
            setInteriorFallHeight(input[17] && Number(input[17].value));
        } else {
            setIsGlass(true);
            setProtectionHeight(90);
            setInteriorUseClass("");
            setIsExteriorPubliclyAccessible(false);
            setOtherInteriorShocksPossible(false);
            setOtherExteriorShocksPossible(false);
            setWidth("");
            setHeight("");
            setSurface("");
            setHeightAboveInteriorFloor("");
            setHeightAboveExteriorGround("");
            setInteriorFallHeight(1);
            setExteriorFallHeight(1);
            setIsInteriorRailingPresent(false);
            setIsExteriorRailingPresent(false);
            setCouldFallThroughWhenOpened(false);
            setStep(0);
        }
    }, [props.result]);

    const cancelEdit = () => {
        goToStep(2);

        const input = props.result.inputParameters.sort((a, b) => (a.parameterId > b.parameterId ? 1 : -1));

        setIsGlass(input[2].value === "True");
        setProtectionHeight(input[3].value / 10);
        setInteriorUseClass(input[4].value);
        setIsExteriorPubliclyAccessible(input[5].value === "True");
        setOtherInteriorShocksPossible(input[6].value === "True");
        setOtherExteriorShocksPossible(input[7].value === "True");
        setWidth(input[8].value / 10);
        setHeight(input[9].value / 10);
        setSurface(input[10].value / 1000000);
        setHeightAboveInteriorFloor(input[11].value / 10);
        setHeightAboveExteriorGround(input[12].value / 10);
        setExteriorFallHeight(Number(input[13].value));
        setIsInteriorRailingPresent(input[14].value === "True");
        setIsExteriorRailingPresent(input[15].value === "True");
        setCouldFallThroughWhenOpened(input[16].value === "True");
        setInteriorFallHeight(input[17] && Number(input[17].value));
    };

    const validateInput = (step) => {
        const errors = [];

        switch (step) {
            case 1: {
                if (protectionHeight === "" || Number(protectionHeight) < 90 || Number(protectionHeight) > 120)
                    errors.push(document.data.calculation_common_invalid_protection_height);

                if (heightAboveInteriorFloor === "" || Number(heightAboveInteriorFloor) <= 0)
                    errors.push(document.data.calculation_common_invalid_interior_height_hi);

                if (heightAboveExteriorGround === "" || Number(heightAboveExteriorGround) <= 0)
                    errors.push(document.data.calculation_common_invalid_exterior_height_he);

                if (width === "" || Number(width) <= 0) errors.push(document.data.calculation_common_invalid_panel_width);

                if (height === "" || Number(height) <= 0) errors.push(document.data.calculation_common_invalid_panel_height);

                if (surface === "" || Number(surface) <= 0) errors.push(document.data.calculation_common_invalid_panel_surface);

                if (interiorFallHeight === "" || Number(interiorFallHeight) <= 0) errors.push(document.data.calculation_common_invalid_interior_drop_height);

                if (exteriorFallHeight === "" || Number(exteriorFallHeight) <= 0) errors.push(document.data.calculation_common_invalid_exterior_drop_height);
                break;
            }

            case 2: {
                if (interiorUseClass === "") errors.push(document.data.calculation_common_invalid_category_of_use);

                break;
            }

            default:
                break;
        }

        return {
            valid: errors.length === 0,
            errors,
        };
    };

    const calculate = () => {
        const validation = validateInput(2);
        if (!validation.valid) {
            setWarning({
                visible: true,
                prompt: false,
                title: document.data.calculation_common_error_title_invalid_properties,
                text: (
                    <>
                        <p>{document.data.calculation_common_error_message_invalid_properties}</p>
                        <ul>
                            {validation.errors.map((error, index) => (
                                <li key={index}>
                                    <span
                                        dangerouslySetInnerHTML={{
                                            __html: error,
                                        }}
                                    />
                                </li>
                            ))}
                        </ul>
                    </>
                ),
                callback: (bool) => setWarning({ visible: false }),
            });

            return false;
        }

        const body = {
            isGlass: isGlass,
            protectionHeight: parseFloat(protectionHeight * 10),
            InteriorUseClass: interiorUseClass.id,
            isExteriorPubliclyAccessible: isExteriorPubliclyAccessible,
            OtherInteriorShocksPossible: otherInteriorShocksPossible,
            OtherExteriorShocksPossible: otherExteriorShocksPossible,
            Width: parseFloat(width * 10),
            Height: parseFloat(height * 10),
            Surface: parseFloat(surface * 1000000),
            HeightAboveInteriorFloor: parseFloat(heightAboveInteriorFloor * 10),
            HeightAboveExteriorGround: parseFloat(heightAboveExteriorGround * 10),
            InteriorFallHeight: parseFloat(interiorFallHeight),
            ExteriorFallHeight: parseFloat(exteriorFallHeight),
            IsInteriorRailingPresent: isInteriorRailingPresent,
            IsExteriorRailingPresent: isExteriorRailingPresent,
            CouldFallThroughWhenOpened: couldFallThroughWhenOpened,
            interiorUseClass: interiorUseClass,
        };

        performCalculation(2, body).then(handleCalculationResult);
    };

    const handleCalculationResult = (calculation) => {
        const storedRes = { ...result };
        const newResult = calculation.calculationResults[0];
        newResult.tempId = props.data.tempId;
        tempIdRef.current += 1;

        if (storedRes.id > 0) newResult.id = storedRes.id;
        else newResult.id = -1;

        setResult(newResult);

        props.callBack(newResult);

        goToStep(2);
    };

    const interiorUseClasses = [
        {
            id: "A",
            title: <PrismicRichText field={document.data.calculation_panels_category_of_use_a_title} />,
            description: <PrismicRichText field={document.data.calculation_panels_category_of_use_a_description} />,
        },
        {
            id: "B",
            title: <PrismicRichText field={document.data.calculation_panels_category_of_use_b_title} />,
        },
        {
            id: "C1",
            title: <PrismicRichText field={document.data.calculation_panels_category_of_use_c1_title} />,
            description: <PrismicRichText field={document.data.calculation_panels_category_of_use_c1_description} />,
        },
        {
            id: "C2",
            title: <PrismicRichText field={document.data.calculation_panels_category_of_use_c2_title} />,
            description: <PrismicRichText field={document.data.calculation_panels_category_of_use_c2_description} />,
        },
        {
            id: "C3",
            title: <PrismicRichText field={document.data.calculation_panels_category_of_use_c3_title} />,
            description: <PrismicRichText field={document.data.calculation_panels_category_of_use_c3_description} />,
        },
        {
            id: "C4",
            title: <PrismicRichText field={document.data.calculation_panels_category_of_use_c4_title} />,
            description: <PrismicRichText field={document.data.calculation_panels_category_of_use_c4_description} />,
        },
        {
            id: "C5",
            title: <PrismicRichText field={document.data.calculation_panels_category_of_use_c5_title} />,
            description: <PrismicRichText field={document.data.calculation_panels_category_of_use_c5_description} />,
        },
        {
            id: "D1",
            title: <PrismicRichText field={document.data.calculation_panels_category_of_use_d1_title} />,
            description: <PrismicRichText field={document.data.calculation_panels_category_of_use_d1_description} />,
        },
        {
            id: "E",
            title: <PrismicRichText field={document.data.calculation_panels_category_of_use_e_title} />,
            description: <PrismicRichText field={document.data.calculation_panels_category_of_use_e_description} />,
        },
    ];

    return (
        <>
            <Panel id="calculation">
                <PanelHeader>
                    <h2 style={{ marginBottom: "32px" }} id={`calc-${props.i}`}>
                        {step < 2 ? document.data.calculation_panels_determination : document.data.calculation_panels_result} {props.i + 1}
                    </h2>
                </PanelHeader>
                <PanelBody>
                    <Stepper step={step} onChangeStep={(from, target) => goToStep(target)}>
                        <Step label={document.data.calculation_panels_stepper_dimensions}>
                            <QuestionBox style={{ marginTop: "25px" }}>
                                <QuestionBoxTitle>{document.data.calculation_panels_material_glass_question}</QuestionBoxTitle>
                                <QuestionBoxBody className={styles.maxParagraphWidth}>
                                    <p className={styles.questionDetails}>{document.data.calculation_panels_material_glass_information}</p>
                                    <Radio label={document.data.calculation_common_yes} checked={isGlass} onChange={() => setIsGlass(true)} />
                                    <Radio label={document.data.calculation_common_no} checked={!isGlass} onChange={() => setIsGlass(false)} />
                                </QuestionBoxBody>
                            </QuestionBox>

                            <QuestionBox>
                                <QuestionBoxTitle>{document.data.calculation_panels_fall_through_question}</QuestionBoxTitle>
                                <QuestionBoxBody className={styles.maxParagraphWidth}>
                                    <div className={styles.questionDetails}>
                                        <PrismicRichText field={document.data.calculation_panels_fall_through_information} />
                                    </div>
                                    <Radio
                                        label={document.data.calculation_common_yes}
                                        checked={couldFallThroughWhenOpened}
                                        onChange={() => setCouldFallThroughWhenOpened(true)}
                                    />
                                    <Radio
                                        label={document.data.calculation_common_no}
                                        checked={!couldFallThroughWhenOpened}
                                        onChange={() => setCouldFallThroughWhenOpened(false)}
                                    />
                                </QuestionBoxBody>
                            </QuestionBox>

                            <QuestionBox>
                                <QuestionBoxTitle>{document.data.calculation_panels_protection_height_question}</QuestionBoxTitle>
                                <QuestionBoxBody className={styles.maxParagraphWidth}>
                                    <div className={styles.questionDetails}>
                                        <PrismicRichText field={document.data.calculation_panels_protection_height_information} />
                                    </div>
                                    <Text
                                        type="text"
                                        placeholder={document.data.calculation_panels_protection_height_placeholder}
                                        legend="cm"
                                        value={protectionHeight}
                                        onChange={(e) => setProtectionHeight(e.target.value)}
                                        onValidate={(value) => Number(value) >= 90 && Number(value) <= 120}
                                        validationError={document.data.calculation_panels_protection_height_error}
                                        required
                                    />
                                </QuestionBoxBody>
                            </QuestionBox>

                            <QuestionBox>
                                <QuestionBoxTitle>
                                    <span
                                        dangerouslySetInnerHTML={{
                                            __html: asText(document.data.calculation_panels_interior_height_question),
                                        }}
                                    ></span>
                                </QuestionBoxTitle>
                                <QuestionBoxBody className={styles.maxParagraphWidth}>
                                    <div
                                        className={styles.questionDetails}
                                        dangerouslySetInnerHTML={{
                                            __html: asText(document.data.calculation_panels_interior_height_information),
                                        }}
                                    ></div>
                                    <Text
                                        type="text"
                                        placeholder={document.data.calculation_panels_interior_height_placeholder}
                                        legend="cm"
                                        value={heightAboveInteriorFloor}
                                        onChange={(e) => setHeightAboveInteriorFloor(e.target.value)}
                                        required
                                    />
                                </QuestionBoxBody>
                                <QuestionBoxImage src={couldFallThroughWhenOpened ? "/img/panels/07.svg" : "/img/panels/02.svg"} />
                            </QuestionBox>

                            <QuestionBox>
                                <QuestionBoxTitle>
                                    <span
                                        dangerouslySetInnerHTML={{
                                            __html: asText(document.data.calculation_panels_exterior_height_question),
                                        }}
                                    ></span>
                                </QuestionBoxTitle>
                                <QuestionBoxBody className={styles.maxParagraphWidth}>
                                    <div
                                        className={styles.questionDetails}
                                        dangerouslySetInnerHTML={{
                                            __html: asText(document.data.calculation_panels_exterior_height_information),
                                        }}
                                    ></div>
                                    <Text
                                        type="text"
                                        placeholder={document.data.calculation_panels_exterior_height_placeholder}
                                        legend="cm"
                                        value={heightAboveExteriorGround}
                                        onChange={(e) => setHeightAboveExteriorGround(e.target.value)}
                                        required
                                    />
                                </QuestionBoxBody>
                                <QuestionBoxImage src={couldFallThroughWhenOpened ? "/img/panels/07.svg" : "/img/panels/02.svg"} />
                            </QuestionBox>

                            {heightAboveExteriorGround !== "" && Number(heightAboveExteriorGround) < protectionHeight && (
                                <QuestionBox>
                                    <QuestionBoxTitle>
                                        <span
                                            dangerouslySetInnerHTML={{
                                                __html: asText(document.data.calculation_panels_interior_drop_height_question),
                                            }}
                                        ></span>
                                    </QuestionBoxTitle>
                                    <QuestionBoxBody className={styles.maxParagraphWidth}>
                                        <div
                                            className={styles.questionDetails}
                                            dangerouslySetInnerHTML={{
                                                __html: asText(document.data.calculation_panels_interior_drop_height_information),
                                            }}
                                        ></div>
                                        <Radio
                                            label={document.data.calculation_panels_smaller_or_equal_to_one_and_a_half}
                                            checked={interiorFallHeight === 1}
                                            onChange={() => setInteriorFallHeight(1)}
                                        />
                                        <Radio
                                            label={document.data.calculation_panels_between_one_and_a_half_and_twelve}
                                            checked={interiorFallHeight === 110000}
                                            onChange={() => setInteriorFallHeight(110000)}
                                        />
                                        <Radio
                                            label={document.data.calculation_panels_larger_or_equal_to_twelve}
                                            checked={interiorFallHeight === 120000}
                                            onChange={() => setInteriorFallHeight(120000)}
                                        />
                                    </QuestionBoxBody>
                                    <QuestionBoxImage src={"/img/panels/04.svg"} />
                                </QuestionBox>
                            )}

                            {heightAboveInteriorFloor !== "" && Number(heightAboveInteriorFloor) < protectionHeight && (
                                <QuestionBox>
                                    <QuestionBoxTitle>
                                        <span
                                            dangerouslySetInnerHTML={{
                                                __html: asText(document.data.calculation_panels_exterior_drop_height_question),
                                            }}
                                        ></span>
                                    </QuestionBoxTitle>
                                    <QuestionBoxBody className={styles.maxParagraphWidth}>
                                        <div
                                            className={styles.questionDetails}
                                            dangerouslySetInnerHTML={{
                                                __html: asText(document.data.calculation_panels_interior_drop_height_information),
                                            }}
                                        ></div>
                                        <Radio
                                            label={document.data.calculation_panels_smaller_or_equal_to_one_and_a_half}
                                            checked={exteriorFallHeight === 1}
                                            onChange={() => setExteriorFallHeight(1)}
                                        />
                                        <Radio
                                            label={document.data.calculation_panels_between_one_and_a_half_and_twelve}
                                            checked={exteriorFallHeight === 110000}
                                            onChange={() => setExteriorFallHeight(110000)}
                                        />
                                        <Radio
                                            label={document.data.calculation_panels_larger_or_equal_to_twelve}
                                            checked={exteriorFallHeight === 120000}
                                            onChange={() => setExteriorFallHeight(120000)}
                                        />
                                    </QuestionBoxBody>
                                    <QuestionBoxImage src={"/img/panels/03.svg"} />
                                </QuestionBox>
                            )}

                            <QuestionBox className={styles.fullWidthQuestion}>
                                <QuestionBoxTitle>
                                    <span>{document.data.calculation_panels_dimensions_question}</span>
                                </QuestionBoxTitle>
                                <QuestionBoxBody className={styles.maxParagraphWidth}>
                                    <div className={styles.questionDetails}>
                                        <PrismicRichText field={document.data.calculation_panels_dimensions_information} />
                                    </div>
                                    <div className={styles.dimensions}>
                                        <div>
                                            <strong>{document.data.calculation_panels_width}</strong>
                                            <Text
                                                type="text"
                                                placeholder={document.data.calculation_panels_width_placeholder}
                                                legend="cm"
                                                value={width}
                                                onChange={(e) => {
                                                    setWidth(e.target.value);
                                                    setSurface((e.target.value * height) / 10000);
                                                }}
                                                required
                                            />
                                        </div>
                                        <div>
                                            <strong>{document.data.calculation_panels_height}</strong>
                                            <Text
                                                type="text"
                                                placeholder={document.data.calculation_panels_height_placeholder}
                                                legend="cm"
                                                value={height}
                                                onChange={(e) => {
                                                    setHeight(e.target.value);
                                                    setSurface((width * e.target.value) / 10000);
                                                }}
                                                required
                                            />
                                        </div>
                                        <div>
                                            <strong>{document.data.calculation_panels_surface}</strong>
                                            <Text
                                                type="text"
                                                placeholder={document.data.calculation_panels_surface_placeholder}
                                                legend="m²"
                                                value={surface}
                                                onChange={(e) => setSurface(e.target.value)}
                                                required
                                            />
                                        </div>
                                    </div>
                                </QuestionBoxBody>
                            </QuestionBox>
                        </Step>

                        <Step label={document.data.calculation_panels_stepper_use_context}>
                            <QuestionBox style={{ marginTop: "25px" }} className={styles.fullWidthQuestion}>
                                <QuestionBoxTitle>
                                    <span>{document.data.calculation_panels_category_of_use_question}</span>
                                </QuestionBoxTitle>
                                <QuestionBoxBody className={styles.maxParagraphWidth}>
                                    <SelectGroup selected={interiorUseClass} onSelectionChanged={(id) => setInteriorUseClass(id)}>
                                        {interiorUseClasses.map((useClass, index) => {
                                            return (
                                                <SelectGroupItem key={index} identifier={useClass.id}>
                                                    <SelectGroupItemTitle>{useClass.title}</SelectGroupItemTitle>
                                                    <SelectGroupItemDescription component={"div"}>{useClass.description}</SelectGroupItemDescription>
                                                    <SelectGroupItemImage
                                                        render={
                                                            <svg
                                                                height="75"
                                                                width="200"
                                                                style={{
                                                                    minWidth: "200px",
                                                                    margin: "auto 0px",
                                                                }}
                                                            >
                                                                <text
                                                                    x="50%"
                                                                    y="50%"
                                                                    dominantBaseline="middle"
                                                                    textAnchor="middle"
                                                                    fill="black"
                                                                    fontSize="36px"
                                                                    fontWeight="bold"
                                                                >
                                                                    {useClass.id}
                                                                </text>
                                                            </svg>
                                                        }
                                                    />
                                                </SelectGroupItem>
                                            );
                                        })}
                                    </SelectGroup>
                                </QuestionBoxBody>
                            </QuestionBox>

                            <QuestionBox>
                                <QuestionBoxTitle>{document.data.calculation_panels_public_access_question}</QuestionBoxTitle>
                                <QuestionBoxBody className={styles.maxParagraphWidth}>
                                    <p className={styles.questionDetails}>{document.data.calculation_panels_public_access_information}</p>
                                    <Radio
                                        label={document.data.calculation_common_yes}
                                        checked={isExteriorPubliclyAccessible}
                                        onChange={() => setIsExteriorPubliclyAccessible(true)}
                                    />
                                    <Radio
                                        label={document.data.calculation_common_no}
                                        checked={!isExteriorPubliclyAccessible}
                                        onChange={() => setIsExteriorPubliclyAccessible(false)}
                                    />
                                </QuestionBoxBody>
                            </QuestionBox>

                            {(Number(heightAboveExteriorGround) >= Number(protectionHeight) && isExteriorPubliclyAccessible) ||
                            Number(heightAboveInteriorFloor) >= Number(protectionHeight) ? (
                                <QuestionBox>
                                    <QuestionBoxTitle>{document.data.calculation_panels_shocks_question}</QuestionBoxTitle>
                                    <QuestionBoxBody className={styles.maxParagraphWidth}>
                                        <p className={styles.questionDetails}>{document.data.calculation_panels_shocks_information}</p>
                                        <div className={styles.dimensions} style={{ justifyContent: "inherit" }}>
                                            {Number(heightAboveInteriorFloor) >= Number(protectionHeight) && (
                                                <div>
                                                    <strong style={{ display: "block", marginBottom: "8px" }}>
                                                        {document.data.calculation_panels_shocks_interior_question}
                                                    </strong>
                                                    <Radio
                                                        label={document.data.calculation_common_yes}
                                                        checked={otherInteriorShocksPossible}
                                                        onChange={() => setOtherInteriorShocksPossible(true)}
                                                    />
                                                    <Radio
                                                        label={document.data.calculation_common_no}
                                                        checked={!otherInteriorShocksPossible}
                                                        onChange={() => setOtherInteriorShocksPossible(false)}
                                                    />
                                                </div>
                                            )}
                                            {Number(heightAboveExteriorGround) >= Number(protectionHeight) && isExteriorPubliclyAccessible && (
                                                <div>
                                                    <strong style={{ display: "block", marginBottom: "8px" }}>
                                                        {document.data.calculation_panels_shocks_exterior_question}
                                                    </strong>
                                                    <Radio
                                                        label={document.data.calculation_common_yes}
                                                        checked={otherExteriorShocksPossible}
                                                        onChange={() => setOtherExteriorShocksPossible(true)}
                                                    />
                                                    <Radio
                                                        label={document.data.calculation_common_no}
                                                        checked={!otherExteriorShocksPossible}
                                                        onChange={() => setOtherExteriorShocksPossible(false)}
                                                    />
                                                </div>
                                            )}
                                        </div>
                                    </QuestionBoxBody>
                                </QuestionBox>
                            ) : null}

                            {Number(heightAboveExteriorGround) < Number(protectionHeight) || Number(heightAboveInteriorFloor) < Number(protectionHeight) ? (
                                <QuestionBox>
                                    <QuestionBoxTitle>{document.data.calculation_panels_railing_question}</QuestionBoxTitle>
                                    <QuestionBoxBody className={styles.maxParagraphWidth}>
                                        <Radio
                                            label={document.data.calculation_common_no}
                                            checked={!isInteriorRailingPresent && !isExteriorRailingPresent}
                                            onChange={() => {
                                                setIsInteriorRailingPresent(false);
                                                setIsExteriorRailingPresent(false);
                                            }}
                                        />
                                        <Radio
                                            label={document.data.calculation_panels_railing_interior_confirmation}
                                            checked={isInteriorRailingPresent}
                                            onChange={() => {
                                                setIsInteriorRailingPresent(true);
                                                setIsExteriorRailingPresent(false);
                                            }}
                                        />
                                        <Radio
                                            label={document.data.calculation_panels_railing_exterior_confirmation}
                                            checked={isExteriorRailingPresent}
                                            onChange={() => {
                                                setIsInteriorRailingPresent(false);
                                                setIsExteriorRailingPresent(true);
                                            }}
                                        />
                                    </QuestionBoxBody>
                                </QuestionBox>
                            ) : null}
                        </Step>

                        <Step label={document.data.calculation_panels_stepper_general_performance}>
                            <Result id={result.tempId} result={result} />
                        </Step>
                    </Stepper>
                </PanelBody>

                <PanelFooter>
                    {step === 1 && (
                        <Button id="panels-determination-previous-step-button" variant="tertiary" startIcon={<ArrowLeft />} onClick={() => goToStep(0)}>
                            {document.data.button_navigate_back_to_dimensions}
                        </Button>
                    )}

                    {step === 2 && (
                        <Button id="panels-determination-delete-button" variant="tertiary" startIcon={<Trash2 />} onClick={() => props.onRemove()}>
                            {document.data.button_common_delete_determination}
                        </Button>
                    )}
                    <div style={{ display: "flex", marginLeft: "auto" }}>
                        {props.result.inputParameters && props.result.inputParameters.length > 0 && (
                            <Button id="panels-cancel-button" variant="tertiary" style={{ marginRight: "10px" }} onClick={() => cancelEdit()}>
                                {document.data.calculation_common_cancel}
                            </Button>
                        )}

                        {step === 0 && (
                            <Button id="panels-determination-next-step-button" variant="secondary" onClick={() => goToStep(1)}>
                                {document.data.button_calculation_panels_navigate_to_use_context}
                            </Button>
                        )}

                        {step === 1 && (
                            <Button id="panels-determination-calculate-button" variant="secondary" startIcon={<ArrowRight />} onClick={() => calculate()}>
                                {document.data.button_common_determine}
                            </Button>
                        )}

                        {step === 2 && (
                            <Button id="panels-determination-edit-button" variant="secondary" startIcon={<Edit />} onClick={() => goToStep(0)}>
                                {document.data.button_common_edit_determination}
                            </Button>
                        )}
                    </div>
                </PanelFooter>
            </Panel>

            <Modal open={warning.visible} backdrop onClose={warning.callback}>
                <ModalHeader closeButton>
                    <ModalTitle>{warning.title}</ModalTitle>
                </ModalHeader>
                <ModalBody>{warning.text}</ModalBody>
                <ModalFooter>
                    <Button id="panels-warning-confirm-button" onClick={warning.callback}>{document.data.button_common_ok}</Button>
                </ModalFooter>
            </Modal>
        </>
    );
};

export default Calculator;
