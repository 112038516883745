import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { config } from "./_configuration/configuration";
import TagManager from "react-gtm-module";
import { BuildwiseApplication, BuildwiseProvider } from "@buildwise/ui";
import { AppContextProvider } from "./context/AppContextProvider";
import AppRoutes from "./Routes";

if (config.gtm) {
    TagManager.initialize(config.gtm);
}

if (config.sentry) {
    Sentry.init({
        dsn: config.sentry.dsn,
        environment: config.sentry.env,
        integrations: [new Integrations.BrowserTracing()],
        tracesSampleRate: config.sentry.sampleRate,
        ignoreErrors: ["BrowserAuthError", "InteractionRequiredAuthError", "ClientAuthError"],
        beforeBreadcrumb: (breadcrumb, hint) => {
            if (breadcrumb.category === "ui.click") {
                const { target } = hint.event;
                if (target.innerText) {
                    breadcrumb.message = `${breadcrumb.message} - ${target.innerText}`;
                }
            }

            return breadcrumb;
        },
    });

    Sentry.configureScope((scope) => {
        scope.setUser({ id: "Demo user" });
    });
}

const instance = new BuildwiseApplication({
    authentication: config.auth,
    prismic: config.prismic,
    feedbackEndpoint: config.feedback,
});

ReactDOM.render(
    <React.StrictMode>
        <BuildwiseProvider instance={instance}>
            <AppContextProvider>
                <BrowserRouter>
                    <AppRoutes />
                </BrowserRouter>
            </AppContextProvider>
        </BuildwiseProvider>
    </React.StrictMode>,
    document.getElementById("root")
);
