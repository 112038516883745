import { useContext, useEffect, useRef, useState } from "react";
import { useParams, useNavigate, Link, generatePath } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import { Download, Edit, RefreshCw, Upload } from "react-feather";
import { PrismicRichText } from "@prismicio/react";
import { asText } from "@prismicio/helpers";
import {
    Box,
    BoxBody,
    BoxTitle,
    Breadcrumb,
    Button,
    Checkbox,
    Content,
    ContentActions,
    ContentBody,
    ContentFooter,
    ContentHeader,
    ContentInfo,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    ModalTitle,
    Panel,
    PanelBody,
    PanelFooter,
    PanelHeader,
    Separator,
    Spinner,
    Text,
    useAuthentication,
    useLanguage,
    usePrismic,
} from "@buildwise/ui";

import CreateCalculationModal from "../../components/CreateCalculationModal/CreateCalculationModal";
import EditCalculationModal from "../../components/EditCalculationModal/EditCalculationModal";

import { AppContext } from "../../context/AppContextProvider";

import { getProjects } from "../../adapters/ProjectsAdapter";
import { addInputFile, getCalculation, getCalculations, getInputFile, updateCalculation } from "../../adapters/CalculationsAdapter";
import { calculateByInputFile, downloadCalculatorFile, generateCalculatorFile, getCalculatorDefinition } from "../../adapters/CalculatorAdapter";

import { config } from "../../_configuration/configuration";
import styles from "./Panels.module.css";

const PanelsImport = () => {
    const { state, dispatch } = useContext(AppContext);
    const { isAuthenticated, user } = useAuthentication();
    const [isLoading, setIsLoading] = useState(true);
    const [hasAccess, setHasAccess] = useState(true);

    const navigate = useNavigate();
    const params = useParams();

    const [hasUploadedList, setHasUploadedList] = useState(false);
    const [calculation, setCalculation] = useState();
    const [isEdittingCalculation, setIsEdittingCalculation] = useState(false);

    const [warning, setWarning] = useState({ visible: false });
    const [isSaving, setIsSaving] = useState(false);
    const [canSave, setCanSave] = useState(false);
    const [protectionHeight, setProtectionHeight] = useState(90);

    const [result, setResult] = useState([]);
    const [determinations, setDeterminations] = useState([]);
    const [hasValidFile, setHasValidFile] = useState(false);
    const [selectAll, setSelectAll] = useState(false);

    const [previousCalcId, setPreviousCalcId] = useState(0);
    const fileInputRef = useRef();

    const [isCreatingCalculation, setIsCreatingCalculation] = useState(false);
    const [parsedOutput, setParsedOutput] = useState([]);

    const { language } = useLanguage();
    const [document] = usePrismic(config.prismic.documentType);

    useEffect(() => {
        ReactTooltip.rebuild();
    });

    useEffect(() => {
        if (state.calculatorDefinitions[2]) return;
        getCalculatorDefinition(2).then(storeCalculatorDefinition);
    }, []);

    useEffect(() => {
        const fileBrowser = window.document.getElementById("file-upload");
        fileBrowser.addEventListener("change", (e) => onUploadFile(e));
    }, []);

    useEffect(() => {
        if (isAuthenticated && state.projects.length === 0) {
            getProjects().then(storeProjects);
        }

        if (!isAuthenticated) setIsLoading(false);
    }, [isAuthenticated]);

    useEffect(() => {
        if (isAuthenticated && state.calculatorDefinitions[2]) {
            if (params.calcId && params.calcId !== "import") {
                if (!Boolean(state.calculations[params.projectId])) getCalculations(params.projectId).then(storeCalculations);
                else loadCalculation(state.calculations[params.projectId]);
                setPreviousCalcId(params.calcId);
            } else {
                if (previousCalcId > 0) {
                    setCalculation(null);
                    setProtectionHeight(90);
                    setHasUploadedList(false);
                    setDeterminations([]);
                    setParsedOutput([]);
                }

                setIsLoading(false);
            }
        }
    }, [isAuthenticated, params.projectId, params.calcId, state.calculatorDefinitions]);

    useEffect(() => {
        setSelectAll(determinations.every((x) => x.active === true));
    }, [determinations]);

    useEffect(() => {
        if (validateProtectionHeight(protectionHeight)) {
            const updatedResult = [...result].map((item) => {
                const protectionHeightParam = item.inputParameters.find((x) => x.parameterId === 11);
                if (protectionHeightParam) {
                    protectionHeightParam.value = (protectionHeight * 10).toString();
                } else {
                    item.inputParameters.push({
                        parameterId: 11,
                        value: (protectionHeight * 10).toString(),
                    });
                }

                return item;
            });

            setResult(updatedResult);
        }
    }, [protectionHeight]);

    useEffect(() => {
        if (parsedOutput.length === 0 || !validateProtectionHeight(protectionHeight)) {
            setCanSave(false);
            return;
        }

        setCanSave(!isSaving);
    }, [parsedOutput, isSaving, protectionHeight]);

    const storeCalculatorDefinition = (definition) => {
        dispatch({ type: "SET_CALCULATOR_DEFINITION", payload: { id: 2, definition } });
    };

    const storeProjects = (projects) => {
        if (projects) {
            dispatch({ type: "SET_PROJECTS", payload: projects });
            if (!params.calcId) {
                if (!projects.find((x) => x.id === Number(params.projectId)))
                    navigate(generatePath(config.routes.newPanelsImportCalculation[language], { ...params }));
            }
        }

        setIsLoading(false);
    };

    const storeCalculations = (calculations) => {
        if (calculations) {
            dispatch({
                type: "SET_CALCULATIONS",
                payload: { projectId: params.projectId, calculations: calculations },
            });

            loadCalculation(calculations);
        }
    };

    const loadCalculation = (calculations) => {
        const calculation = calculations.find((x) => x.id === Number(params.calcId) && x.calculationAppId === 2 && x.hasInputfile);
        const protHeight = calculation.calculationResults[0].inputParameters.find((x) => x.parameterId === 11);

        if (calculation) {
            setIsLoading(false);
            setHasUploadedList(true);
            setCalculation(calculation);
            setProtectionHeight(Number(protHeight.value) / 10);
            handleCalculationResults(calculation.calculationResults);
            getInputFile(params.projectId, params.calcId).then(handleInputFile);
        } else {
            setIsLoading(false);
            setHasAccess(false);
        }
    };

    const handleInputFile = (data) => {
        const sorted = data.parameterLines.sort((a, b) => (a.order < b.order ? -1 : 1));
        setDeterminations(sorted);
        setHasValidFile(true);
    };

    const onSave = () => {
        setIsSaving(true);

        if (!params.calcId) {
            setIsCreatingCalculation(true);
            return;
        }

        updateCalculation(params.projectId, {
            ...calculation,
            calculationResults: result,
        }).then(updateCalculations);
    };

    const updateCalculations = (succes) => {
        if (succes)
            addInputFile(params.projectId, params.calcId, determinations).then(() =>
                getCalculation(params.projectId, params.calcId).then(updateStoredCalculation)
            );

        setIsSaving(false);
    };

    const updateStoredCalculation = (calculation) => {
        dispatch({
            type: "UPDATE_CALCULATION",
            payload: {
                projectId: params.projectId,
                calculation: calculation,
            },
        });
    };

    const onEdit = () => {
        setIsEdittingCalculation(true);
    };

    const onCancel = () => {
        if (params.projectId) navigate(generatePath(config.routes.project[language], { ...params, id: params.projectId }));
        else navigate(generatePath(config.routes.landing[language], { ...params }));
    };

    const onUpload = () => {
        fileInputRef.current.click();
    };

    const onDownloadTemplate = () => {
        downloadCalculatorFile(2, language);
    };

    const onUploadFile = (e) => {
        let files = e.target.files;
        if (!files || !files[0] || !files[0].name) return;

        generateCalculatorFile(2, files[0]).then(handleGeneratedCalculatorFile);
    };

    const onToggleSelectAll = (checked) => {
        const toggledItems = determinations.map((item) => {
            item.active = checked;

            return item;
        });

        setSelectAll(checked);
        setDeterminations(toggledItems);
    };

    const onCalculate = () => {
        if (!validateProtectionHeight(protectionHeight)) {
            setWarning({
                visible: true,
                prompt: false,
                title: asText(document.data.calculation_common_error_title_invalid_properties),
                text: (
                    <>
                        <p>{asText(document.data.calculation_common_error_massage_invalid_properties)}</p>
                        <ul>
                            <li>{asText(document.data.calculation_common_invalid_protection_height)}</li>
                        </ul>
                    </>
                ),
                callback: (bool) => setWarning({ visible: false }),
            });

            return;
        }

        calculateByInputFile(2, getCalculationInput()).then((data) => handleCalculationResults(data.calculationResults));
    };

    const validateProtectionHeight = (value) => Number(value) >= 90 && Number(value) <= 120;

    const getCalculationInput = () => {
        const lines = [...determinations].map((item) => {
            const resultItem = { ...item };
            resultItem.parameterCandidates = [...item.parameterCandidates].map((original) => {
                const clone = { ...original };
                if (
                    clone.parameterId === 16 ||
                    clone.parameterId === 17 ||
                    clone.parameterId === 19 ||
                    clone.parameterId === 20 ||
                    clone.parameterId === 21 ||
                    clone.parameterId === 25
                ) {
                    clone.value = (Number(clone.value) * 10).toString();
                }

                if (clone.parameterId === 18) {
                    clone.value = (Number(clone.value) * 1000000).toString();
                }

                return clone;
            });

            const protectionHeightParam = resultItem.parameterCandidates.find((x) => x.parameterId === 11);
            if (protectionHeightParam) {
                protectionHeightParam.value = (protectionHeight * 10).toString();
            } else {
                resultItem.parameterCandidates.push({
                    parameterId: 11,
                    value: (protectionHeight * 10).toString(),
                });
            }

            return { ...resultItem };
        });

        return lines;
    };

    const handleCalculationResults = async (results) => {
        const output = [];
        for (let i = 0; i < results.length; i++) {
            const res = results[i];
            var parsedRes = await handleCalculationResult(res);
            if (parsedRes) output.push(parsedRes);
        }

        setResult(results);
        setParsedOutput(output);
    };

    const handleCalculationResult = async (result) => {
        if (Object.keys(result).length === 0) return;

        const inputData = {};
        const pDefs = state.calculatorDefinitions[2];

        for (let i = 0; i < pDefs.parameters.length; i++) {
            const pDef = pDefs.parameters[i];
            const input = result.inputParameters.find((x) => x.parameterId === pDef.id);

            if (!input) {
                inputData[pDef.name] = null;
                continue;
            }

            let value;
            switch (pDef.dataType) {
                case "Int":
                case "Double":
                    value = Number(input.value);
                    break;
                case "Boolean":
                    value = input.value === "True";
                    break;
                default:
                    value = input.value;
            }

            inputData[pDef.name] = value;
        }

        const resultData = {};
        for (let i = 0; i < pDefs.calculationResultDefinitions.length; i++) {
            const rDef = pDefs.calculationResultDefinitions[i];
            const res = result.resultItems.find((x) => x.calculationResultDefinitionId === rDef.id);

            if (!res) {
                resultData[rDef.name] = null;
                continue;
            }

            resultData[rDef.name] = res.value;
        }

        return { input: inputData, result: resultData };
    };

    const handleGeneratedCalculatorFile = async (data) => {
        fileInputRef.current.value = null;

        if (!data) return;

        setHasUploadedList(true);

        if (data.parameterLines !== null || data.parameterLines.length > 0) {
            setDeterminations(data.parameterLines);
            setHasValidFile(true);
        }
    };

    const selectRow = (orderId, checked) => {
        if (!checked) {
            setSelectAll(false);
        }

        let allChecked = true;
        const myList = [...determinations];
        for (let x = 0; x < myList.length; x++) {
            if (myList[x].order === orderId) {
                myList[x].active = checked;
            }

            if (!myList[x].active) allChecked = false;
        }

        setDeterminations(myList);
        setSelectAll(allChecked);
    };

    const getOrder = (parameterId) => {
        const parameter = state.calculatorDefinitions[2].parameters.find((x) => x.id === parameterId);
        return parameter.order;
    };

    const renderButtons = () => {
        return (
            <>
                <Button id="panels-import-cancel-button" variant="tertiary" className=" windows-calculation-add" onClick={() => onCancel()} style={{ margin: "5px" }}>
                    {document.data.button_common_cancel}
                </Button>
                {hasUploadedList && (
                    <>
                        {params.calcId && (
                            <Button id="panels-import-edit-button" disabled={!hasUploadedList} onClick={() => onEdit()} style={{ margin: "5px" }} startIcon={<Edit />}>
                                {document.data.button_common_edit_calculation_details}
                            </Button>
                        )}
                        <Button
                            id="panels-import-save-button"
                            disabled={(isAuthenticated && !canSave) || isSaving}
                            onClick={() => isAuthenticated && onSave()}
                            style={{ margin: "5px" }}
                            className={isAuthenticated ? "windows-calculation-save" : "windows-calculation-save transparent"}
                            data-for="tooltip"
                            data-tip={document.data.calculation_common_user_feature}
                            data-tip-disable={isAuthenticated}
                        >
                            {isSaving
                                ? document.data.button_common_busy_saving
                                : params.calcId === null
                                ? document.data.button_common_save_as
                                : document.data.button_common_save}
                        </Button>
                    </>
                )}
            </>
        );
    };

    const renderRemarksIsolation = () => {
        if (parsedOutput.some((x) => x.result.ExteriorFractureRequirementStandardType !== null || x.result.ExteriorFractureRequirementType !== null)) {
            return (
                <Box useAccent>
                    <BoxTitle>{document.data.calculation_panels_remark_insulation_title}</BoxTitle>
                    <BoxBody>
                        <div>{document.data.calculation_panels_remark_insulation_title}</div>
                    </BoxBody>
                </Box>
            );
        }

        return null;
    };

    const renderRemarksBreakTypeA = () => {
        if (parsedOutput.some((x) => x.result.InteriorFractureRequirementExceptionalType === "A")) {
            return (
                <Box useAccent>
                    <BoxTitle>{document.data.calculation_panels_remark_break_type_a_title}</BoxTitle>
                    <BoxBody>
                        <div>
                            <PrismicRichText field={document.data.calculation_panels_remark_break_type_a_description} />
                        </div>
                    </BoxBody>
                </Box>
            );
        }

        return null;
    };

    return (
        <>
            <Breadcrumb>
                {Boolean(state.projects.find((x) => x.id === Number(params.projectId))) && (
                    <Link id="panels-import-breadcrumb-link" to={generatePath(config.routes.project[language], { ...params, id: params.projectId })}>
                        {state.projects.find((x) => x.id === Number(params.projectId)).name}
                    </Link>
                )}
            </Breadcrumb>
            {!Boolean(params.calcId) && <Breadcrumb>{document.data.calculation_panels_title}</Breadcrumb>}
            {calculation && calculation.name && <Breadcrumb>{calculation.name}</Breadcrumb>}

            <input type="file" id="file-upload" style={{ display: "none" }} accept=".xls,.xlsx" ref={fileInputRef} />

            {isLoading ? (
                <Content>
                    <Spinner />
                </Content>
            ) : hasAccess ? (
                <>
                    <Content>
                        <ContentHeader className={styles.header}>
                            <h1>{calculation ? calculation.name : document.data.navigation_new_panels_calculation}</h1>
                            <ContentActions className={styles.actions}>{renderButtons()}</ContentActions>
                        </ContentHeader>
                        <Separator color="blue">
                            {document.data.project_overview_general_performance_of_panels_title} - {document.data.calculation_panels_with_list}
                        </Separator>
                        <ContentInfo style={{ flexWrap: "wrap" }}>
                            <div className={styles.projectinfo}>
                                {calculation && calculation.id > 0 ? (
                                    <>
                                        <h4>{document.data.project_overview_description_title}</h4>
                                        <p>{calculation.description}</p>
                                    </>
                                ) : (
                                    <div>
                                        <PrismicRichText field={document.data.calculation_panels_description} />
                                    </div>
                                )}
                            </div>
                            <div>
                                {calculation && calculation.id > 0 && (
                                    <>
                                        <p>
                                            <strong>{document.data.calculation_common_creation_date}</strong>{" "}
                                            {new Date(calculation.creationDate).toLocaleDateString("nl-BE")}
                                        </p>
                                        <p>
                                            <strong>{document.data.calculation_common_modification_date}</strong>{" "}
                                            {new Date(calculation.modificationDate).toLocaleDateString("nl-BE")}
                                        </p>
                                    </>
                                )}
                            </div>
                        </ContentInfo>
                        <ContentBody className={styles.importinfo}>
                            {!hasUploadedList ? (
                                <>
                                    <Panel className={styles.split}>
                                        <PanelHeader>
                                            <h2 style={{ fontSize: "28px" }}>{document.data.calculation_panels_import_determination_with_list}</h2>
                                        </PanelHeader>
                                        <PanelBody>
                                            <p>{document.data.calculation_panels_import_determination_with_list_description}</p>
                                        </PanelBody>
                                        <PanelFooter>
                                            <Button id="panels-import-upload-list-button" startIcon={<Upload />} onClick={() => onUpload()}>
                                                {document.data.button_calculation_panels_list_import}
                                            </Button>
                                        </PanelFooter>
                                    </Panel>
                                    <Panel className={styles.split}>
                                        <PanelHeader>
                                            <h2 style={{ fontSize: "28px" }}>{document.data.calculation_panels_first_time_import}</h2>
                                        </PanelHeader>
                                        <PanelFooter>
                                            <Button id="panels-import-download-template-button" variant="secondary" startIcon={<Download />} onClick={() => onDownloadTemplate()}>
                                                {document.data.button_calculation_panels_download_list}
                                            </Button>
                                        </PanelFooter>
                                    </Panel>
                                </>
                            ) : (
                                <>
                                    <Panel>
                                        <PanelBody className={styles.maxParagraphWidth}>
                                            <div style={{ margin: "0 0 4px 15px" }}>
                                                <strong>{document.data.calculation_panels_protection_height_question}</strong>
                                                <PrismicRichText field={document.data.calculation_panels_protection_height_information} />
                                            </div>
                                            <Text
                                                type="text"
                                                placeholder={document.data.calculation_panels_protection_height_placeholder}
                                                legend="cm"
                                                value={protectionHeight}
                                                onChange={(e) => setProtectionHeight(e.target.value)}
                                                onValidate={validateProtectionHeight}
                                                validationError={document.data.calculation_panels_protection_height_error}
                                                required
                                            />
                                        </PanelBody>

                                        {hasValidFile ? (
                                            <>
                                                <PanelBody className={styles.tableActions}>
                                                    <Checkbox
                                                        label={document.data.checkbox_calculation_panels_select_all_rows}
                                                        disabled={!hasValidFile}
                                                        onChange={(e) => onToggleSelectAll(e.target.checked)}
                                                        checked={selectAll}
                                                    />

                                                    <Button
                                                        id="panels-import-calculate-button"
                                                        onClick={() => onCalculate()}
                                                        disabled={!hasValidFile || determinations.filter((x) => x.active).length === 0}
                                                    >
                                                        {document.data.button_calculation_panels_determine_selection}
                                                    </Button>

                                                    <Button
                                                        id="panels-import-upload-updated-list-button"
                                                        variant="secondary"
                                                        style={{ marginLeft: "auto" }}
                                                        disabled={!hasValidFile}
                                                        startIcon={<RefreshCw />}
                                                        onClick={() => onUpload()}
                                                    >
                                                        {document.data.button_calculation_panels_import_list_update}
                                                    </Button>
                                                </PanelBody>

                                                <PanelBody className={styles.tableContainer}>
                                                    <table className={styles.table}>
                                                        <thead>
                                                            <tr>
                                                                <th></th>
                                                                <th>{document.data.calculation_panels_import_data_table_header_name}</th>
                                                                <th>{document.data.calculation_panels_import_data_table_header_id}</th>
                                                                <th>{document.data.calculation_panels_import_data_table_header_glazed}</th>
                                                                <th>{document.data.calculation_panels_import_data_table_header_panel_width}</th>
                                                                <th>{document.data.calculation_panels_import_data_table_header_panel_height}</th>
                                                                <th>{document.data.calculation_panels_import_data_table_header_surface}</th>
                                                                <th
                                                                    dangerouslySetInnerHTML={{
                                                                        __html: asText(document.data.calculation_panels_import_data_table_header_height_hi),
                                                                    }}
                                                                ></th>
                                                                <th
                                                                    dangerouslySetInnerHTML={{
                                                                        __html: asText(document.data.calculation_panels_import_data_table_header_height_he),
                                                                    }}
                                                                ></th>

                                                                <th
                                                                    dangerouslySetInnerHTML={{
                                                                        __html: asText(
                                                                            document.data.calculation_panels_import_data_table_header_drop_height_hci
                                                                        ),
                                                                    }}
                                                                ></th>
                                                                <th
                                                                    dangerouslySetInnerHTML={{
                                                                        __html: asText(
                                                                            document.data.calculation_panels_import_data_table_header_drop_height_hce
                                                                        ),
                                                                    }}
                                                                ></th>
                                                                <th>{document.data.calculation_panels_import_data_table_header_category_of_use}</th>
                                                                <th>{document.data.calculation_panels_import_data_table_header_interior_shocks}</th>
                                                                <th>{document.data.calculation_panels_import_data_table_header_exterior_shocks}</th>
                                                                <th>
                                                                    {document.data.calculation_panels_import_data_table_header_exterior_publicly_accessible}
                                                                </th>
                                                                <th>{document.data.calculation_panels_import_data_table_header_fall_through_question}</th>
                                                                <th>{document.data.calculation_panels_import_data_table_header_railing}</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {determinations.length > 0 &&
                                                                determinations.map((item, i) => (
                                                                    <tr key={i}>
                                                                        <th>
                                                                            <Checkbox
                                                                                onChange={(e) => selectRow(item.order, e.target.checked)}
                                                                                checked={item.active}
                                                                            />
                                                                        </th>

                                                                        {item.parameterCandidates
                                                                            .sort((a, b) => (getOrder(a.parameterId) < getOrder(b.parameterId) ? -1 : 1))
                                                                            .map((param, i) => {
                                                                                if (param.parameterId === 11 || param.parameterId === 23) return null;
                                                                                if (param.parameterId === 22) {
                                                                                    if (param.value === "True")
                                                                                        return <td key={i}>{document.data.calculation_panels_interior}</td>;

                                                                                    const exteriorRailing = item.parameterCandidates.find(
                                                                                        (x) => x.parameterId === 23
                                                                                    );
                                                                                    if (exteriorRailing.value === "True")
                                                                                        return <td key={i}>{document.data.calculation_panels_exterior}</td>;

                                                                                    return <td key={i}>{document.data.calculation_panels_none}</td>;
                                                                                }

                                                                                if (param.value === "True")
                                                                                    return <td key={i}>{document.data.calculation_panels_import_data_yes}</td>;

                                                                                if (param.value === "False")
                                                                                    return <td key={i}>{document.data.calculation_panels_import_data_no}</td>;

                                                                                return <td key={i}>{param.value}</td>;
                                                                            })}
                                                                    </tr>
                                                                ))}
                                                        </tbody>
                                                    </table>
                                                </PanelBody>
                                            </>
                                        ) : (
                                            <PanelBody className={styles.tableActions}>
                                                <PrismicRichText field={document.data.calculation_common_error_message_import} />

                                                <div style={{ display: "flex", marginTop: "20px" }}>
                                                    <Button id="panels-import-download-template-button" onClick={() => onUpload()} startIcon={<Upload />} style={{ marginRight: "10px" }}>
                                                        {document.data.button_calculation_panels_list_import}
                                                    </Button>

                                                    <Button id="panels-import-download-template-button" variant="secondary" onClick={() => onDownloadTemplate()} startIcon={<Download />}>
                                                        {document.data.button_calculation_panels_download_list}
                                                    </Button>
                                                </div>
                                            </PanelBody>
                                        )}
                                    </Panel>

                                    {parsedOutput.length > 0 && (
                                        <Panel>
                                            <PanelBody className={styles.tableContainer}>
                                                <table className={styles.resulttable}>
                                                    <thead>
                                                        <tr>
                                                            <th>{document.data.calculation_panels_result_data_table_header_name}</th>
                                                            <th>{document.data.calculation_panels_result_data_table_header_id}</th>
                                                            <th>{document.data.calculation_panels_result_data_table_header_outer_panel_shock_resistance}</th>
                                                            <th>
                                                                {document.data.calculation_panels_result_data_table_header_outer_panel_break_type}
                                                                <br />
                                                                {document.data.calculation_panels_result_data_table_header_standard}
                                                            </th>
                                                            <th>
                                                                {document.data.calculation_panels_result_data_table_header_outer_panel_break_type}
                                                                <br />
                                                                {document.data.calculation_panels_result_data_table_header_alternative}
                                                            </th>
                                                            <th>
                                                                {document.data.calculation_panels_result_data_table_header_outer_panel_break_type}
                                                                <br />
                                                                {document.data.calculation_panels_result_data_table_header_exception}
                                                            </th>
                                                            <th>{document.data.calculation_panels_result_data_table_header_inner_panel_shock_resistance}</th>
                                                            <th>
                                                                {document.data.calculation_panels_result_data_table_header_inner_panel_break_type}
                                                                <br />
                                                                {document.data.calculation_panels_result_data_table_header_standard}
                                                            </th>
                                                            <th>
                                                                {document.data.calculation_panels_result_data_table_header_inner_panel_break_type}
                                                                <br />
                                                                {document.data.calculation_panels_result_data_table_header_alternative}
                                                            </th>
                                                            <th>
                                                                {document.data.calculation_panels_result_data_table_header_inner_panel_break_type}
                                                                <br />
                                                                {document.data.calculation_panels_result_data_table_header_exception}
                                                            </th>
                                                            <th>{document.data.calculation_panels_result_data_table_header_railing}</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {parsedOutput.map((item, i) => (
                                                            <tr key={i}>
                                                                <th>{item.input.WindowName}</th>
                                                                <td>{item.input.PanelId}</td>
                                                                <td>
                                                                    {item.result.ExteriorShockResistanceClass === "NoRequirement"
                                                                        ? document.data.calculation_panels_result_no_requirement
                                                                        : item.result.ExteriorShockResistanceClass}
                                                                </td>
                                                                <td>{item.result.ExteriorFractureRequirementStandardType}</td>
                                                                <td>{item.result.ExteriorFractureRequirementAlternativeType} </td>
                                                                <td>{item.result.ExteriorFractureRequirementExceptionalType}</td>
                                                                <td>
                                                                    {item.result.InteriorShockResistanceClass === "NoRequirement"
                                                                        ? document.data.calculation_panels_result_no_requirement
                                                                        : item.result.InteriorShockResistanceClass}
                                                                </td>
                                                                <td>{item.result.InteriorFractureRequirementStandardType}</td>
                                                                <td>{item.result.InteriorFractureRequirementAlternativeType}</td>
                                                                <td>
                                                                    {item.result.InteriorFractureRequirementExceptionalType}
                                                                    {item.result.InteriorFractureRequirementExceptionalType === "A" ? "*" : ""}
                                                                </td>
                                                                <td>
                                                                    {item.result.IsRailingRequired === "True"
                                                                        ? document.data.calculation_panels_required
                                                                        : document.data.calculation_panels_not_required}
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </PanelBody>
                                            <PanelBody style={{ marginTop: "32px" }}>
                                                {parsedOutput.some((x) => x.input.IsGlass) && (
                                                    <Box>
                                                        <BoxTitle>{document.data.calculation_panels_general_remark_glass}</BoxTitle>
                                                        <BoxBody>
                                                            <div>{document.data.calculation_panels_general_remark_glass_description}</div>
                                                        </BoxBody>
                                                    </Box>
                                                )}

                                                <div
                                                    style={{
                                                        display: "flex",
                                                        justifyContent: "space-between",
                                                    }}
                                                >
                                                    {renderRemarksIsolation()}
                                                    {renderRemarksBreakTypeA()}
                                                </div>
                                            </PanelBody>
                                        </Panel>
                                    )}
                                </>
                            )}
                        </ContentBody>
                        <ContentFooter>
                            <ContentActions className={styles.actions}>{renderButtons()}</ContentActions>
                        </ContentFooter>
                    </Content>

                    {isAuthenticated && (
                        <>
                            <CreateCalculationModal
                                isOpen={isCreatingCalculation}
                                onClose={() => {
                                    setIsCreatingCalculation(false);
                                    setIsSaving(false);
                                }}
                                calculation={{
                                    calculationAppId: 2,
                                    ownerId: user.userId,
                                    calculationResults: result,
                                    inputParameters: determinations,
                                }}
                            />

                            <EditCalculationModal isOpen={isEdittingCalculation} onClose={() => setIsEdittingCalculation(false)} calculation={calculation} />
                        </>
                    )}

                    <Modal open={warning.visible} backdrop onClose={warning.callback}>
                        <ModalHeader closeButton>
                            <ModalTitle>{warning.title}</ModalTitle>
                        </ModalHeader>
                        <ModalBody>{warning.text}</ModalBody>
                        <ModalFooter>
                            <Button id="panels-import-warning-confirm-button" onClick={warning.callback}>{document.data.button_common_ok}</Button>
                        </ModalFooter>
                    </Modal>
                </>
            ) : (
                <Content>
                    <ContentBody>
                        <Panel>
                            <PanelHeader>
                                <h2>{document.data.calculation_common_calculation_not_found}</h2>
                            </PanelHeader>
                            <PanelBody>{document.data.calculation_common_calculation_not_found_details}</PanelBody>
                        </Panel>
                    </ContentBody>
                </Content>
            )}
        </>
    );
};

export default PanelsImport;
