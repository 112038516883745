import { useContext, useEffect, useRef, useState } from "react";
import { generatePath, Link, Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import { Layers, Plus } from "react-feather";
import { Helmet } from "react-helmet";
import ReactTooltip from "react-tooltip";
import {
    ApplicationMenuItem,
    ApplicationSubmenuItem,
    ApplicationTitle,
    Breadcrumb,
    Header,
    Page,
    useAuthentication,
    useLanguage,
    usePrismic,
} from "@buildwise/ui";

import CreateProjectModal from "./components/CreateProjectModal/CreateProjectModal";

import { AppContext } from "./context/AppContextProvider";

import { config } from "./_configuration/configuration";

import "./assets/global.css";
import logo from "./assets/logo.svg";

const getRoutePath = (location, params) => {
    const { pathname } = location;

    if (!Object.keys(params).length) {
        return pathname; // we don't need to replace anything
    }

    let path = pathname;
    Object.entries(params).forEach(([paramName, paramValue]) => {
        if (paramValue) {
            path = path.replace(paramValue, `:${paramName}`);
        }
    });
    return path;
};

const getLanguage = (locale) => {
    return locale.split("-")[0];
};

const App = () => {
    const { state } = useContext(AppContext);
    const [routesMap, setRoutesMap] = useState(null);
    const [isCreatingProject, setIsCreatingProject] = useState(false);
    const [projectId, setProjectId] = useState(null);

    const navigate = useNavigate();
    const params = useParams();
    const location = useLocation();

    const { isAuthenticated, login } = useAuthentication();
    const timeoutRef = useRef();

    const { language: locale, setLanguage } = useLanguage();
    const [document, { state: prismicState }] = usePrismic(config.prismic.documentType);
    const routeLanguageRef = useRef();

    useEffect(() => {
        if (localStorage.getItem("bbri-cookie-consent") === "1") {
            localStorage.clear();
            location.reload();
        }
    }, []);

    useEffect(() => {
        ReactTooltip.rebuild();
    });

    useEffect(() => {
        const map = { routes: {}, languages: {} };
        for (let i = 0; i < Object.keys(config.routes).length; i++) {
            const key = Object.keys(config.routes)[i];
            const route = config.routes[key];

            map.routes[route["nl-be"]] = route["fr-be"];
            map.routes[route["fr-be"]] = route["nl-be"];

            if (!map.languages[route["nl-be"]]) map.languages[route["nl-be"]] = [];
            map.languages[route["nl-be"]].push("nl-be");

            if (!map.languages[route["fr-be"]]) map.languages[route["fr-be"]] = [];
            map.languages[route["fr-be"]].push("fr-be");
        }

        setRoutesMap(map);
    }, []);

    useEffect(() => {
        if (!Boolean(params.lang)) {
            const storedLanguage = window.localStorage.getItem("locale") ?? "nl-be";
            window.localStorage.setItem("locale", storedLanguage);
            setLanguage(storedLanguage);
            navigate(`/${locale.split("-")[0]}`);
            routeLanguageRef.current = locale.split("-")[0];

            return;
        }
    }, [params]);

    useEffect(() => {
        if (!params.lang) return;
        if (routeLanguageRef.current === params.lang) return;

        const storedLanguage = window.localStorage.getItem("locale") ?? "nl-be";
        const currentRoute = getRoutePath(location, params);
        const targetLanguage = `${params.lang}-be`;

        if (["nl-be", "fr-be"].indexOf(targetLanguage) === -1) {
            navigate(generatePath(currentRoute, { ...params, lang: storedLanguage.split("-")[0] }));
            return;
        }

        routeLanguageRef.current = params.lang;
        setLanguage(targetLanguage);
    }, [params, routesMap]);

    useEffect(() => {
        if (!routesMap) return;

        const targetLang = getLanguage(locale);
        const currentRoute = getRoutePath(location, params);
        let target;
        if (currentRoute === "/") {
            target = `/${targetLang}`;
        } else {
            let targetRoute = currentRoute;
            const currentRouteLang = routesMap.languages[currentRoute];
            if (!currentRouteLang.includes(locale)) targetRoute = routesMap.routes[currentRoute];
            target = generatePath(targetRoute, { ...params, lang: targetLang });
        }

        window.localStorage.setItem("locale", locale);
        navigate(target);
    }, [locale, routesMap]);

    useEffect(() => {
        setProjectId(params.projectId);
    }, [params]);

    useEffect(() => {
        if (isAuthenticated && projectId && state.projects.length > 0) {
            const project = state.projects.find((x) => Number(x.id) === Number(params.projectId));
            if (!project) setProjectId(null);
        }
    }, [state.projects]);

    useEffect(() => {
        if (isAuthenticated) {
            clearTimeout(timeoutRef.current);
        } else if (projectId) {
            timeoutRef.current = setTimeout(
                () =>
                    login(
                        null,
                        () => navigate("/"),
                        () => navigate("/")
                    ),
                1000
            );
        }
    }, [projectId, isAuthenticated]);

    if (prismicState !== "loaded") return null;

    return (
        <Page feedback>
            <Helmet>
                <html lang={locale.split("-")[0]}></html>
                <title>{document.data.application_title}</title>
            </Helmet>

            <Header homeAction={() => navigate(generatePath(config.routes.landing[locale], { ...params }))}>
                <ApplicationTitle icon={<img src={logo} alt="FENESTRio" />}></ApplicationTitle>
                <ApplicationMenuItem
                    id={"app-nav-my-projects"}
                    icon={<Layers />}
                    label={document.data.navigation_my_projects}
                    onClick={() => (isAuthenticated ? navigate(generatePath(config.routes.landing[locale], { ...params })) : null)}
                    className={isAuthenticated ? null : "transparent"}
                    data-for={"tooltip"}
                    data-tip={document.data.navigation_user_feature}
                    data-tip-disable={isAuthenticated}
                />
                <ApplicationMenuItem
                    id={"app-nav-new-calculations-dropdown"}
                    icon={<Plus />}
                    label={document.data.navigation_new_calculations_dropdown}
                    data-tip-disable={true}
                >
                    <ApplicationSubmenuItem
                        id={"app-nav-new-calculations-option-windows"}
                        icon={<Plus />}
                        label={document.data.navigation_new_windows_calculation}
                        onClick={() =>
                            navigate(
                                generatePath(projectId ? config.routes.newWindowsCalculationProject[locale] : config.routes.newWindowsCalculation[locale], {
                                    ...params,
                                })
                            )
                        }
                    />
                    <ApplicationSubmenuItem
                        id={"app-nav-new-calculations-option-panels"}
                        icon={<Plus />}
                        label={document.data.navigation_new_panels_calculation}
                    >
                        <ApplicationSubmenuItem
                            id={"app-nav-new-calculations-option-panels-manual"}
                            icon={<Plus />}
                            label={document.data.navigation_manual_determination}
                            onClick={() =>
                                navigate(
                                    generatePath(projectId ? config.routes.newPanelsCalculationProject[locale] : config.routes.newPanelsCalculation[locale], {
                                        ...params,
                                    })
                                )
                            }
                        />
                        <ApplicationSubmenuItem
                            id={"app-nav-new-calculations-option-panels-import-list"}
                            icon={<Plus />}
                            label={document.data.navigation_list_determination}
                            onClick={() =>
                                navigate(
                                    generatePath(
                                        projectId ? config.routes.newPanelsImportCalculationProject[locale] : config.routes.newPanelsImportCalculation[locale],
                                        { ...params }
                                    )
                                )
                            }
                        />
                    </ApplicationSubmenuItem>

                    <ApplicationSubmenuItem
                        id={"app-nav-new-calculations-option-glass"}
                        icon={<Plus />}
                        label={document.data.navigation_new_glass_thickness_calculation}
                    >
                        <ApplicationSubmenuItem
                            id={"app-nav-new-calculations-option-glass-manual"}
                            icon={<Plus />}
                            label={document.data.navigation_manual_determination}
                            onClick={() =>
                                navigate(
                                    generatePath(projectId ? config.routes.newGlassCalculationProject[locale] : config.routes.newGlassCalculation[locale], {
                                        ...params,
                                    })
                                )
                            }
                        />
                        <ApplicationSubmenuItem
                            id={"app-nav-new-calculations-option-glass-import-list"}
                            icon={<Plus />}
                            label={document.data.navigation_list_determination}
                            onClick={() =>
                                navigate(
                                    generatePath(
                                        projectId ? config.routes.newGlassImportCalculationProject[locale] : config.routes.newGlassImportCalculation[locale],
                                        { ...params }
                                    )
                                )
                            }
                        />
                    </ApplicationSubmenuItem>
                </ApplicationMenuItem>

                <ApplicationMenuItem
                    id={"app-nav-new-project"}
                    icon={<Plus />}
                    label={document.data.navigation_new_project}
                    onClick={() => isAuthenticated && setIsCreatingProject(true)}
                    className={isAuthenticated ? null : "transparent"}
                    data-for="tooltip"
                    data-tip={document.data.navigation_user_feature}
                    data-tip-disable={isAuthenticated}
                />
            </Header>

            <Breadcrumb>
                {isAuthenticated ? (
                    <Link id="breadcrumb-app-home" to={`/${locale.split("-")[0]}`}>
                        {document.data.navigation_my_projects}
                    </Link>
                ) : (
                    <Link id="breadcrumb-app-home" to={`/${locale.split("-")[0]}`}>
                        FENESTRio
                    </Link>
                )}
            </Breadcrumb>

            <Outlet />

            {isAuthenticated && <CreateProjectModal isOpen={isCreatingProject} onClose={() => setIsCreatingProject(false)} />}

            <ReactTooltip
                id={"tooltip"}
                type="light"
                effect="solid"
                border={true}
                borderColor={"#8c969b"}
                place={"top"}
                getContent={(dataTip) => <span>{dataTip}</span>}
            />
        </Page>
    );
};

export default App;
