export const development = {
    api: "https://toolscalculations.azurewebsites.net/",
    sentry: {
        dsn: "https://0911d3f7e51846c4996b8088168525bc@o399431.ingest.sentry.io/5940610",
        env: "development",
        sampleRate: 1.0,
    },
    gtm: {
        gtmId: "GTM-55SCF3M",
    },
    auth: {
        clientId: "256cd153-7731-434f-ac1e-e955e40f962b",
        authority: "https://login-staging.bbri.be/wtcbidencestaging.onmicrosoft.com/b2c_1a_signup_signin/",
        knownAuthorities: ["https://login-staging.bbri.be/"],
        redirectUri: `${window.location.protocol}//${window.location.hostname}`,
        postLogoutRedirectUri: `${window.location.protocol}//${window.location.hostname}`,
        cacheLocation: "localStorage",
        scopes: ["https://wtcbidencestaging.onmicrosoft.com/BIMio/BIMio.Access", "openid", "profile", "offline_access"],
    },
    feedback: "https://toolsmodels.azurewebsites.net/api/v1/Feedback",
};
