import { Button, Panel, useLanguage, usePrismic } from "@buildwise/ui";
import { Edit, Eye, Trash2 } from "react-feather";

import styles from "./ProjectsTable.module.css";

const ProjectTable = ({ data, onView, onEdit, onDelete }) => {
    const { language } = useLanguage();
    const [document, { state: prismicState }] = usePrismic("fenestrio");

    if (prismicState !== "loaded") return null;

    return (
        <Panel className={styles.container}>
            <div className={styles.table}>
                <div className={styles.head}>
                    <span className={styles.project}>{document.data.data_table_header_name}</span>
                    <span className={styles.date}>{document.data.data_table_header_creation_date}</span>
                    <span className={styles.date}>{document.data.data_table_header_modification_date}</span>
                    <span className={styles.actions}>{document.data.data_table_header_actions}</span>
                </div>
                <div className={styles.body}>
                    {(!Boolean(data) || data.length === 0) && (
                        <div className={styles.row}>
                            <span className={styles.error}>{document.data.data_table_content_no_results}</span>
                        </div>
                    )}

                    {data &&
                        data.map((item, index) => {
                            const creationDate = item.creationDate && new Date(item.creationDate).toLocaleDateString(language);
                            const modificationDate = item.modificationDate && new Date(item.modificationDate).toLocaleDateString(language);

                            return (
                                <div key={index} className={styles.row}>
                                    <span className={styles.name}>{item.name ?? item.title}</span>
                                    <span className={styles.date}>
                                        <strong>{document.data.data_table_header_creation_date}</strong>
                                        {creationDate}
                                    </span>
                                    <span className={styles.date}>
                                        <strong>{document.data.data_table_header_modification_date}</strong>
                                        {modificationDate}
                                    </span>
                                    <span className={styles.actions}>
                                        <Button id="projects-table-action-view-button" variant="action" startIcon={<Eye />} onClick={() => onView(item)}>
                                            {document.data.button_data_table_action_view}
                                        </Button>
                                        <Button id="projects-table-action-edit-button" variant="action" startIcon={<Edit />} onClick={() => onEdit(item)} style={{ margin: "0 10px" }}>
                                            {document.data.button_data_table_action_edit}
                                        </Button>
                                        <Button id="projects-table-action-delete-button" variant="action" startIcon={<Trash2 />} onClick={() => onDelete(item)}>
                                            {document.data.button_data_table_action_delete}
                                        </Button>
                                    </span>
                                </div>
                            );
                        })}
                </div>
            </div>
        </Panel>
    );
};

export default ProjectTable;
