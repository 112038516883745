import { config } from "../_configuration/configuration";

export const getGlassTypes = async () => {
    const endpoint = `${config.api}api/v1/Calculators/GlassThickness/GlassTypes`;
    const options = {
        method: "GET",
    };

    const response = await fetch(endpoint, options);
    if (response.ok) return await response.json();

    return undefined;
};

export const getCalculations = async (projectId) => {
    const endpoint = `${config.api}api/v1/Projects/${projectId}/GlassThicknessStorage`;
    const options = {
        method: "GET",
    };

    const response = await fetch(endpoint, options);
    if (response.ok) return await response.json();

    return undefined;
};

export const getCalculation = async (projectId, calculationId) => {
    const endpoint = `${config.api}api/v1/Projects/${projectId}/GlassThicknessStorage/${calculationId}`;
    const options = {
        method: "GET",
    };

    const response = await fetch(endpoint, options);
    if (response.ok) return await response.json();

    return undefined;
};

export const performCalculation = async (determinations) => {
    const endpoint = `${config.api}api/v1/Calculators/GlassThickness/CalculateBulk`;
    const options = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(determinations),
    };

    const response = await fetch(endpoint, options);
    if (response.ok) return await response.json();

    return undefined;
};

export const createCalculation = async (projectId, calculation) => {
    const endpoint = `${config.api}api/v1/Projects/${projectId}/GlassThicknessStorage`;
    const options = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(calculation),
    };

    const response = await fetch(endpoint, options);
    if (response.ok) return await response.json();

    return undefined;
};

export const updateCalculationInfo = async (projectId, calculation) => {
    const endpoint = `${config.api}api/v1/Projects/${projectId}/GlassThicknessStorage/${calculation.id}`;
    const options = {
        method: "PATCH",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(calculation),
    };

    const response = await fetch(endpoint, options);

    return response.ok;
};

export const updateCalculation = async (projectId, calculation) => {
    const endpoint = `${config.api}api/v1/Projects/${projectId}/GlassThicknessStorage/${calculation.id}`;
    const options = {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(calculation),
    };

    const response = await fetch(endpoint, options);

    return response.ok;
};

export const deleteCalculation = async (projectId, calculationId) => {
    const endpoint = `${config.api}api/v1/Projects/${projectId}/GlassThicknessStorage/${calculationId}`;
    const options = {
        method: "DELETE",
    };

    const response = await fetch(endpoint, options);

    return response.ok;
};

export const downloadExcelTemplate = async (language) => {
    const endpoint = `${config.api}api/v1/Calculators/GlassThickness/Template/${language}`;
    const options = {
        method: "GET",
    };

    const response = await fetch(endpoint, options);
    if (!response.ok) return undefined;

    const blob = await response.blob();

    const url = window.URL.createObjectURL(blob);
    const tempLink = document.createElement("a");
    tempLink.href = url;
    tempLink.setAttribute("download", `Template_GT_${language}.xlsx`);
    tempLink.click();
    window.URL.revokeObjectURL(url);
};

export const importExcelTemplate = async (file) => {
    const endpoint = `${config.api}api/v1/Calculators/GlassThickness/CalculateByXl`;
    const form = new FormData();
    form.append("File", file);
    const options = {
        method: "POST",
        headers: {
            Accept: "application/json",
        },
        body: form,
    };

    const response = await fetch(endpoint, options);
    if (response.ok) return await response.json();

    return undefined;
};

export const downloadAsPdf = async (data, language, filename) => {
    const endpoint = `${config.api}api/v1/Calculators/GlassThickness/Pdf/${language}`;
    const options = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
    };

    const response = await fetch(endpoint, options);
    if (!response.ok) return undefined;

    const blob = await response.blob();

    const url = window.URL.createObjectURL(blob);
    const tempLink = document.createElement("a");
    tempLink.href = url;
    tempLink.setAttribute("download", filename);
    tempLink.click();
    window.URL.revokeObjectURL(url);
};
