import { useContext, useEffect, useState } from "react";
import { generatePath, Link, useNavigate, useParams } from "react-router-dom";
import { Edit, Plus, Trash2 } from "react-feather";
import {
    Breadcrumb,
    Button,
    Content,
    ContentActions,
    ContentBody,
    ContentHeader,
    ContentInfo,
    DropdownButton,
    DropdownItem,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    ModalTitle,
    Panel,
    PanelBody,
    PanelHeader,
    Separator,
    Spinner,
    useAuthentication,
    useLanguage,
    usePrismic,
} from "@buildwise/ui";
import EditProjectModal from "../../components/EditProjectModal/EditProjectModal";
import EditCalculationModal from "../../components/EditCalculationModal/EditCalculationModal";
import { AppContext } from "../../context/AppContextProvider";
import { deleteProject, getProjects } from "../../adapters/ProjectsAdapter";
import { deleteCalculation, getCalculations } from "../../adapters/CalculationsAdapter";
import {
    getCalculations as getGlassThicknessCalculations,
    deleteCalculation as deleteGlassThicknessCalculation,
} from "../../adapters/GlassThicknessCalculatorAdapter";
import { config } from "../../_configuration/configuration";
import ProjectTable from "../../components/ProjectsTable/ProjectsTable";

import styles from "./Projects.module.css";

const Project = () => {
    const { state, dispatch } = useContext(AppContext);
    const { isAuthenticated } = useAuthentication();
    const [project, setProject] = useState(null);
    const [isEdittingProject, setIsEdittingProject] = useState(false);
    const [isEdittingCalculation, setIsEdittingCalculation] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [hasAccess, setHasAccess] = useState(true);
    const [warning, setWarning] = useState({ visible: false });

    const navigate = useNavigate();
    const params = useParams();

    const { language } = useLanguage();
    const [document] = usePrismic(config.prismic.documentType);

    useEffect(() => {
        if (isAuthenticated) {
            if (state.projects?.length > 0) return;
            getProjects().then(storeProjects);
        }
    }, [isAuthenticated]);

    useEffect(() => {
        if (isAuthenticated && params.projectId && state.projects.length > 0) {
            const project = state.projects.find((x) => Number(x.id) === Number(params.projectId));
            if (project) setProject(project);
            else {
                setHasAccess(false);
                setIsLoading(false);
            }
        }
    }, [state.projects]);

    useEffect(() => {
        if (project && !Boolean(state.calculations[project.id])) {
            Promise.all([
                getCalculations(project.id).then(storeCalculations),
                getGlassThicknessCalculations(project.id).then(storeGlassThicknessCalculations),
            ]).then(() => {
                setIsLoading(false);
            });
        } else if (project) setIsLoading(false);
    }, [project]);

    const storeProjects = (projects) => {
        if (projects) dispatch({ type: "SET_PROJECTS", payload: projects });
    };

    const storeCalculations = (calculations) => {
        if (calculations) dispatch({ type: "SET_CALCULATIONS", payload: { projectId: project.id, calculations: calculations } });
    };

    const storeGlassThicknessCalculations = (calculations) => {
        if (calculations)
            dispatch({
                type: "SET_GLASSTHICKNESS_CALCULATIONS",
                payload: { projectId: project.id, calculations: calculations },
            });
    };

    const onViewCalculation = (calculation) => {
        if (calculation.calculationAppId === 1) {
            navigate(generatePath(config.routes.existingWindowsCalculation[language], { ...params, projectId: project.id, id: calculation.id }));
        } else {
            if (calculation.hasInputfile) {
                navigate(generatePath(config.routes.existingPanelsImportCalculation[language], { ...params, projectId: project.id, id: calculation.id }));
            } else {
                navigate(generatePath(config.routes.existingPanelsCalculation[language], { ...params, projectId: project.id, id: calculation.id }));
            }
        }
    };

    const onViewGlassThicknessCalculation = (calculation) => {
        if (calculation.isMatrix) {
            navigate(generatePath(config.routes.existingGlassImportCalculationProject[language], { ...params, projectId: project.id, id: calculation.id }));
        } else {
            navigate(generatePath(config.routes.existingGlassCalculationProject[language], { ...params, projectId: project.id, id: calculation.id }));
        }
    };

    const onEditProject = () => setIsEdittingProject(true);

    const onEditCalculation = (calculation) => setIsEdittingCalculation(calculation);

    const onEditGlassThicknessCalculation = (calculation) => setIsEdittingCalculation({ ...calculation, calculationAppId: "GlassThickness" });

    const onDeleteProject = (project, confirmed = false, result = false) => {
        if (!confirmed) {
            setWarning({
                visible: true,
                title: document.data.calculation_common_remove_title,
                text: document.data.calculation_common_remove_description.replace("{{name}}", project.name),
                callback: (bool) => onDeleteProject(project, true, bool),
            });

            return;
        }

        setWarning({ visible: false });

        if (!result) return;

        deleteProject(project.id).then((success) => removeProject(success, project.id));
        navigate("/");
    };

    const onDeleteCalculation = (calculation, confirmed = false, result = false) => {
        if (!confirmed) {
            setWarning({
                visible: true,
                title: document.data.calculation_common_remove_title,
                text: document.data.calculation_common_remove_description.replace("{{name}}", calculation.name),
                callback: (bool) => onDeleteCalculation(calculation, true, bool),
            });

            return;
        }

        setWarning({ visible: false });

        if (!result) return;

        deleteCalculation(project.id, calculation.id).then((success) => removeCalculation(success, calculation.id));
    };

    const onDeleteGlassThicknessCalculation = (calculation, confirmed = false, result = false) => {
        if (!confirmed) {
            setWarning({
                visible: true,
                title: document.data.calculation_common_remove_title,
                text: document.data.calculation_common_remove_description.replace("{{name}}", calculation.title),
                callback: (bool) => onDeleteGlassThicknessCalculation(calculation, true, bool),
            });

            return;
        }

        setWarning({ visible: false });

        if (!result) return;
        deleteGlassThicknessCalculation(project.id, calculation.id).then((success) => removeGlassThicknessCalculation(success, calculation.id));
    };

    const removeProject = (success, id) => {
        if (!success) return;

        dispatch({ type: "REMOVE_PROJECT", payload: id });
    };

    const removeCalculation = (success, calculationId) => {
        if (success) dispatch({ type: "REMOVE_CALCULATION", payload: { projectId: project.id, calculationId: calculationId } });
    };

    const removeGlassThicknessCalculation = (success, calculationId) => {
        if (success)
            dispatch({
                type: "REMOVE_GLASSTHICKNESS_CALCULATION",
                payload: { projectId: project.id, calculationId: calculationId },
            });
    };

    return isLoading ? (
        <Spinner />
    ) : hasAccess ? (
        <>
            <Breadcrumb>{project.name}</Breadcrumb>

            <Content>
                <ContentHeader className={styles.header}>
                    <h1>{project.name}</h1>
                    <ContentActions className={styles.actions}>
                        <Button id="project-overview-delete-button" variant="tertiary" startIcon={<Trash2 />} onClick={() => onDeleteProject(project)} style={{ marginRight: 10 }}>
                            {document.data.project_overview_delete_project}
                        </Button>
                        <Button id="project-overview-detail-edit-button" variant="secondary" startIcon={<Edit />} onClick={() => onEditProject()}>
                            {document.data.button_project_overview_edit_details}
                        </Button>
                    </ContentActions>
                </ContentHeader>
                <Separator color="green">{document.data.projects_overview_page_title}</Separator>
                <ContentInfo style={{ flexWrap: "wrap" }}>
                    <div className={styles.projectinfo}>
                        <h4>{document.data.project_overview_description_title}</h4>
                        <p>{project.description}</p>
                    </div>
                    <div>
                        {project && project.id > 0 && (
                            <>
                                <p>
                                    <strong>{document.data.project_overview_creation_date}</strong> {new Date(project.creationDate).toLocaleDateString("nl-BE")}
                                </p>
                                <p>
                                    <strong>{document.data.project_overview_modification_date}</strong>{" "}
                                    {new Date(project.modificationDate).toLocaleDateString("nl-BE")}
                                </p>
                            </>
                        )}
                    </div>
                </ContentInfo>
                <ContentBody>
                    <ContentHeader className={styles.header}>
                        <h2>{document.data.project_overview_general_performance_of_windows_title}</h2>
                        <ContentActions className={styles.actions}>
                            <Button
                                id="project-new-windows-calculation-button"
                                startIcon={<Plus />}
                                onClick={() =>
                                    navigate(generatePath(config.routes.newWindowsCalculationProject[language], { ...params, projectId: project.id }))
                                }
                            >
                                {document.data.navigation_new_windows_calculation}
                            </Button>
                        </ContentActions>
                    </ContentHeader>

                    <ProjectTable
                        data={state.calculations[project.id]?.filter((x) => x.calculationAppId === 1)}
                        onView={onViewCalculation}
                        onEdit={onEditCalculation}
                        onDelete={onDeleteCalculation}
                    />

                    <ContentHeader className={styles.header} style={{ marginTop: "30px" }}>
                        <h2>{document.data.project_overview_general_performance_of_panels_title}</h2>
                        <ContentActions className={styles.actions}>
                            <DropdownButton startIcon={<Plus />} label={document.data.navigation_new_panels_calculation}>
                                <DropdownItem>
                                    <Link id="project-new-panels-calculation-link" to={generatePath(config.routes.newPanelsCalculationProject[language], { ...params, projectId: project.id })}>
                                        {document.data.navigation_manual_determination}
                                    </Link>
                                </DropdownItem>
                                <DropdownItem>
                                    <Link id="project-new-panels-import-calculation-link" to={generatePath(config.routes.newPanelsImportCalculationProject[language], { ...params, projectId: project.id })}>
                                        {document.data.navigation_list_determination}
                                    </Link>
                                </DropdownItem>
                            </DropdownButton>
                        </ContentActions>
                    </ContentHeader>
                    <ProjectTable
                        data={state.calculations[project.id]?.filter((x) => x.calculationAppId === 2)}
                        onView={onViewCalculation}
                        onEdit={onEditCalculation}
                        onDelete={onDeleteCalculation}
                    />

                    <ContentHeader className={styles.header} style={{ marginTop: "30px" }}>
                        <h2>{document.data.project_overview_glass_thickness_title}</h2>
                        <ContentActions className={styles.actions}>
                            <DropdownButton startIcon={<Plus />} label={document.data.navigation_new_glass_thickness_calculation}>
                                <DropdownItem>
                                    <Link id="project-new-glass-calculation-link" to={generatePath(config.routes.newGlassCalculationProject[language], { ...params, projectId: project.id })}>
                                        {document.data.navigation_manual_determination}
                                    </Link>
                                </DropdownItem>
                                <DropdownItem>
                                    <Link id="project-new-glass-import-calculation-link" to={generatePath(config.routes.newGlassImportCalculationProject[language], { ...params, projectId: project.id })}>
                                        {document.data.navigation_list_determination}
                                    </Link>
                                </DropdownItem>
                            </DropdownButton>
                        </ContentActions>
                    </ContentHeader>
                    <ProjectTable
                        data={state.glassThicknessCalculations[project.id]}
                        onView={onViewGlassThicknessCalculation}
                        onEdit={onEditGlassThicknessCalculation}
                        onDelete={onDeleteGlassThicknessCalculation}
                    />
                </ContentBody>

                <EditProjectModal isOpen={isEdittingProject} project={project} onClose={() => setIsEdittingProject(false)} />

                <EditCalculationModal
                    isOpen={isEdittingCalculation !== null}
                    onClose={() => {
                        setIsEdittingCalculation(null);
                        // reloadCalculationInfo();
                    }}
                    calculation={isEdittingCalculation}
                />

                <Modal open={warning.visible} backdrop onClose={() => setWarning({ visible: false })}>
                    <ModalHeader closeButton>
                        <ModalTitle>{document.data.calculation_common_remove_title}</ModalTitle>
                    </ModalHeader>
                    <ModalBody>{warning.text}</ModalBody>
                    <ModalFooter>
                        <Button id="project-warning-cancel-button" variant="tertiary" onClick={() => setWarning({ visible: false })}>
                            {document.data.button_common_cancel}
                        </Button>
                        <Button id="project-warning-confirm-button" onClick={warning.callback}>{document.data.calculation_common_yes}</Button>
                    </ModalFooter>
                </Modal>
            </Content>
        </>
    ) : (
        <Content>
            <ContentBody>
                <Panel>
                    <PanelHeader>
                        <h2>{document.data.project_overview_project_not_found}</h2>
                    </PanelHeader>
                    <PanelBody>{document.data.project_overview_project_not_found_details}</PanelBody>
                </Panel>
            </ContentBody>
        </Content>
    );
};

export default Project;
