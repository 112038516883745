import { useContext, useState, useEffect, useRef } from "react";
import { PrismicRichText } from "@prismicio/react";
import { asText } from "@prismicio/helpers";
import {
    Button,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    ModalTitle,
    Numeric,
    Panel,
    PanelBody,
    PanelFooter,
    PanelHeader,
    QuestionBox,
    QuestionBoxBody,
    QuestionBoxInfo,
    QuestionBoxTitle,
    Radio,
    SelectGroup,
    SelectGroupItem,
    SelectGroupItemDescription,
    SelectGroupItemImage,
    SelectGroupItemTitle,
    Text,
    usePrismic,
} from "@buildwise/ui";
import Result from "./Result";
import { AppContext } from "../../context/AppContextProvider";
import { performCalculation } from "../../adapters/CalculatorAdapter";

import styles from "./Windows.module.css";
import { config } from "../../_configuration/configuration";

const Calculator = (props) => {
    const { state } = useContext(AppContext);
    const [warning, setWarning] = useState({ visible: false });

    const [showCalc, setShowCalc] = useState(true);

    const [postalCodes, setPostalCodes] = useState([]);
    const [showPostalCodes, setShowPostalCodes] = useState(false);
    const [filterPostalCodes, setFilterPostalCodes] = useState([]);

    const [postalCode, setPostalCode] = useState("");
    const [wind, setWind] = useState("0");
    const [height, setHeight] = useState("");
    const [facade, setFacade] = useState(false);
    const [terrain, setTerrain] = useState("");
    const [airco, setAirco] = useState(false);

    const [result, setResult] = useState({});
    const headerRef = useRef();

    const [document] = usePrismic(config.prismic.documentType);

    useEffect(() => {
        headerRef.current = window.document.getElementById(`calc-${props.i}`);
    }, []);

    useEffect(() => {
        setPostalCodes(state.calculatorData[1]);
    }, [state.calculatorData[1]]);

    useEffect(() => {
        if (Object.keys(props.result).length > 1) {
            setShowCalc(false);
            setResult(props.result);

            if (!props.result.inputParameters || props.result.inputParameters.length === 0) return;

            const input = props.result.inputParameters.sort((a, b) => (a.parameterId > b.parameterId ? 1 : -1));

            setWind(input[0].value);
            setPostalCode(input[1].value);
            setTerrain(input[2].value);
            setHeight(input[3].value);
            setAirco(input[4].value === "True");
            setFacade(input[5].value === "True");
        } else {
            setShowCalc(true);
            setWind("0");
            setPostalCode("");
            setTerrain("");
            setHeight("");
            setAirco(false);
            setFacade(false);
        }
    }, [props.result]);

    const validateInput = () => {
        const errors = [];

        if (wind === "0" || postalCode === "") errors.push(document.data.calculation_common_invalid_postal_code);
        if (terrain === "") errors.push(document.data.calculation_common_invalid_roughness_category);
        if (height === "" || Number(height) < 0 || Number(height) > 200) errors.push(document.data.calculation_common_invalid_reference_height);

        return {
            valid: errors.length === 0,
            errors,
        };
    };

    const calculate = () => {
        const validation = validateInput();
        if (!validation.valid) {
            setWarning({
                visible: true,
                prompt: false,
                title: document.data.calculation_common_error_title_invalid_properties,
                text: (
                    <>
                        <p>{document.data.calculation_common_error_massage_invalid_properties}</p>
                        <ul>
                            {validation.errors.map((error, index) => (
                                <li key={index}>{error}</li>
                            ))}
                        </ul>
                    </>
                ),
                callback: (bool) => setWarning({ visible: false }),
            });

            return;
        }

        const body = {
            windSpeed: Number(wind),
            category: Number(terrain),
            height: parseFloat(height),
            hasAirco: airco,
            isFacadeAligned: facade,
            location: postalCode,
        };

        performCalculation(1, body).then(handleCalculationResult);
    };

    const handleCalculationResult = (calculation) => {
        const storedRes = { ...result };
        const newResult = calculation.calculationResults[0];
        newResult.tempId = props.result.tempId;

        if (storedRes.id > 0) newResult.id = storedRes.id;
        else newResult.id = -1;

        setResult(newResult);

        props.callBack(newResult);
        headerRef.current.scrollIntoView();
        setShowCalc(false);
    };

    const cancelEdit = () => {
        setShowCalc(false);

        setWind(props.result.inputParameters[0].value);
        setPostalCode(props.result.inputParameters[1].value);
        setTerrain(props.result.inputParameters[2].value);
        setHeight(props.result.inputParameters[3].value);
        setAirco(props.result.inputParameters[4].value === "True");
        setFacade(props.result.inputParameters[5].value === "True");
    };

    const onSelectedZip = (zip) => {
        setWind(zip.windSpeed);
        setPostalCode(zip.code + " " + zip.city);
        setShowPostalCodes(false);
    };

    const onFilterZips = (value) => {
        setPostalCode(value);
        if (value.length > 1) {
            setShowPostalCodes(true);
            const filteredZips = postalCodes.filter((zip) => zip.code.substring(0, value.length) === value);
            setFilterPostalCodes(filteredZips);
        } else {
            setShowPostalCodes(false);
            setWind("0");
        }
    };

    const terrainCat = [
        {
            id: "0",
            image: "/img/windows/category0.png",
            title: asText(document.data.terrain_roughness_category_0_title),
            desc: asText(document.data.terrain_roughness_category_0_description),
        },
        {
            id: "1",
            image: "/img/windows/category1.png",
            title: asText(document.data.terrain_roughness_category_1_title),
            desc: asText(document.data.terrain_roughness_category_1_description),
        },
        {
            id: "2",
            image: "/img/windows/category2.png",
            title: asText(document.data.terrain_roughness_category_2_title),
            desc: asText(document.data.terrain_roughness_category_2_description),
        },
        {
            id: "3",
            image: "/img/windows/category3.png",
            title: asText(document.data.terrain_roughness_category_3_title),
            desc: asText(document.data.terrain_roughness_category_3_description),
        },
        {
            id: "4",
            image: "/img/windows/category4.png",
            title: asText(document.data.terrain_roughness_category_4_title),
            desc: asText(document.data.terrain_roughness_category_4_description),
        },
    ];

    return (
        <>
            <Panel id="calculation">
                <PanelHeader>
                    <h2 style={{ marginBottom: "32px" }} id={`calc-${props.i}`}>
                        {showCalc ? document.data.calculation_windows_determination : document.data.calculation_windows_result} {props.i + 1}
                    </h2>
                </PanelHeader>

                {!showCalc && <Result result={result} id={result.tempId} onEdit={() => setShowCalc(true)} onRemove={props.onRemove} />}

                {showCalc && (
                    <>
                        <PanelBody>
                            <QuestionBox>
                                <QuestionBoxTitle>{document.data.calculation_windows_postal_code_question}</QuestionBoxTitle>
                                <QuestionBoxBody style={{ position: "relative" }}>
                                    <Text
                                        type="text"
                                        value={postalCode}
                                        placeholder={document.data.calculation_windows_postal_code_placeholder}
                                        onChange={(e) => onFilterZips(e.target.value)}
                                        required
                                    />
                                    {showPostalCodes && filterPostalCodes.length > 0 && (
                                        <div className={styles.zipcodes}>
                                            {filterPostalCodes.map((zip, i) => (
                                                <p key={i} onClick={() => onSelectedZip(zip)}>
                                                    {zip.code} - {zip.city}
                                                </p>
                                            ))}
                                        </div>
                                    )}
                                </QuestionBoxBody>
                                <QuestionBoxInfo>
                                    <p
                                        dangerouslySetInnerHTML={{
                                            __html: asText(document.data.calculation_windows_postal_code_information).replace("{{windspeed}}", wind),
                                        }}
                                    />
                                </QuestionBoxInfo>
                            </QuestionBox>

                            <QuestionBox className={styles.fullWidthQuestion}>
                                <QuestionBoxTitle>{document.data.calculation_windows_roughness_category_question}</QuestionBoxTitle>
                                <QuestionBoxBody>
                                    <SelectGroup selected={terrain} onSelectionChanged={(id) => setTerrain(id)}>
                                        {terrainCat.map((t, index) => (
                                            <SelectGroupItem identifier={t.id} key={index} className={t.id === "0" && wind !== 26 ? "disabled" : ""}>
                                                <SelectGroupItemTitle>{t.title}</SelectGroupItemTitle>
                                                <SelectGroupItemDescription component={"div"}>{t.desc}</SelectGroupItemDescription>
                                                <SelectGroupItemImage src={t.image} alt={t.title} />
                                            </SelectGroupItem>
                                        ))}
                                    </SelectGroup>
                                </QuestionBoxBody>
                            </QuestionBox>

                            <QuestionBox>
                                <QuestionBoxTitle>{document.data.calculation_windows_reference_height_question}</QuestionBoxTitle>
                                <QuestionBoxBody style={{ position: "relative" }}>
                                    <Numeric value={height} onChange={(e) => setHeight(e.target.value)} legend={"m"} required min={0} max={200} />
                                </QuestionBoxBody>
                                <QuestionBoxInfo>
                                    <PrismicRichText field={document.data.calculation_windows_reference_height_information} />
                                </QuestionBoxInfo>
                            </QuestionBox>

                            <QuestionBox>
                                <QuestionBoxTitle>{document.data.calculation_windows_climate_control_question}</QuestionBoxTitle>
                                <QuestionBoxBody style={{ marginTop: "15px" }}>
                                    <Radio label={document.data.calculation_common_yes} name="airco" checked={airco} onChange={() => setAirco(true)} />
                                    <Radio label={document.data.calculation_common_no} name="airco" checked={!airco} onChange={() => setAirco(false)} />
                                </QuestionBoxBody>
                            </QuestionBox>

                            <QuestionBox>
                                <QuestionBoxTitle>{document.data.calculation_windows_water_protection_question}</QuestionBoxTitle>
                                <QuestionBoxBody style={{ marginTop: "15px" }}>
                                    <Radio label={document.data.calculation_common_yes} name="facade" checked={facade} onChange={() => setFacade(true)} />
                                    <Radio label={document.data.calculation_common_no} name="facade" checked={!facade} onChange={() => setFacade(false)} />
                                </QuestionBoxBody>
                            </QuestionBox>
                        </PanelBody>

                        <PanelFooter>
                            <div style={{ display: "flex", marginLeft: "auto" }}>
                                {props.result.inputParameters && props.result.inputParameters.length > 0 && (
                                    <Button id="windows-edit-cancel-button" variant="tertiary" style={{ marginRight: "10px" }} onClick={() => cancelEdit()}>
                                        {document.data.button_common_cancel}
                                    </Button>
                                )}
                                <Button id="windows-edit-determination-button" variant="secondary" onClick={() => calculate()}>
                                    {document.data.button_common_determine}
                                </Button>
                            </div>
                        </PanelFooter>
                    </>
                )}
            </Panel>

            <Modal open={warning.visible} backdrop onClose={warning.callback}>
                <ModalHeader closeButton>
                    <ModalTitle>{warning.title}</ModalTitle>
                </ModalHeader>
                <ModalBody>{warning.text}</ModalBody>
                <ModalFooter>
                    <Button id="windows-warning-confirm-button" onClick={warning.callback}>{document.data.button_common_ok}</Button>
                </ModalFooter>
            </Modal>
        </>
    );
};

export default Calculator;
