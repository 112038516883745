import { useContext, useEffect, useState } from "react";
import { Eye, EyeOff } from "react-feather";
import { PrismicRichText } from "@prismicio/react";
import { asText } from "@prismicio/helpers";
import { Box, BoxBody, BoxTitle, usePrismic } from "@buildwise/ui";
import { AppContext } from "../../context/AppContextProvider";

import styles from "./Panels.module.css";
import { config } from "../../_configuration/configuration";

const Result = ({ result }) => {
    const { state } = useContext(AppContext);

    const [showParams, setShowParams] = useState(false);
    const [resultValues, setResultValues] = useState({});
    const [inputValues, setInputValues] = useState({});

    const [document] = usePrismic(config.prismic.documentType);

    useEffect(() => {
        if (state.calculatorDefinitions[2]) handleCalculatorDefinition(state.calculatorDefinitions[2]);
    }, [state.calculatorDefinitions]);

    const handleCalculatorDefinition = (definition) => {
        const inputData = {};

        for (let i = 0; i < definition.parameters.length; i++) {
            const pDef = definition.parameters[i];
            const input = result.inputParameters.find((x) => x.parameterId === pDef.id);

            if (!input) {
                inputData[pDef.name] = null;
                continue;
            }

            let value;
            switch (pDef.dataType) {
                case "Int":
                case "Double":
                    value = Number(input.value);
                    break;
                case "Boolean":
                    value = input.value === "True";
                    break;
                default:
                    value = input.value;
            }

            inputData[pDef.name] = value;
        }

        const resultData = {};
        for (let i = 0; i < definition.calculationResultDefinitions.length; i++) {
            const rDef = definition.calculationResultDefinitions[i];
            const res = result.resultItems.find((x) => x.calculationResultDefinitionId === rDef.id);

            if (!res) {
                resultData[rDef.name] = null;
                continue;
            }

            if (rDef.name === "InteriorFractureRequirementExceptionalType" && res.value === "A") {
                res.value = "A*";
            }

            resultData[rDef.name] = res.value;
        }

        setResultValues(resultData);
        setInputValues(inputData);
    };

    const renderDropHeight = (value) => {
        switch (value) {
            case 1:
                return document.data.calculation_panels_smaller_or_equal_to_one_and_a_half;
            case 110000:
                return document.data.calculation_panels_between_one_and_a_half_and_twelve;
            case 120000:
                return document.data.calculation_panels_larger_or_equal_to_twelve;
            default:
                return "-";
        }
    };

    return (
        <>
            <div className={styles.container} style={{ marginTop: "25px" }}>
                <Box useAccent>
                    <BoxTitle>{document.data.calculation_panels_results_outer_panel}</BoxTitle>
                    <BoxBody className={styles.results}>
                        <div className={styles.block}>
                            <p className={styles.input}>
                                <span className={styles.key}>{document.data.calculation_panels_shock_resistance}</span>
                                <span className={styles.value}>
                                    {resultValues["ExteriorShockResistanceClass"] === "NoRequirement"
                                        ? document.data.calculation_panels_result_no_requirement
                                        : resultValues["ExteriorShockResistanceClass"]}
                                </span>
                            </p>
                            <p className={styles.norm}>{document.data.calculation_windows_according_to_norm_windows_body_impact}</p>
                        </div>

                        {resultValues["ExteriorFractureRequirementStandardType"] !== undefined && (
                            <div className={styles.block}>
                                <p className={styles.input}>
                                    <span className={styles.key}>{document.data.calculation_panels_break_type}</span>
                                    <span className={styles.value}>{resultValues["ExteriorFractureRequirementStandardType"]}</span>
                                </p>
                                <p className={styles.norm}>
                                    {document.data.calculation_panels_standard} - {document.data.calculation_panels_according_to_norm_glass_in_building}
                                </p>
                            </div>
                        )}

                        {resultValues["ExteriorFractureRequirementAlternativeType"] !== undefined && (
                            <div className={styles.block}>
                                <p className={styles.input}>
                                    <span className={styles.key}>{document.data.calculation_panels_break_type}</span>
                                    <span className={styles.value}>{resultValues["ExteriorFractureRequirementAlternativeType"]}</span>
                                </p>
                                <p className={styles.norm}>
                                    {document.data.calculation_panels_alternative} - {document.data.calculation_panels_according_to_norm_glass_in_building}
                                </p>
                            </div>
                        )}

                        {resultValues["ExteriorFractureRequirementExceptionalType"] !== undefined && (
                            <div className={styles.block}>
                                <p className={styles.input}>
                                    <span className={styles.key}>{document.data.calculation_panels_break_type}</span>
                                    <span className={styles.value}>{resultValues["ExteriorFractureRequirementExceptionalType"]}</span>
                                </p>
                                <p className={styles.norm}>
                                    {document.data.calculation_panels_exception} - {document.data.calculation_panels_according_to_norm_glass_in_building}
                                </p>
                            </div>
                        )}

                        {resultValues["ExteriorFractureRequirementType"] !== undefined &&
                            resultValues["ExteriorFractureRequirementStandardType"] === undefined && (
                                <div className={styles.block}>
                                    <p className={styles.input}>
                                        <span className={styles.key}>{document.data.calculation_panels_break_type}</span>
                                        <span className={styles.value}>{resultValues["ExteriorFractureRequirementType"]}</span>
                                    </p>
                                    <p className={styles.norm}>{document.data.calculation_panels_according_to_norm_glass_in_building}</p>
                                </div>
                            )}
                    </BoxBody>
                </Box>

                <Box useAccent>
                    <BoxTitle>{document.data.calculation_panels_results_inner_panel}</BoxTitle>
                    <BoxBody className={styles.results}>
                        <div className={styles.block}>
                            <p className={styles.input}>
                                <span className={styles.key}>{document.data.calculation_panels_shock_resistance}</span>
                                <span className={styles.value}>
                                    {resultValues["InteriorShockResistanceClass"] === "NoRequirement"
                                        ? document.data.calculation_panels_result_no_requirement
                                        : resultValues["InteriorShockResistanceClass"]}
                                </span>
                            </p>
                            <p className={styles.norm}>{document.data.calculation_windows_according_to_norm_windows_body_impact}</p>
                        </div>

                        {resultValues["InteriorFractureRequirementStandardType"] !== undefined && (
                            <div className={styles.block}>
                                <p className={styles.input}>
                                    <span className={styles.key}>{document.data.calculation_panels_break_type}</span>
                                    <span className={styles.value}>{resultValues["InteriorFractureRequirementStandardType"]}</span>
                                </p>
                                <p className={styles.norm}>
                                    {document.data.calculation_panels_standard} - {document.data.calculation_panels_according_to_norm_glass_in_building}
                                </p>
                            </div>
                        )}

                        {resultValues["InteriorFractureRequirementAlternativeType"] !== undefined && (
                            <div className={styles.block}>
                                <p className={styles.input}>
                                    <span className={styles.key}>{document.data.calculation_panels_break_type}</span>
                                    <span className={styles.value}>{resultValues["InteriorFractureRequirementAlternativeType"]}</span>
                                </p>
                                <p className={styles.norm}>
                                    {document.data.calculation_panels_alternative} - {document.data.calculation_panels_according_to_norm_glass_in_building}
                                </p>
                            </div>
                        )}

                        {resultValues["InteriorFractureRequirementExceptionalType"] !== undefined && (
                            <div className={styles.block}>
                                <p className={styles.input}>
                                    <span className={styles.key}>{document.data.calculation_panels_break_type}</span>
                                    <span className={styles.value}>{resultValues["InteriorFractureRequirementExceptionalType"]}</span>
                                </p>
                                <p className={styles.norm}>
                                    {document.data.calculation_panels_exception} - {document.data.calculation_panels_according_to_norm_glass_in_building}
                                </p>
                            </div>
                        )}

                        {resultValues["InteriorFractureRequirementType"] !== undefined &&
                            resultValues["InteriorFractureRequirementStandardType"] === undefined && (
                                <div className={styles.block}>
                                    <p className={styles.input}>
                                        <span className={styles.key}>{document.data.calculation_panels_break_type}</span>
                                        <span className={styles.value}>{resultValues["InteriorFractureRequirementType"]}</span>
                                    </p>
                                    <p className={styles.norm}>{document.data.calculation_panels_according_to_norm_glass_in_building}</p>
                                </div>
                            )}
                    </BoxBody>
                </Box>

                <Box useAccent>
                    <BoxTitle>{document.data.calculation_panels_results_railing}</BoxTitle>
                    <BoxBody className={styles.results}>
                        <div className={styles.block}>
                            <p className={styles.input}>
                                <span className={styles.value} style={{ margin: "auto" }}>
                                    {resultValues["IsRailingRequired"] === "True"
                                        ? document.data.calculation_panels_required
                                        : document.data.calculation_panels_not_required}
                                </span>
                            </p>
                            <p className={styles.norm}>{document.data.calculation_panels_according_to_norm_railings}</p>
                        </div>
                    </BoxBody>
                </Box>
            </div>

            {inputValues["IsGlass"] && (
                <Box useAccent>
                    <BoxTitle>{document.data.calculation_panels_general_remark_glass}</BoxTitle>
                    <BoxBody>
                        <p>{document.data.calculation_panels_general_remark_glass_description}</p>
                    </BoxBody>
                </Box>
            )}

            <div className={styles.container}>
                {(resultValues["ExteriorFractureRequirementStandardType"] !== null || resultValues["ExteriorFractureRequirementType"] !== null) && (
                    <Box useAccent>
                        <BoxTitle>{document.data.calculation_panels_remark_insulation_title}</BoxTitle>
                        <BoxBody>
                            <p>{document.data.calculation_panels_remark_insulation_description}</p>
                        </BoxBody>
                    </Box>
                )}

                {resultValues["InteriorFractureRequirementExceptionalType"] === "A*" && (
                    <Box useAccent>
                        <BoxTitle>{document.data.calculation_panels_remark_break_type_a_title}</BoxTitle>
                        <BoxBody className={styles.list}>
                            <PrismicRichText field={document.data.calculation_panels_remark_break_type_a_description} />
                        </BoxBody>
                    </Box>
                )}
            </div>

            <Box>
                <BoxTitle style={{ display: "flex" }}>
                    {document.data.calculation_panels_base_on_properties}
                    <p style={{ marginLeft: "auto", display: "flex", cursor: "pointer", fontSize: "14px" }} onClick={() => setShowParams(!showParams)}>
                        {showParams ? (
                            <>
                                <EyeOff size="16" style={{ margin: "1px 10px 0 0" }} />
                                {document.data.button_calculation_panels_hide_properties}
                            </>
                        ) : (
                            <>
                                <Eye size="16" style={{ margin: "1px 10px 0 0" }} />
                                {document.data.button_calculation_panels_show_properties}
                            </>
                        )}
                    </p>
                </BoxTitle>
                <BoxBody className={styles.params} style={{ height: showParams ? "fit-content" : "0px" }}>
                    <div style={{ width: "50%" }}>
                        <h3 style={{ marginBottom: "10px" }}>1. {document.data.calculation_panels_stepper_dimensions}</h3>
                        <p>
                            <strong>{document.data.calculation_panels_glass_panel}</strong>
                            <span>{inputValues["IsGlass"] ? document.data.calculation_common_yes : document.data.calculation_common_no}</span>
                        </p>
                        <p>
                            <strong>{document.data.calculation_panels_protection_height}</strong>
                            <span>{inputValues["ProtectionHeight"] / 10} cm</span>
                        </p>
                        <p>
                            <strong>{document.data.calculation_panels_width}</strong>
                            <span>{inputValues["Width"] / 10} cm</span>
                        </p>
                        <p>
                            <strong>{document.data.calculation_panels_height}</strong>
                            <span>{inputValues["Height"] / 10} cm</span>
                        </p>
                        <p>
                            <strong>{document.data.calculation_panels_surface}</strong>
                            <span>{inputValues["Surface"] / 1000000} m²</span>
                        </p>
                        <p>
                            <strong
                                dangerouslySetInnerHTML={{
                                    __html: asText(document.data.calculation_panels_interior_height_hi),
                                }}
                            ></strong>
                            <span>{inputValues["HeightAboveInteriorFloor"] / 10} cm</span>
                        </p>
                        <p>
                            <strong
                                dangerouslySetInnerHTML={{
                                    __html: asText(document.data.calculation_panels_exterior_height_he),
                                }}
                            ></strong>
                            <span>{inputValues["HeightAboveExteriorGround"] / 10} cm</span>
                        </p>
                        {inputValues["HeightAboveExteriorGround"] < inputValues["ProtectionHeight"] && (
                            <p>
                                <strong
                                    dangerouslySetInnerHTML={{
                                        __html: asText(document.data.calculation_panels_interior_drop_height),
                                    }}
                                ></strong>
                                <span>{renderDropHeight(inputValues["InteriorFallHeight"])}</span>
                            </p>
                        )}
                        {inputValues["HeightAboveInteriorFloor"] < inputValues["ProtectionHeight"] && (
                            <p>
                                <strong
                                    dangerouslySetInnerHTML={{
                                        __html: asText(document.data.calculation_panels_exterior_drop_height),
                                    }}
                                ></strong>
                                <span>{renderDropHeight(inputValues["ExteriorFallHeight"])}</span>
                            </p>
                        )}
                        <p>
                            <strong>{document.data.calculation_panels_fall_through_title}</strong>
                            <span>
                                {inputValues["CouldFallThroughWhenOpened"] ? document.data.calculation_common_yes : document.data.calculation_common_no}
                            </span>
                        </p>
                    </div>

                    <div style={{ width: "50%" }}>
                        <h3 style={{ marginBottom: "10px" }}>2. {document.data.calculation_panels_stepper_use_context}</h3>
                        <p>
                            <strong>{document.data.calculation_panels_category_of_use}</strong>
                            <span>{inputValues["InteriorUseClass"]}</span>
                        </p>
                        <p>
                            <strong>{document.data.calculation_panels_exterior_publicly_accessible}</strong>
                            <span>
                                {inputValues["IsExteriorPubliclyAccessible"] ? document.data.calculation_common_yes : document.data.calculation_common_no}
                            </span>
                        </p>
                        {inputValues["ProtectionHeight"] < inputValues["HeightAboveInteriorFloor"] && (
                            <p>
                                <strong>{document.data.calculation_panels_other_shocks_interior}</strong>
                                <span>
                                    {inputValues["OtherInteriorShocksPossible"] ? document.data.calculation_common_yes : document.data.calculation_common_no}
                                </span>
                            </p>
                        )}
                        {inputValues["ProtectionHeight"] < inputValues["HeightAboveExteriorGround"] && (
                            <p>
                                <strong>{document.data.calculation_panels_other_shocks_exterior}</strong>
                                <span>
                                    {inputValues["OtherExteriorShocksPossible"] ? document.data.calculation_common_yes : document.data.calculation_common_no}
                                </span>
                            </p>
                        )}

                        {(inputValues["HeightAboveExteriorGround"] < inputValues["ProtectionHeight"] ||
                            inputValues["HeightAboveInteriorFloor"] < inputValues["ProtectionHeight"]) && (
                            <>
                                <p>
                                    <strong>{document.data.calculation_panels_railing_interior}</strong>
                                    <span>
                                        {inputValues["IsInteriorRailingPresent"] ? document.data.calculation_common_yes : document.data.calculation_common_no}
                                    </span>
                                </p>
                                <p>
                                    <strong>{document.data.calculation_panels_railing_exterior}</strong>
                                    <span>
                                        {inputValues["IsExteriorRailingPresent"] ? document.data.calculation_common_yes : document.data.calculation_common_no}
                                    </span>
                                </p>
                            </>
                        )}
                    </div>
                </BoxBody>
            </Box>
        </>
    );
};

export default Result;
