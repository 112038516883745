import { generatePath, Link, useNavigate, useParams } from "react-router-dom";
import { Plus } from "react-feather";
import { PrismicRichText } from "@prismicio/react";
import { Button, Content, DropdownButton, DropdownItem, Panel, PanelBody, PanelFooter, useLanguage, usePrismic } from "@buildwise/ui";
import { config } from "../../_configuration/configuration";

import styles from "./Demo.module.css";

const Demo = () => {
    const navigate = useNavigate();
    const { language } = useLanguage();
    const params = useParams();
    const [document, { state: prismicState }] = usePrismic(config.prismic.documentType);

    if (prismicState !== "loaded") return null;

    return (
        <Content className={styles.container}>
            <Panel className={styles.panel}>
                <PanelBody>
                    <PrismicRichText field={document.data.windows_calculation_information} />
                </PanelBody>
                <PanelFooter className={styles.dropdown}>
                    <Button id="demo-new-windows-calculation-button" startIcon={<Plus />} onClick={() => navigate(generatePath(config.routes.newWindowsCalculation[language], { ...params }))} block>
                        {document.data.navigation_new_windows_calculation}
                    </Button>
                </PanelFooter>
            </Panel>
            <Panel className={styles.panel}>
                <PanelBody>
                    <PrismicRichText field={document.data.panels_calculation_information} />
                </PanelBody>
                <PanelFooter className={styles.dropdown}>
                    <DropdownButton startIcon={<Plus />} label={document.data.navigation_new_panels_calculation} block>
                        <DropdownItem>
                            <Link id="demo-new-panels-calculation-link" to={generatePath(config.routes.newPanelsCalculation[language], { ...params })}>
                                {document.data.navigation_manual_determination}
                            </Link>
                        </DropdownItem>
                        <DropdownItem>
                            <Link id="demo-new-panels-import-calculation-link"  to={generatePath(config.routes.newPanelsImportCalculation[language], { ...params })}>
                                {document.data.navigation_list_determination}
                            </Link>
                        </DropdownItem>
                    </DropdownButton>
                </PanelFooter>
            </Panel>
            <Panel className={styles.panel}>
                <PanelBody>
                    <PrismicRichText field={document.data.glass_thickness_calculation_information} />
                </PanelBody>
                <PanelFooter className={styles.dropdown}>
                    <DropdownButton startIcon={<Plus />} label={document.data.navigation_new_glass_thickness_calculation} block>
                        <DropdownItem>
                            <Link id="demo-new-glass-calculation-link" to={generatePath(config.routes.newGlassCalculation[language], { ...params })}>
                                {document.data.navigation_manual_determination}
                            </Link>
                        </DropdownItem>
                        <DropdownItem>
                            <Link id="demo-new-glass-import-calculation-link"  to={generatePath(config.routes.newGlassImportCalculation[language], { ...params })}>
                                {document.data.navigation_list_determination}
                            </Link>
                        </DropdownItem>
                    </DropdownButton>
                </PanelFooter>
            </Panel>
        </Content>
    );
};

export default Demo;
