import { useContext, useState, useEffect, useRef, useLayoutEffect } from "react";
import { asText } from "@prismicio/helpers";
import {
    Button,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    ModalTitle,
    Numeric,
    Panel,
    PanelBody,
    PanelFooter,
    PanelHeader,
    QuestionBox,
    QuestionBoxBody,
    QuestionBoxImage,
    QuestionBoxInfo,
    QuestionBoxTitle,
    Select,
    SelectGroup,
    SelectGroupItem,
    SelectGroupItemDescription,
    SelectGroupItemImage,
    SelectGroupItemTitle,
    Text,
    usePrismic,
} from "@buildwise/ui";
import Result from "./Result";
import { AppContext } from "../../context/AppContextProvider";
import { getGlassTypes, performCalculation } from "../../adapters/GlassThicknessCalculatorAdapter";

import { config } from "../../_configuration/configuration";
import styles from "./GlassThickness.module.css";

const Calculator = (props) => {
    const { state } = useContext(AppContext);
    const [warning, setWarning] = useState({ visible: false });

    const [showCalc, setShowCalc] = useState(true);

    const [postalCodes, setPostalCodes] = useState([]);
    const [showPostalCodes, setShowPostalCodes] = useState(false);
    const [filterPostalCodes, setFilterPostalCodes] = useState([]);

    const [postalCode, setPostalCode] = useState("");
    const [wind, setWind] = useState("0");
    const [terrain, setTerrain] = useState("");
    const [buildingHeight, setBuildingHeight] = useState("");
    const [buildingWidth, setBuildingWidth] = useState("");
    const [buildingDepth, setBuildingDepth] = useState("");
    const [glassTypes, setGlassTypes] = useState([]);
    const [glassType, setGlassType] = useState();
    const [glassHeight, setGlassHeight] = useState("");
    const [glassWidth, setGlassWidth] = useState("");

    const [result, setResult] = useState({});
    const headerRef = useRef();

    const [document] = usePrismic(config.prismic.documentType);

    useLayoutEffect(() => {
        headerRef.current = window.document.getElementById(`calc-${props.i}`);
        getGlassTypes().then(setGlassTypes);
    }, []);

    useEffect(() => {
        setPostalCodes(state.calculatorData[1]);
    }, [state.calculatorData[1]]);

    useEffect(() => {
        if (!glassTypes || glassTypes.length === 0) return;

        if (Object.keys(props.result).length > 1) {
            setShowCalc(false);
            setResult(props.result);

            if (!props.result.elt5d) return;

            cancelEdit();
        } else {
            // setShowCalc(true);
            setWind("0");
            setPostalCode("");
            setTerrain("");
            setBuildingHeight("");
            setBuildingWidth("");
            setBuildingDepth("");
            setGlassType();
            setGlassHeight("");
            setGlassWidth("");
        }
    }, [props.result, glassTypes]);

    const validateInput = () => {
        const errors = [];

        if (wind === "0" || postalCode === "") errors.push(document.data.calculation_common_invalid_postal_code);
        if (terrain === "") errors.push(document.data.calculation_common_invalid_roughness_category);
        if (buildingHeight === "" || Number(buildingHeight) < 0 || Number(buildingHeight) > 200)
            errors.push(document.data.calculation_common_invalid_building_height);
        if (buildingWidth === "" || Number(buildingWidth) < 0 || Number(buildingWidth) > 200)
            errors.push(document.data.calculation_common_invalid_building_width);
        if (buildingDepth === "" || Number(buildingDepth) < 0 || Number(buildingDepth) > 200)
            errors.push(document.data.calculation_common_invalid_building_depth);
        if (!glassType || glassType.length === 0) errors.push(document.data.calculation_common_invalid_glass_type);
        if (glassHeight === "" || Number(glassHeight) < 0 || Number(glassHeight) > 200) errors.push(document.data.calculation_common_invalid_glass_height);
        if (glassWidth === "" || Number(glassWidth) < 0 || Number(glassWidth) > 200) errors.push(document.data.calculation_common_invalid_glass_width);

        return {
            valid: errors.length === 0,
            errors,
        };
    };

    const calculate = () => {
        const validation = validateInput();
        if (!validation.valid) {
            setWarning({
                visible: true,
                prompt: false,
                title: document.data.calculation_common_error_title_invalid_properties,
                text: (
                    <>
                        <p>{document.data.calculation_common_error_massage_invalid_properties}</p>
                        <ul>
                            {validation.errors.map((error, index) => (
                                <li key={index}>{error}</li>
                            ))}
                        </ul>
                    </>
                ),
                callback: (bool) => setWarning({ visible: false }),
            });

            return;
        }

        const body = [
            {
                postalCode: postalCode,
                referenceSpeed: Number(wind),
                roughnessClass: Number(terrain),
                buildingHeight: parseFloat(buildingHeight),
                facadeWidth: parseFloat(buildingWidth),
                facadeWidthPerpendicular: parseFloat(buildingDepth),
                glassType: glassType[0].value,
                windowHeight: parseFloat(glassHeight),
                windowWidth: parseFloat(glassWidth),
            },
        ];

        performCalculation(body).then(handleCalculationResult);
    };

    const handleCalculationResult = (calculation) => {
        if (calculation.calculated.length > 0) {
            const storedRes = { ...result };
            const newResult = calculation.calculated[0];
            newResult.tempId = props.result.tempId;

            if (storedRes.id > 0) newResult.id = storedRes.id;
            else newResult.id = -1;

            setResult(newResult);

            props.callBack(newResult);
            headerRef.current.scrollIntoView();
            setShowCalc(false);
        }

        if (calculation.failed.length > 0) {
            setWarning({
                visible: true,
                prompt: false,
                title: document.data.calculation_common_error_title_invalid_values,
                text: (
                    <>
                        <p>{document.data.calculation_common_error_message_invalid_values}</p>
                    </>
                ),
                callback: (bool) => setWarning({ visible: false }),
            });
        }
    };

    const cancelEdit = () => {
        setShowCalc(false);

        const glassType = glassTypes.find((x) => x.code === props.result.glassType);

        setWind(props.result.referenceSpeed);
        setPostalCode(props.result.postalCode);
        setTerrain(props.result.roughnessClass.toString());
        setBuildingHeight(props.result.buildingHeight);
        setBuildingWidth(props.result.facadeWidth);
        setBuildingDepth(props.result.facadeWidthPerpendicular);
        setGlassType([{ value: glassType.code, label: glassType.name }]);
        setGlassHeight(props.result.windowHeight);
        setGlassWidth(props.result.windowWidth);
    };

    const onSelectedZip = (zip) => {
        setWind(zip.windSpeed);
        setTerrain("");
        setPostalCode(zip.code + " " + zip.city);
        setShowPostalCodes(false);
    };

    const onFilterZips = (value) => {
        setPostalCode(value);
        if (value.length > 1) {
            setShowPostalCodes(true);
            const filteredZips = postalCodes.filter((zip) => zip.code.substring(0, value.length) === value);
            setFilterPostalCodes(filteredZips);
        } else {
            setShowPostalCodes(false);
            setWind("0");
        }
    };

    const terrainCat = [
        {
            id: "0",
            image: "/img/windows/category0.png",
            title: asText(document.data.terrain_roughness_category_0_title),
            desc: asText(document.data.terrain_roughness_category_0_description),
        },
        {
            id: "1",
            image: "/img/windows/category1.png",
            title: asText(document.data.terrain_roughness_category_1_title),
            desc: asText(document.data.terrain_roughness_category_1_description),
        },
        {
            id: "2",
            image: "/img/windows/category2.png",
            title: asText(document.data.terrain_roughness_category_2_title),
            desc: asText(document.data.terrain_roughness_category_2_description),
        },
        {
            id: "3",
            image: "/img/windows/category3.png",
            title: asText(document.data.terrain_roughness_category_3_title),
            desc: asText(document.data.terrain_roughness_category_3_description),
        },
        {
            id: "4",
            image: "/img/windows/category4.png",
            title: asText(document.data.terrain_roughness_category_4_title),
            desc: asText(document.data.terrain_roughness_category_4_description),
        },
    ];

    return (
        <>
            <Panel id="calculation">
                <PanelHeader>
                    <h2 style={{ marginBottom: "32px" }} id={`calc-${props.i}`}>
                        {showCalc ? document.data.calculation_glass_thickness_determination : document.data.calculation_glass_thickness_result} {props.i + 1}
                    </h2>
                </PanelHeader>

                {!showCalc && <Result result={result} glassTypes={glassTypes} id={result.tempId} onEdit={() => setShowCalc(true)} onRemove={props.onRemove} />}

                {showCalc && (
                    <>
                        <PanelBody>
                            <QuestionBox>
                                <QuestionBoxTitle>{document.data.calculation_glass_thickness_postal_code_question}</QuestionBoxTitle>
                                <QuestionBoxBody style={{ position: "relative" }}>
                                    <Text
                                        type="text"
                                        value={postalCode}
                                        placeholder={document.data.calculation_glass_thickness_postal_code_placeholder}
                                        onChange={(e) => onFilterZips(e.target.value)}
                                        required
                                    />
                                    {showPostalCodes && filterPostalCodes.length > 0 && (
                                        <div className={styles.zipcodes}>
                                            {filterPostalCodes.map((zip, i) => (
                                                <p key={i} onClick={() => onSelectedZip(zip)}>
                                                    {zip.code} - {zip.city}
                                                </p>
                                            ))}
                                        </div>
                                    )}
                                </QuestionBoxBody>
                                <QuestionBoxInfo>
                                    <p
                                        dangerouslySetInnerHTML={{
                                            __html: asText(document.data.calculation_windows_postal_code_information).replace("{{windspeed}}", wind),
                                        }}
                                    />
                                </QuestionBoxInfo>
                            </QuestionBox>

                            <QuestionBox className={styles.fullWidthQuestion}>
                                <QuestionBoxTitle>{document.data.calculation_glass_thickness_roughness_category_question}</QuestionBoxTitle>
                                <QuestionBoxBody>
                                    <SelectGroup selected={terrain} onSelectionChanged={(id) => setTerrain(id)}>
                                        {terrainCat.map((t, index) => (
                                            <SelectGroupItem identifier={t.id} key={index} className={t.id === "0" && wind !== 26 ? "disabled" : ""}>
                                                <SelectGroupItemTitle>{t.title}</SelectGroupItemTitle>
                                                <SelectGroupItemDescription component={"div"}>{t.desc}</SelectGroupItemDescription>
                                                <SelectGroupItemImage src={t.image} alt={t.title} />
                                            </SelectGroupItem>
                                        ))}
                                    </SelectGroup>
                                </QuestionBoxBody>
                            </QuestionBox>

                            <QuestionBox className={styles.fullWidthQuestion}>
                                <QuestionBoxTitle style={{ marginBottom: 8 }}>
                                    <span>{document.data.calculation_glass_thickness_building_data_question}</span>
                                </QuestionBoxTitle>
                                <QuestionBoxBody className={styles.maxParagraphWidth}>
                                    <div>{document.data.calculation_glass_thickness_building_data_information}</div>
                                    <div className={styles.dimensions}>
                                        <div>
                                            <strong>{document.data.calculation_glass_thickness_building_height_h_question}</strong>
                                            <Numeric
                                                value={buildingHeight}
                                                onChange={(e) => setBuildingHeight(e.target.value)}
                                                legend={"m"}
                                                required
                                                min={0}
                                                max={200}
                                                step={"any"}
                                            />
                                        </div>
                                        <div>
                                            <strong>{document.data.calculation_glass_thickness_building_width_b_question}</strong>
                                            <Numeric
                                                value={buildingWidth}
                                                onChange={(e) => setBuildingWidth(e.target.value)}
                                                legend={"m"}
                                                required
                                                min={0}
                                                max={200}
                                                step={"any"}
                                            />
                                        </div>
                                        <div>
                                            <strong>{document.data.calculation_glass_thickness_building_depth_d_question}</strong>
                                            <Numeric
                                                value={buildingDepth}
                                                onChange={(e) => setBuildingDepth(e.target.value)}
                                                legend={"m"}
                                                required
                                                min={0}
                                                max={200}
                                                step={"any"}
                                            />
                                        </div>
                                    </div>
                                </QuestionBoxBody>
                                <QuestionBoxInfo>
                                    <QuestionBoxImage
                                        src={document.data.calculation_glass_thickness_building_data_image.url}
                                        style={{ backgroundPosition: "left", width: 800, marginTop: 0, marginLeft: 0 }}
                                    />
                                </QuestionBoxInfo>
                            </QuestionBox>

                            <QuestionBox className={styles.fullWidthQuestion}>
                                <QuestionBoxTitle style={{ marginBottom: 8 }}>{document.data.calculation_glass_thickness_glass_type_question}</QuestionBoxTitle>
                                <QuestionBoxBody style={{ position: "relative" }}>
                                    <Select
                                        options={glassTypes.filter((x) => x.active).map((x) => ({ value: x.code, label: document.data[`glass_type_${x.code}`] }))}
                                        selection={glassType}
                                        onChangeSelection={(item) => setGlassType(item)}
                                        required
                                        placeholder={document.data.calculation_glass_thickness_glass_type_placeholder}
                                    />
                                </QuestionBoxBody>
                                <QuestionBoxInfo>
                                    <p>{document.data.calculation_glass_thickness_glass_type_information}</p>
                                </QuestionBoxInfo>
                            </QuestionBox>

                            <QuestionBox className={styles.fullWidthQuestion}>
                                <QuestionBoxTitle style={{ marginBottom: 8 }}>
                                    <span>{document.data.calculation_glass_thickness_glass_dimensions_question}</span>
                                </QuestionBoxTitle>
                                <QuestionBoxBody className={styles.maxParagraphWidth}>
                                    <p>{document.data.calculation_glass_thickness_glass_dimensions_information}</p>
                                    <div className={styles.dimensions}>
                                        <div>
                                            <strong>{document.data.calculation_glass_thickness_glass_height_question}</strong>
                                            <Numeric
                                                value={glassHeight}
                                                onChange={(e) => setGlassHeight(e.target.value)}
                                                legend={"m"}
                                                required
                                                min={0}
                                                max={200}
                                                step={"any"}
                                            />
                                        </div>
                                        <div>
                                            <strong>{document.data.calculation_glass_thickness_glass_width_question}</strong>
                                            <Numeric
                                                value={glassWidth}
                                                onChange={(e) => setGlassWidth(e.target.value)}
                                                legend={"m"}
                                                required
                                                min={0}
                                                max={200}
                                                step={"any"}
                                            />
                                        </div>
                                    </div>
                                </QuestionBoxBody>
                            </QuestionBox>
                        </PanelBody>

                        <PanelFooter>
                            <div style={{ display: "flex", marginLeft: "auto" }}>
                                {props.result.elt5d !== undefined && props.result.elt5d !== null && (
                                    <Button id="glass-thickness-cancel-button" variant="tertiary" style={{ marginRight: "10px" }} onClick={() => cancelEdit()}>
                                        {document.data.button_common_cancel}
                                    </Button>
                                )}
                                <Button id="glass-thickness-calculate-button" variant="secondary" onClick={() => calculate()}>
                                    {document.data.button_common_determine}
                                </Button>
                            </div>
                        </PanelFooter>
                    </>
                )}
            </Panel>

            <Modal open={warning.visible} backdrop onClose={warning.callback}>
                <ModalHeader closeButton>
                    <ModalTitle>{warning.title}</ModalTitle>
                </ModalHeader>
                <ModalBody>{warning.text}</ModalBody>
                <ModalFooter>
                    <Button id="glass-thickness-warning-confirm-button" onClick={warning.callback}>{document.data.button_common_ok}</Button>
                </ModalFooter>
            </Modal>
        </>
    );
};

export default Calculator;
