import { localhost } from "./localhost";
import { development } from "./development";
import { staging } from "./staging";
import { production } from "./production";

const getEnvConfig = (env) => {
    switch (env) {
        case "development":
            return development;
        case "staging":
            return staging;
        case "production":
            return production;
        case "localhost":
        default:
            return localhost;
    }
};

export const config = {
    ...getEnvConfig(process.env.REACT_APP_STAGE),
    prismic: {
        documentType: "fenestrio",
        aboutApplicationItemKey: "navigation_help_contact_about_subitem",
        aboutApplicationUrlKey: "navigation_help_contact_about_url",
    },
    routes: {
        landing: {
            "nl-be": "/:lang",
            "fr-be": "/:lang",
        },
        project: {
            "nl-be": "/:lang/projects/:projectId",
            "fr-be": "/:lang/projects/:projectId",
        },
        newWindowsCalculation: {
            "nl-be": "/:lang/vensters/nieuw",
            "fr-be": "/:lang/fenetres/nouveau",
        },
        newWindowsCalculationProject: {
            "nl-be": "/:lang/projects/:projectId/vensters/nieuw",
            "fr-be": "/:lang/projects/:projectId/fenetres/nouveau",
        },
        newPanelsCalculation: {
            "nl-be": "/:lang/panelen/nieuw",
            "fr-be": "/:lang/panneaux/nouveau",
        },
        newPanelsCalculationProject: {
            "nl-be": "/:lang/projects/:projectId/panelen/nieuw",
            "fr-be": "/:lang/projects/:projectId/panneaux/nouveau",
        },
        newPanelsImportCalculation: {
            "nl-be": "/:lang/panelen/import",
            "fr-be": "/:lang/panneaux/import",
        },
        newPanelsImportCalculationProject: {
            "nl-be": "/:lang/projects/:projectId/panelen/import",
            "fr-be": "/:lang/projects/:projectId/panneaux/import",
        },
        existingWindowsCalculation: {
            "nl-be": "/:lang/projects/:projectId/vensters/:id",
            "fr-be": "/:lang/projects/:projectId/fenetres/:id",
        },
        existingPanelsCalculation: {
            "nl-be": "/:lang/projects/:projectId/panelen/:id",
            "fr-be": "/:lang/projects/:projectId/panneaux/:id",
        },
        existingPanelsImportCalculation: {
            "nl-be": "/:lang/projects/:projectId/panelen/import/:id",
            "fr-be": "/:lang/projects/:projectId/panneaux/import/:id",
        },
        newGlassCalculation: {
            "nl-be": "/:lang/glasdikte/nieuw",
            "fr-be": "/:lang/glasdikte/nouveau",
        },
        newGlassCalculationProject: {
            "nl-be": "/:lang/projects/:projectId/glasdikte/nieuw",
            "fr-be": "/:lang/projects/:projectId/glasdikte/nouveau",
        },
        newGlassImportCalculation: {
            "nl-be": "/:lang/glasdikte/import",
            "fr-be": "/:lang/glasdikte/import",
        },
        newGlassImportCalculationProject: {
            "nl-be": "/:lang/projects/:projectId/glasdikte/import",
            "fr-be": "/:lang/projects/:projectId/glasdikte/import",
        },
        existingGlassCalculationProject: {
            "nl-be": "/:lang/projects/:projectId/glasdikte/:id",
            "fr-be": "/:lang/projects/:projectId/glasdikte/:id",
        },
        existingGlassImportCalculationProject: {
            "nl-be": "/:lang/projects/:projectId/glasdikte/import/:id",
            "fr-be": "/:lang/projects/:projectId/glasdikte/import/:id",
        },
    },
};
