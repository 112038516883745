export const production = {
    api: "https://wtcb-tools-production-fenestrio.azurewebsites.net/",
    sentry: {
        dsn: "https://0911d3f7e51846c4996b8088168525bc@o399431.ingest.sentry.io/5940610",
        env: "production",
        sampleRate: 0.1,
    },
    gtm: {
        gtmId: "GTM-NNCPLBX",
    },
    auth: {
        clientId: "68db96b4-e340-4a6b-adac-ea393079800e",
        authority: "https://login.bbri.be/wtcbidence.onmicrosoft.com/b2c_1a_signup_signin/",
        knownAuthorities: ["https://login.bbri.be/"],
        redirectUri: `${window.location.protocol}//${window.location.hostname}`,
        postLogoutRedirectUri: `${window.location.protocol}//${window.location.hostname}`,
        cacheLocation: "localStorage",
        scopes: ["https://wtcbidence.onmicrosoft.com/BIMio/BIMio.Access", "openid", "profile", "offline_access"],
    },
    feedback: "https://wtcb-tools-production-ifcviewer.azurewebsites.net/api/v1/Feedback",
};
