import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle, QuestionBox, QuestionBoxBody, Text, usePrismic } from "@buildwise/ui";
import { AppContext } from "../../context/AppContextProvider";
import { getProjects } from "../../adapters/ProjectsAdapter";
import { getCalculation, getCalculations, updateCalculation } from "../../adapters/CalculationsAdapter";
import {
    getCalculation as getGlassThicknessCalculation,
    getCalculations as getGlassThicknessCalculations,
    updateCalculationInfo as updateGlassThicknessCalculation,
} from "../../adapters/GlassThicknessCalculatorAdapter";

import { config } from "../../_configuration/configuration";
import styles from "./EditCalculationModal.module.css";

const EditCalculationModal = ({ isOpen, onClose, calculation }) => {
    const { state, dispatch } = useContext(AppContext);

    const [name, setName] = useState("");
    const [description, setDescription] = useState("");

    const [canSave, setCanSave] = useState(true);
    const [isSaving, setIsSaving] = useState(false);

    const params = useParams();

    const [document] = usePrismic(config.prismic.documentType);

    useEffect(() => {
        if (isOpen) {
            if (state.projects?.length > 0) return;
            getProjects().then(storeProjects);
        }
    }, [isOpen]);

    useEffect(() => {
        if (isOpen) {
            if (state.calculations[params.projectId]?.length > 0) return;
            getCalculations(params.projectId).then(storeCalculations);
            getGlassThicknessCalculations(params.projectId).then(storeGlassThicknessCalculations);
        }
    }, [isOpen]);

    useEffect(() => {
        if (isOpen) {
            setName(calculation.name ?? calculation.title);
            setDescription(calculation.description);
            setCanSave(false);
            setIsSaving(false);
        }
    }, [isOpen]);

    useEffect(() => {
        if (isSaving) {
            setCanSave(false);
            return;
        }

        if (!name || name.trim().length === 0 || !validateCalculationName(name)) {
            setCanSave(false);
            return;
        }

        setCanSave(true);
    }, [name, isSaving]);

    const validateCalculationName = (name) => {
        if (!state.calculations[params.projectId] || !state.glassThicknessCalculations[params.projectId]) return false;

        const genericSavedData = state.calculations[params.projectId];
        const glassThicknessSavedData = state.glassThicknessCalculations[params.projectId];

        const merged = [...genericSavedData.map((x) => ({ id: x.id, name: x.name })), ...glassThicknessSavedData.map((x) => ({ id: x.id, name: x.title }))];

        return !merged.find((x) => x.name?.toLowerCase() === name?.trim()?.toLowerCase() && x.id !== Number(params.calcId) && x.id !== calculation?.id);
    };

    const onSave = () => {
        setIsSaving(true);

        if (calculation.calculationAppId === "GlassThickness") {
            updateGlassThicknessCalculation(params.projectId, {
                ...calculation,
                title: name,
                description: description,
            }).then(updateCalculations);

            return;
        }

        updateCalculation(params.projectId, {
            ...calculation,
            name: name,
            description: description,
        }).then(updateCalculations);
    };

    const storeProjects = (projects) => {
        if (projects) dispatch({ type: "SET_PROJECTS", payload: projects });
    };

    const storeCalculations = (calculations) => {
        if (calculations)
            dispatch({
                type: "SET_CALCULATIONS",
                payload: { projectId: params.projectId, calculations: calculations },
            });
    };

    const storeGlassThicknessCalculations = (calculations) => {
        if (calculations)
            dispatch({
                type: "SET_GLASSTHICKNESS_CALCULATIONS",
                payload: { projectId: params.projectId, calculations: calculations },
            });
    };

    const updateCalculations = (succes) => {
        if (succes && calculation.calculationAppId < 3) getCalculation(params.projectId, calculation.id).then(updateStoredCalculation);
        else if (succes && calculation.calculationAppId === "GlassThickness")
            getGlassThicknessCalculation(params.projectId, calculation.id).then(updateStoredGlassThicknessCalculation);

        setIsSaving(false);
        onClose && onClose();
    };

    const updateStoredCalculation = (calculation) => {
        dispatch({
            type: "UPDATE_CALCULATION",
            payload: {
                projectId: params.projectId,
                calculation: { ...calculation, name: name, description: description },
            },
        });
    };

    const updateStoredGlassThicknessCalculation = (calculation) => {
        dispatch({
            type: "UPDATE_GLASSTHICKNESS_CALCULATION",
            payload: {
                projectId: params.projectId,
                calculation: { ...calculation, name: name, description: description },
            },
        });
    };

    return (
        <Modal open={isOpen} backdrop onClose={onClose} width="small">
            <ModalHeader closeButton>
                <ModalTitle>{document.data.button_common_edit_calculation_details}</ModalTitle>
            </ModalHeader>
            <ModalBody className={styles.body}>
                <QuestionBox className={styles.input} style={{ display: "block" }}>
                    <QuestionBoxBody>
                        <strong className={styles.label}>{document.data.calculation_common_edit_name}</strong>
                        <Text
                            type="text"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            placeholder={document.data.calculation_common_edit_name_placeholder}
                            required
                            onValidate={validateCalculationName}
                            validationError={document.data.calculation_common_edit_name_already_exists}
                        />
                        <strong className={styles.label} style={{ marginTop: "16px" }}>
                            {document.data.calculation_common_edit_description}
                        </strong>
                        <Text
                            type="textarea"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            placeholder={document.data.calculation_common_edit_description_placeholder}
                        />
                    </QuestionBoxBody>
                </QuestionBox>
            </ModalBody>
            <ModalFooter>
                <Button id="edit-calculation-details-modal-cancel-button" variant="tertiary" onClick={onClose}>
                    {document.data.button_common_edit_cancel}
                </Button>
                <Button id="edit-calculation-details-modal-save-button" onClick={onSave} disabled={!canSave}>
                    {isSaving === true ? document.data.button_common_busy_saving : document.data.button_common_save}
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default EditCalculationModal;
