import { useContext, useEffect, useState } from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle, QuestionBox, QuestionBoxBody, Text, usePrismic } from "@buildwise/ui";
import { AppContext } from "../../context/AppContextProvider";
import { getProjects, updateProject } from "../../adapters/ProjectsAdapter";

import { config } from "../../_configuration/configuration";
import styles from "./EditProjectModal.module.css";

const EditProjectModal = ({ isOpen, onClose, project }) => {
    const { state, dispatch } = useContext(AppContext);

    const [name, setName] = useState("");
    const [description, setDescription] = useState("");

    const [canSave, setCanSave] = useState(false);
    const [isSaving, setIsSaving] = useState(false);

    const [document] = usePrismic(config.prismic.documentType);

    useEffect(() => {
        if (isOpen) {
            if (state.projects?.length > 0) return;
            getProjects().then(storeProjects);
        }
    }, [isOpen]);

    useEffect(() => {
        if (isOpen) {
            setCanSave(true);
            setIsSaving(false);
        }
    }, [isOpen]);

    useEffect(() => {
        if (project) {
            setName(project.name);
            setDescription(project.description);
        }
    }, [project]);

    useEffect(() => {
        if (isSaving) {
            setCanSave(false);
            return;
        }

        if (!name || name.trim().length === 0 || !validateProjectName(name)) {
            setCanSave(false);
            return;
        }

        setCanSave(true);
    }, [name, isSaving]);

    const validateProjectName = (name) => {
        return !state.projects.find((x) => x.name?.toLowerCase() === name?.trim()?.toLowerCase() && x.id !== project.id);
    };

    const onSave = () => {
        setIsSaving(true);
        updateProject({ id: project.id, name: name, description: description }).then(updateProjects);
    };

    const storeProjects = (projects) => {
        if (projects) dispatch({ type: "SET_PROJECTS", payload: projects });
    };

    const updateProjects = (success) => {
        if (!success) return;

        dispatch({ type: "UPDATE_PROJECT", payload: { id: project.id, name: name, description: description } });
        onClose && onClose();
    };

    return (
        <Modal open={isOpen} backdrop onClose={onClose} width="small">
            <ModalHeader closeButton>
                <ModalTitle>{document.data.button_project_overview_edit_details}</ModalTitle>
            </ModalHeader>
            <ModalBody className={styles.body}>
                <QuestionBox className={styles.input}>
                    <QuestionBoxBody>
                        <strong className={styles.label}>{document.data.project_overview_edit_name}</strong>
                        <Text
                            type="text"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            placeholder={document.data.project_overview_edit_name_placeholder}
                            required
                            onValidate={validateProjectName}
                            validationError={document.data.project_overview_edit_name_already_exists}
                        />
                        <strong className={styles.label} style={{ marginTop: "16px" }}>
                            {document.data.project_overview_edit_description}
                        </strong>
                        <Text
                            type="textarea"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            placeholder={document.data.project_overview_edit_description_placeholder}
                        />
                    </QuestionBoxBody>
                </QuestionBox>
            </ModalBody>
            <ModalFooter>
                <Button id="edit-project-modal-cancel-button" variant="tertiary" onClick={onClose}>
                    {document.data.button_project_overview_edit_cancel}
                </Button>
                <Button id="edit-project-modal-save-button" onClick={onSave} disabled={!canSave}>
                    {isSaving ? document.data.button_project_overview_edit_busy_saving : document.data.button_project_overview_edit_save}
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default EditProjectModal;
