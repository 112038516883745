import { Route, Routes } from "react-router-dom";
import { Spinner, useAuthentication, usePrismic } from "@buildwise/ui";

import App from "./App";
import Demo from "./pages/demo/Demo";
import Projects from "./pages/projects/Projects";
import Project from "./pages/projects/Project";
import Windows from "./pages/windows/Windows";
import Panels from "./pages/panels/Panels";
import PanelsImport from "./pages/panels/PanelsImport";
import GlassThickness from "./pages/glass/GlassThickness";
import GlassThicknessImport from "./pages/glass/GlassThicknessImport";
import { config } from "./_configuration/configuration";

const AppRoutes = () => {
    const { isAuthenticated } = useAuthentication();
    const [, { state: prismicState }] = usePrismic(config.prismic.documentType);

    return prismicState === "loaded" ? (
        <Routes>
            <Route index element={<App />} />
            <Route path=":lang" element={<App />}>
                <Route index element={isAuthenticated ? <Projects /> : <Demo />} />
                <Route path={"projects"}>
                    <Route path={":projectId"}>
                        <Route index element={<Project />} />
                        <Route path={"vensters"}>
                            <Route path={"nieuw"} element={<Windows />} />
                            <Route path={":calcId"} element={<Windows />} />
                        </Route>
                        <Route path={"fenetres"}>
                            <Route path={"nouveau"} element={<Windows />} />
                            <Route path={":calcId"} element={<Windows />} />
                        </Route>

                        <Route path={"panelen"}>
                            <Route path={"nieuw"} element={<Panels />} />
                            <Route path={":calcId"} element={<Panels />} />
                            <Route path={"import"} element={<PanelsImport />}>
                                <Route path={"nieuw"} element={<PanelsImport />} />
                                <Route path={":calcId"} element={<PanelsImport />} />
                            </Route>
                        </Route>
                        <Route path={"panneaux"}>
                            <Route path={"nouveau"} element={<Panels />} />
                            <Route path={":calcId"} element={<Panels />} />
                            <Route path={"import"} element={<PanelsImport />}>
                                <Route path={"nouveau"} element={<PanelsImport />} />
                                <Route path={":calcId"} element={<PanelsImport />} />
                            </Route>
                        </Route>

                        <Route path={"glasdikte"}>
                            <Route path={"nieuw"} element={<GlassThickness />} />
                            <Route path={"nouveau"} element={<GlassThickness />} />
                            <Route path={":calcId"} element={<GlassThickness />} />
                            <Route path={"import"} element={<GlassThicknessImport />}>
                                <Route path={"nieuw"} element={<GlassThicknessImport />} />
                                <Route path={"nouveau"} element={<GlassThicknessImport />} />
                                <Route path={":calcId"} element={<GlassThicknessImport />} />
                            </Route>
                        </Route>
                    </Route>
                </Route>

                <Route path={"vensters"}>
                    <Route path={"nieuw"} element={<Windows />} />
                </Route>

                <Route path={"fenetres"}>
                    <Route path={"nouveau"} element={<Windows />} />
                </Route>

                <Route path={"panelen"}>
                    <Route path={"nieuw"} element={<Panels />} />
                    <Route path={"import"} element={<PanelsImport />} />
                </Route>

                <Route path={"panneaux"}>
                    <Route path={"nouveau"} element={<Panels />} />
                    <Route path={"import"} element={<PanelsImport />} />
                </Route>

                <Route path={"glasdikte"}>
                    <Route path={"nieuw"} element={<GlassThickness />} />
                    <Route path={"nouveau"} element={<GlassThickness />} />
                    <Route path={"import"} element={<GlassThicknessImport />} />
                </Route>
            </Route>
        </Routes>
    ) : (
        <Spinner />
    );
};

export default AppRoutes;
