import { useContext, useEffect, useRef, useState } from "react";
import { useParams, useNavigate, Link, generatePath } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import { Edit, Plus, Trash2 } from "react-feather";
import { PrismicRichText } from "@prismicio/react";
import { asText } from "@prismicio/helpers";
import {
    Breadcrumb,
    Button,
    Content,
    ContentActions,
    ContentBody,
    ContentFooter,
    ContentHeader,
    ContentInfo,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    ModalTitle,
    Panel,
    PanelBody,
    PanelHeader,
    Separator,
    Spinner,
    useAuthentication,
    useLanguage,
    usePrismic,
} from "@buildwise/ui";
import { AppContext } from "../../context/AppContextProvider";
import Calculator from "./Calculator";
import CreateCalculationModal from "../../components/CreateCalculationModal/CreateCalculationModal";
import EditCalculationModal from "../../components/EditCalculationModal/EditCalculationModal";
import { getProjects } from "../../adapters/ProjectsAdapter";
import { deleteCalculation, getCalculation, getCalculations, updateCalculation } from "../../adapters/CalculationsAdapter";
import { downloadAsPdf, getCalculatorData } from "../../adapters/CalculatorAdapter";

import { config } from "../../_configuration/configuration";
import styles from "./Windows.module.css";

const Windows = () => {
    const { state, dispatch } = useContext(AppContext);
    const { isAuthenticated, user } = useAuthentication();
    const [isLoading, setIsLoading] = useState(true);
    const [hasAccess, setHasAccess] = useState(true);
    const navigate = useNavigate();
    const params = useParams();
    const currentTempId = useRef(1);

    const [calculation, setCalculation] = useState();
    const [determinations, setDeterminations] = useState([{ tempId: 0 }]);
    const [isEdittingCalculation, setIsEdittingCalculation] = useState(false);

    const [warning, setWarning] = useState({ visible: false });
    const [isSaving, setIsSaving] = useState(false);
    const [isExporting, setIsExporting] = useState(false);
    const [canSave, setCanSave] = useState(false);

    const [isCreatingCalculation, setIsCreatingCalculation] = useState(false);
    const [previousCalcId, setPreviousCalcId] = useState(0);

    const { language } = useLanguage();
    const [document] = usePrismic(config.prismic.documentType);

    useEffect(() => {
        ReactTooltip.rebuild();
    });

    useEffect(() => {
        if (state.calculatorData[1]) return;
        getCalculatorData(1, "postalcodes", language).then(storeCalculatorData);
    }, []);

    useEffect(() => {
        if (isAuthenticated && state.projects.length === 0) {
            getProjects().then(storeProjects);
        }

        if (!isAuthenticated) setIsLoading(false);
    }, [isAuthenticated]);

    useEffect(() => {
        if (isAuthenticated) {
            if (params.calcId && params.calcId !== "nieuw" && params.calcId !== "nouveau") {
                if (!Boolean(state.calculations[params.projectId])) getCalculations(params.projectId).then(storeCalculations);
                else loadCalculation(state.calculations[params.projectId]);
                setPreviousCalcId(params.calcId);
            } else {
                if (previousCalcId > 0) {
                    setCalculation(null);
                    setDeterminations([{ tempId: 0 }]);
                }

                setIsLoading(false);
            }
        }
    }, [isAuthenticated, params.projectId, params.calcId]);

    useEffect(() => {
        if (determinations.length === 0) {
            setCanSave(false);
            return;
        }

        if (determinations.length === 1 && Object.keys(determinations[0]).length <= 1) {
            setCanSave(false);
            return;
        }

        setCanSave(!isSaving);
    }, [determinations]);

    const storeCalculatorData = (data) => {
        if (!data) return;
        dispatch({ type: "SET_CALCULATOR_DATA", payload: { id: 1, data: data } });
    };

    const storeProjects = (projects) => {
        if (projects) {
            dispatch({ type: "SET_PROJECTS", payload: projects });
            if (!params.calcId) {
                if (!projects.find((x) => x.id === Number(params.projectId)))
                    navigate(generatePath(config.routes.newWindowsCalculation[language], { ...params }));
            }
        }
    };

    const storeCalculations = (calculations) => {
        if (calculations) {
            dispatch({
                type: "SET_CALCULATIONS",
                payload: { projectId: params.projectId, calculations: calculations },
            });

            loadCalculation(calculations);
        }
    };

    const loadCalculation = (calculations) => {
        const calculation = calculations.find((x) => x.id === Number(params.calcId) && x.calculationAppId === 1);
        if (calculation) {
            setCalculation(calculation);
            setDeterminations(calculation.calculationResults);
            setIsLoading(false);
        } else {
            setIsLoading(false);
            setHasAccess(false);
        }
    };

    const onSave = () => {
        setIsSaving(true);

        if (!params.calcId || params.calcId === "nieuw" || params.calcId === "nouveau") {
            setIsCreatingCalculation(true);
            return;
        }

        updateCalculation(params.projectId, {
            ...calculation,
            calculationResults: determinations,
            calculationAppId: 1,
        }).then(updateCalculations);
    };

    const updateCalculations = (succes) => {
        if (succes) getCalculation(params.projectId, params.calcId).then(updateStoredCalculation);

        setIsSaving(false);
    };

    const updateStoredCalculation = (calculation) => {
        dispatch({
            type: "UPDATE_CALCULATION",
            payload: {
                projectId: params.projectId,
                calculation: calculation,
            },
        });
    };

    const onRemoveDetermination = (calc) => {
        let results = determinations;

        if (calc.id > 0) {
            results = determinations.filter((x) => x.id !== calc.id);
        } else if (calc.tempId >= 0) {
            results = determinations.filter((x) => x.tempId !== calc.tempId);
        }

        setDeterminations(results);
    };

    const onDelete = (confirmed = false, result = false) => {
        if (!confirmed) {
            setWarning({
                visible: true,
                title: document.data.calculation_common_remove_title,
                text: document.data.calculation_common_remove_description.replace("{{name}}", calculation.name),
                callback: (bool) => onDelete(true, bool),
            });

            return;
        }

        setWarning({ visible: false });

        if (!result) return;

        deleteCalculation(params.projectId, params.calcId).then(removeCalculation);
    };

    const removeCalculation = (success) => {
        if (!success) return;

        dispatch({
            type: "REMOVE_CALCULATION",
            payload: { projectId: Number(params.projectId), calculationId: Number(params.calcId) },
        });

        navigate(generatePath(config.routes.project[language], { ...params, id: params.projectId }));
    };

    const addDetermination = (result) => {
        if (Object.keys(result).length === 1) {
            setDeterminations([...determinations, result]);
            currentTempId.current = currentTempId.current + 1;
            scrollToNewDetermination(determinations.length);
        } else {
            const newCalcResults = [...determinations];
            const newCalcResult = newCalcResults.find((x) => x.tempId === result.tempId);

            for (let key in result) newCalcResult[key] = result[key];

            setDeterminations(newCalcResults);
        }
    };

    const scrollToNewDetermination = (id) => {
        const element = window.document.getElementById(`calc-${id}`);

        if (!element) {
            setTimeout(() => scrollToNewDetermination(id), 10);
            return;
        }

        element.scrollIntoView();
    };

    const onCancel = () => {
        if (params.projectId) navigate(generatePath(config.routes.project[language], { ...params, id: params.projectId }));
        else navigate(generatePath(config.routes.landing[language], { ...params }));
    };

    const onExport = async () => {
        setIsExporting(true);

        const data = {
            projectName: calculation?.name || document.data.project_overview_general_performance_of_windows_title,
            projectDescription: calculation?.description || asText(document.data.calculation_windows_description) || "",
            language: language,
            calculationResults: determinations.filter((x) => Object.keys(x).length > 1),
        };

        downloadAsPdf(1, data, `${calculation?.name || document.data.project_overview_general_performance_of_windows_title}.pdf`).then(() =>
            setIsExporting(false)
        );
    };

    const renderButtons = () => {
        return (
            <>
                {params.calcId > 0 ? (
                    <>
                        <Button id="windows-remove-button" variant="tertiary" className="windows-remove-group" onClick={() => onDelete()} style={{ margin: "5px" }} startIcon={<Trash2 />}>
                            {document.data.button_common_delete_calculation}
                        </Button>
                        <Button
                            id="windows-calculation-edit-button"
                            variant="secondary"
                            className="windows-calculation-edit"
                            onClick={() => setIsEdittingCalculation(true)}
                            style={{ margin: "5px" }}
                            startIcon={<Edit />}
                        >
                            {document.data.button_common_edit_calculation_details}
                        </Button>
                    </>
                ) : (
                    <Button id="windows-cancel-button" variant="tertiary" className="windows-calculation-cancel" onClick={() => onCancel()} style={{ margin: "5px" }}>
                        {document.data.button_common_cancel}
                    </Button>
                )}
                <Button
                    id="windows-add-button"
                    variant="secondary"
                    className=" windows-calculation-add"
                    onClick={() => addDetermination({ tempId: currentTempId.current })}
                    style={{ margin: "5px" }}
                    startIcon={<Plus />}
                >
                    {document.data.button_common_add_determination}
                </Button>
                <Button
                    id="windows-export-pdf-button"
                    disabled={determinations.filter((x) => Object.keys(x).length > 1).length === 0 || isExporting}
                    onClick={() => onExport()}
                    style={{ margin: "5px" }}
                    className={"windows-calculation-pdf"}
                >
                    {isExporting ? document.data.button_common_busy_exporting : document.data.button_common_export_to_pdf}
                </Button>
                <Button
                    id="windows-save-button"
                    disabled={(isAuthenticated && !canSave) || isSaving}
                    onClick={() => isAuthenticated && onSave()}
                    style={{ margin: "5px" }}
                    className={isAuthenticated ? "windows-calculation-save" : "windows-calculation-save transparent"}
                    data-for="tooltip"
                    data-tip={document.data.calculation_common_user_feature}
                    data-tip-disable={isAuthenticated}
                >
                    {isSaving
                        ? document.data.button_common_busy_saving
                        : params.calcId === null
                        ? document.data.button_common_save_as
                        : document.data.button_common_save}
                </Button>
            </>
        );
    };

    return (
        <>
            <Breadcrumb>
                {Boolean(state.projects.find((x) => x.id === Number(params.projectId))) && (
                    <Link id="windows-breadcrumb" to={generatePath(config.routes.project[language], { ...params, id: params.projectId })}>
                        {state.projects.find((x) => x.id === Number(params.projectId)).name}
                    </Link>
                )}
            </Breadcrumb>
            {!Boolean(params.calcId) && <Breadcrumb>{document.data.calculation_windows_title}</Breadcrumb>}
            {calculation && calculation.name && <Breadcrumb>{calculation.name}</Breadcrumb>}
            {isLoading ? (
                <Content>
                    <Spinner />
                </Content>
            ) : hasAccess ? (
                <>
                    <Content>
                        <ContentHeader className={styles.header}>
                            <h1>{calculation ? calculation.name : document.data.calculation_windows_title}</h1>
                            <ContentActions className={styles.actions}>{renderButtons()}</ContentActions>
                        </ContentHeader>
                        <Separator color="orange">
                            <PrismicRichText field={document.data.windows_calculation_information} />
                        </Separator>
                        <ContentInfo style={{ flexWrap: "wrap" }}>
                            <div className={styles.projectinfo}>
                                {calculation && calculation.id > 0 ? (
                                    <>
                                        <h4>{document.data.project_overview_description_title}</h4>
                                        <p>{calculation.description}</p>
                                    </>
                                ) : (
                                    <PrismicRichText field={document.data.calculation_windows_description} />
                                )}
                            </div>
                            <div>
                                {calculation && calculation.id > 0 && (
                                    <>
                                        <p>
                                            <strong>{document.data.calculation_common_creation_date}</strong>{" "}
                                            {new Date(calculation.creationDate).toLocaleDateString("nl-BE")}
                                        </p>
                                        <p>
                                            <strong>{document.data.calculation_common_modification_date}</strong>{" "}
                                            {new Date(calculation.modificationDate).toLocaleDateString("nl-BE")}
                                        </p>
                                    </>
                                )}
                            </div>
                        </ContentInfo>
                        <ContentBody>
                            {determinations.map((result, i) => (
                                <Calculator key={i} i={i} result={result} callBack={addDetermination} onRemove={() => onRemoveDetermination(result)} />
                            ))}
                        </ContentBody>
                        <ContentFooter>
                            <ContentActions className={styles.actions}>{renderButtons()}</ContentActions>
                        </ContentFooter>
                    </Content>

                    {isAuthenticated && (
                        <>
                            <CreateCalculationModal
                                isOpen={isCreatingCalculation}
                                onClose={() => {
                                    setIsCreatingCalculation(false);
                                    setIsSaving(false);
                                }}
                                calculation={{
                                    calculationAppId: 1,
                                    ownerId: user.userId,
                                    calculationResults: determinations.filter((x) => Object.keys(x).length > 1),
                                }}
                            />

                            <EditCalculationModal isOpen={isEdittingCalculation} onClose={() => setIsEdittingCalculation(false)} calculation={calculation} />
                        </>
                    )}

                    <Modal open={warning.visible} backdrop onClose={() => setWarning({ visible: false })}>
                        <ModalHeader closeButton>
                            <ModalTitle>{document.data.calculation_common_remove_title}</ModalTitle>
                        </ModalHeader>
                        <ModalBody>{warning.text}</ModalBody>
                        <ModalFooter>
                            <Button id="windows-warning-cancel-button" variant="tertiary" onClick={() => setWarning({ visible: false })}>
                                {document.data.button_common_cancel}
                            </Button>
                            <Button id="windows-warning-confirm-button" onClick={warning.callback}>{document.data.calculation_common_yes}</Button>
                        </ModalFooter>
                    </Modal>
                </>
            ) : (
                <Content>
                    <ContentBody>
                        <Panel>
                            <PanelHeader>
                                <h2>{document.data.calculation_common_calculation_not_found}</h2>
                            </PanelHeader>
                            <PanelBody>{document.data.calculation_common_calculation_not_found_details}</PanelBody>
                        </Panel>
                    </ContentBody>
                </Content>
            )}
        </>
    );
};

export default Windows;
