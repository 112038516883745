import { config } from "../_configuration/configuration";

export const getCalculations = async (projectId) => {
    const endpoint = `${config.api}api/v1/Projects/${projectId}/StoredCalculations`;
    const options = {
        method: "GET",
    };

    const response = await fetch(endpoint, options);
    if (response.ok) return await response.json();

    return undefined;
};

export const getCalculation = async (projectId, calculationId) => {
    const endpoint = `${config.api}api/v1/Projects/${projectId}/StoredCalculations/${calculationId}`;
    const options = {
        method: "GET",
    };

    const response = await fetch(endpoint, options);
    if (response.ok) return await response.json();

    return undefined;
};

export const createCalculation = async (projectId, calculation) => {
    const endpoint = `${config.api}api/v1/Projects/${projectId}/StoredCalculations`;
    const options = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(calculation),
    };

    const response = await fetch(endpoint, options);
    if (response.ok) return await response.json();

    return undefined;
};

export const addInputFile = async (projectId, calculationId, input) => {
    const endpoint = `${config.api}api/v1/Projects/${projectId}/StoredCalculations/${calculationId}/InputFile`;
    const options = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({ parameterLines: input }),
    };

    const response = await fetch(endpoint, options);

    return response.ok;
};

export const getInputFile = async (projectId, calculationId) => {
    const endpoint = `${config.api}api/v1/Projects/${projectId}/StoredCalculations/${calculationId}/InputFile`;
    const options = {
        method: "GET",
    };

    const response = await fetch(endpoint, options);
    if (response.ok) return await response.json();

    return undefined;
};

export const updateCalculation = async (projectId, calculation) => {
    const endpoint = `${config.api}api/v1/Projects/${projectId}/StoredCalculations/${calculation.id}`;
    const options = {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(calculation),
    };

    const response = await fetch(endpoint, options);

    return response.ok;
};

export const deleteCalculation = async (projectId, calculationId) => {
    const endpoint = `${config.api}api/v1/Projects/${projectId}/StoredCalculations/${calculationId}`;
    const options = {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json",
        },
    };

    const response = await fetch(endpoint, options);

    return response.ok;
};
