import { config } from "../_configuration/configuration";

export const getCalculatorDefinition = async (calculatorId) => {
    const endpoint = `${config.api}api/v1/Calculations/${calculatorId}`;
    const options = {
        method: "GET",
    };

    const response = await fetch(endpoint, options);
    if (response.ok) return await response.json();

    return undefined;
};

export const getCalculatorData = async (calculatorId, generator, language) => {
    const endpoint = `${config.api}api/v1/Calculations/${calculatorId}/Data?generator=${generator}&language=${language}`;
    const options = {
        method: "GET",
    };

    const response = await fetch(endpoint, options);
    if (response.ok) return await response.json();

    return undefined;
};

export const downloadCalculatorFile = async (calculatorId, language) => {
    const endpoint = `${config.api}api/v1/Calculations/${calculatorId}/Template?language=${language}`;
    const options = {
        method: "GET",
    };

    const response = await fetch(endpoint, options);
    if (!response.ok) return undefined;

    const blob = await response.blob();

    const url = window.URL.createObjectURL(blob);
    const tempLink = document.createElement("a");
    tempLink.href = url;
    tempLink.setAttribute("download", `Template_GPP_${language}.xlsx`);
    tempLink.click();
    window.URL.revokeObjectURL(url);
};

export const generateCalculatorFile = async (calculatorId, file) => {
    const endpoint = `${config.api}api/v1/Calculations/${calculatorId}/GenerateInputFile`;
    const form = new FormData();
    form.append("File", file);
    const options = {
        method: "POST",
        headers: {
            Accept: "application/json",
        },
        body: form,
    };

    const response = await fetch(endpoint, options);
    if (response.ok) return await response.json();

    return undefined;
};

export const downloadAsPdf = async (calculatorId, data, filename) => {
    const endpoint = `${config.api}api/v1/Calculations/${calculatorId}/Pdf`;
    const options = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
    };

    const response = await fetch(endpoint, options);
    if (!response.ok) return undefined;

    const blob = await response.blob();

    const url = window.URL.createObjectURL(blob);
    const tempLink = document.createElement("a");
    tempLink.href = url;
    tempLink.setAttribute("download", filename);
    tempLink.click();
    window.URL.revokeObjectURL(url);
};

export const calculateByInputFile = async (calculatorId, parameterLines) => {
    const endpoint = `${config.api}api/v1/Calculations/${calculatorId}/CalculateByInputFile`;
    const options = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({ parameterLines }),
    };

    const response = await fetch(endpoint, options);
    if (response.ok) return await response.json();

    return undefined;
};

export const performCalculation = async (calculatorId, data) => {
    const endpoint = `${config.api}api/v1/Calculations/${calculatorId}`;
    const options = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
    };

    const response = await fetch(endpoint, options);
    if (response.ok) return await response.json();

    return undefined;
};
