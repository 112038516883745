import { createContext, useReducer } from "react";

const AppContext = createContext();

const initialState = {
    prismic: { document: null, state: "idle" },

    projects: [],
    calculatorDefinitions: {},
    calculatorData: {},
    calculations: {},

    glassThicknessCalculations: [],
};

const reducer = (state, action) => {
    switch (action.type) {
        case "ADD_PROJECT": {
            const projects = [...state.projects];
            projects.push(action.payload);

            return {
                ...state,
                projects,
            };
        }

        case "UPDATE_PROJECT": {
            const projects = [...state.projects];
            const project = projects.find((x) => x.id === action.payload.id);
            project.name = action.payload.name;
            project.description = action.payload.description;

            return {
                ...state,
                projects,
            };
        }

        case "REMOVE_PROJECT": {
            return {
                ...state,
                projects: state.projects.filter((x) => x.id !== action.payload),
            };
        }

        case "SET_PROJECTS": {
            return {
                ...state,
                projects: action.payload,
            };
        }

        case "SET_CALCULATOR_DEFINITION": {
            return {
                ...state,
                calculatorDefinitions: {
                    ...state.calculatorDefinitions,
                    [action.payload.id]: action.payload.definition,
                },
            };
        }

        case "SET_CALCULATOR_DATA": {
            return { ...state, calculatorData: { ...state.calculatorData, [action.payload.id]: action.payload.data } };
        }

        case "ADD_CALCULATION": {
            const calculations = { ...state.calculations };
            if (!calculations[action.payload.projectId]) {
                calculations[action.payload.projectId] = [];
            }

            calculations[action.payload.projectId].push(action.payload.calculation);

            return { ...state, calculations };
        }

        case "UPDATE_CALCULATION": {
            const calculations = { ...state.calculations };
            if (!calculations[action.payload.projectId]) {
                calculations[action.payload.projectId] = [];
            }

            const calculation = calculations[Number(action.payload.projectId)].find((x) => x.id === Number(action.payload.calculation.id));

            for (let i = 0, len = Object.keys(action.payload.calculation).length; i < len; i++) {
                const key = Object.keys(action.payload.calculation)[i];
                if (calculation[key]) {
                    calculation[key] = action.payload.calculation[key];
                }
            }

            return {
                ...state,
                calculations: calculations,
            };
        }

        case "REMOVE_CALCULATION": {
            const calculations = { ...state.calculations };
            if (!calculations[action.payload.projectId]) {
                calculations[action.payload.projectId] = [];
            }

            calculations[action.payload.projectId] = calculations[action.payload.projectId].filter((x) => x.id !== action.payload.calculationId);

            return {
                ...state,
                calculations: calculations,
            };
        }

        case "SET_CALCULATIONS": {
            return {
                ...state,
                calculations: { ...state.calculations, [action.payload.projectId]: action.payload.calculations },
            };
        }

        case "SET_GLASSTHICKNESS_CALCULATIONS": {
            return {
                ...state,
                glassThicknessCalculations: {
                    ...state.glassThicknessCalculations,
                    [action.payload.projectId]: action.payload.calculations,
                },
            };
        }

        case "ADD_GLASSTHICKNESS_CALCULATION": {
            const calculations = { ...state.glassThicknessCalculations };
            if (!calculations[action.payload.projectId]) {
                calculations[action.payload.projectId] = [];
            }

            calculations[action.payload.projectId].push(action.payload.calculation);

            return { ...state, glassThicknessCalculations: calculations };
        }

        case "UPDATE_GLASSTHICKNESS_CALCULATION": {
            const calculations = { ...state.glassThicknessCalculations };
            if (!calculations[action.payload.projectId]) {
                calculations[action.payload.projectId] = [];
            }

            const calculation = calculations[Number(action.payload.projectId)].find((x) => x.id === Number(action.payload.calculation.id));

            for (let i = 0, len = Object.keys(action.payload.calculation).length; i < len; i++) {
                const key = Object.keys(action.payload.calculation)[i];
                if (calculation[key]) {
                    calculation[key] = action.payload.calculation[key];
                }
            }

            return {
                ...state,
                glassThicknessCalculations: calculations,
            };
        }

        case "REMOVE_GLASSTHICKNESS_CALCULATION": {
            const calculations = { ...state.glassThicknessCalculations };
            if (!calculations[action.payload.projectId]) {
                calculations[action.payload.projectId] = [];
            }

            calculations[action.payload.projectId] = calculations[action.payload.projectId].filter((x) => x.id !== action.payload.calculationId);

            return {
                ...state,
                glassThicknessCalculations: calculations,
            };
        }

        case "SET_PRISMIC_DOCUMENT": {
            return { ...state, prismic: { document: action.payload.document, state: action.payload.state } };
        }

        default: {
            console.error(`Invalid action '${action.type}' supplied to AppContext.`);
            return;
        }
    }
};

const AppContextProvider = (props) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    return <AppContext.Provider value={{ state, dispatch }}>{props.children}</AppContext.Provider>;
};

export { AppContextProvider, AppContext };
