import { config } from "../_configuration/configuration";

export const getProjects = async () => {
    const endpoint = `${config.api}api/v1/Projects`;
    const options = {
        method: "GET",
    };

    const response = await fetch(endpoint, options);
    if (response.ok) return await response.json();

    return undefined;
};

export const createProject = async (project) => {
    const endpoint = `${config.api}api/v1/Projects`;
    const options = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(project),
    };

    const response = await fetch(endpoint, options);
    if (response.ok) return await response.json();

    return undefined;
};

export const updateProject = async (project) => {
    const endpoint = `${config.api}api/v1/Projects/${project.id}`;
    const options = {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(project),
    };

    const response = await fetch(endpoint, options);
    return response.ok;
};

export const deleteProject = async (projectId) => {
    const endpoint = `${config.api}api/v1/Projects/${projectId}`;
    const options = {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json",
        },
    };

    const response = await fetch(endpoint, options);
    return response.ok;
};
