import { useContext, useEffect, useState } from "react";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import {
    Button,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    ModalTitle,
    QuestionBox,
    QuestionBoxBody,
    Select,
    Text,
    useLanguage,
    usePrismic,
} from "@buildwise/ui";
import { AppContext } from "../../context/AppContextProvider";
import { createProject, getProjects } from "../../adapters/ProjectsAdapter";
import { addInputFile, createCalculation, getCalculations } from "../../adapters/CalculationsAdapter";
import { createCalculation as createGlassThicknessCalculation } from "../../adapters/GlassThicknessCalculatorAdapter";
import { config } from "../../_configuration/configuration";

import styles from "./CreateCalculationModal.module.css";

const CreateCalculationModal = ({ isOpen, onClose, calculation }) => {
    const { state, dispatch } = useContext(AppContext);

    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [hasProject, setHasProject] = useState(false);
    const [projects, setProjects] = useState([]);
    const [project, setProject] = useState([]);
    const [projectName, setProjectName] = useState("");
    const [projectDescription, setProjectDescription] = useState("");

    const [canSave, setCanSave] = useState(false);
    const [isSaving, setIsSaving] = useState(false);

    const params = useParams();
    const navigate = useNavigate();

    const { language } = useLanguage();
    const [document] = usePrismic(config.prismic.documentType);

    useEffect(() => {
        if (isOpen) {
            if (state.projects?.length > 0) return;
            getProjects().then(storeProjects);
        }
    }, [isOpen]);

    useEffect(() => {
        if (isOpen) {
            setName("");
            setDescription("");
            setProject([]);
            setProjectName("");
            setProjectDescription("");
            setCanSave(false);
            setIsSaving(false);
        }
    }, [isOpen]);

    useEffect(() => {
        if (isOpen) {
            setHasProject(Boolean(params?.projectId));
        }
    }, [isOpen]);

    useEffect(() => {
        if (isOpen) {
            const projectId = hasProject ? params.projectId : project[0]?.value;
            if (!Boolean(projectId) || projectId === -1 || state.calculations[projectId]?.length > 0) return;

            getCalculations(projectId).then((res) => storeCalculations(projectId, res));
        }
    }, [isOpen, hasProject, project]);

    useEffect(() => {
        const projects = state.projects.map((x) => ({ value: x.id, label: x.name }));
        projects.unshift({ value: -1, label: document.data.new_project_make_new_project });
        setProjects(projects);
    }, [state.projects]);

    useEffect(() => {
        if (isSaving) {
            setCanSave(false);
            return;
        }

        if (!name || name.trim().length === 0 || !validateCalculationName(name)) {
            setCanSave(false);
            return;
        }

        setCanSave(true);
    }, [name, project, projectName, isSaving]);

    const validateCalculationName = (name) => {
        const projectId = hasProject ? params.projectId : project[0]?.value;
        if (!Boolean(projectId) || projectId === -1 || !state.calculations[projectId] || state.calculations[projectId.length] === 0) return true;

        return !state.calculations[projectId].find((x) => x.name?.toLowerCase() === name?.trim()?.toLowerCase());
    };

    const validateProjectName = (name) => {
        return !state.projects.find((x) => x.name?.toLowerCase() === name?.trim()?.toLowerCase());
    };

    const onSave = () => {
        setIsSaving(true);

        const projectId = hasProject ? params.projectId : project[0]?.value;
        if (!Boolean(projectId) || projectId === -1 || !state.calculations[projectId] || state.calculations[projectId.length] === 0) {
            createProject({ name: projectName.trim(), description: projectDescription }).then(addProject).then(saveCalculation);

            return;
        }

        saveCalculation(hasProject ? params.projectId : project[0].value);
    };

    const storeProjects = (projects) => {
        if (projects) dispatch({ type: "SET_PROJECTS", payload: projects });
    };

    const storeCalculations = (projectId, calculations) => {
        if (calculations) dispatch({ type: "SET_CALCULATIONS", payload: { projectId: projectId, calculations: calculations } });
    };

    const addProject = (project) => {
        if (!project) return;

        dispatch({ type: "ADD_PROJECT", payload: project });

        return project.id;
    };

    const saveCalculation = (projectId) => {
        if (calculation.calculationAppId === 1 || calculation.calculationAppId === 2) {
            createCalculation(projectId, { ...calculation, name: name, description: description }).then((result) => {
                if (calculation.inputParameters && calculation.inputParameters.length > 0) {
                    addInputFile(projectId, result.id, calculation.inputParameters).then(() =>
                        addCalculation(projectId, {
                            ...result,
                            hasInputfile: calculation.inputParameters && calculation.inputParameters.length > 0,
                        })
                    );
                    return;
                }

                addCalculation(projectId, {
                    ...result,
                    hasInputFile: calculation.inputParameters && calculation.inputParameters.length > 0,
                });
            });
        }

        if (calculation.calculationAppId === 3) {
            createGlassThicknessCalculation(projectId, {
                ...calculation,
                title: name,
                description,
                results: calculation.calculationResults,
            }).then((result) => {
                dispatch({
                    type: "ADD_GLASSTHICKNESS_CALCULATION",
                    payload: { projectId: projectId, calculation: result },
                });

                navigate(
                    generatePath(
                        result.isMatrix
                            ? config.routes.existingGlassImportCalculationProject[language]
                            : config.routes.existingGlassCalculationProject[language],
                        {
                            lang: params.lang,
                            projectId: projectId,
                            id: result.id,
                        }
                    )
                );

                onClose && onClose();
            });
        }
    };

    const addCalculation = (projectId, calculation) => {
        if (!calculation) return;
        dispatch({ type: "ADD_CALCULATION", payload: { projectId: projectId, calculation: calculation } });

        if (calculation.calculationAppId === 1) {
            navigate(generatePath(config.routes.existingWindowsCalculation[language], { ...params, id: calculation.id }));
        }

        if (calculation.calculationAppId === 2) {
            navigate(
                generatePath(
                    calculation.hasInputfile ? config.routes.existingPanelsImportCalculation[language] : config.routes.existingPanelsCalculation[language],
                    { ...params, id: calculation.id }
                )
            );
        }

        onClose && onClose();
    };

    return (
        <Modal open={isOpen} backdrop onClose={onClose} width="small">
            <ModalHeader closeButton>
                <ModalTitle>
                    {calculation.calculationAppId === 1 && document.data.new_calculation_new_general_performance_windows}
                    {calculation.calculationAppId === 2 && document.data.new_calculation_new_general_performance_panels}
                    {calculation.calculationAppId === 3 && document.data.new_calculation_new_glass_thickness}
                </ModalTitle>
            </ModalHeader>
            <ModalBody>
                <QuestionBox style={{ display: "block" }}>
                    <QuestionBoxBody>
                        <strong className={styles.label}>{document.data.new_calculation_name}</strong>
                        <Text
                            type="text"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            placeholder={document.data.new_calculation_name_placeholder}
                            required
                            onValidate={validateCalculationName}
                            validationError={document.data.new_calculation_name_already_exists}
                        />
                        <strong className={styles.label} style={{ marginTop: "16px" }}>
                            {document.data.new_calculation_description}
                        </strong>
                        <Text
                            type="textarea"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            placeholder={document.data.new_calculation_description_placeholder}
                        />
                    </QuestionBoxBody>
                </QuestionBox>

                {!hasProject && (
                    <QuestionBox style={{ display: "block" }}>
                        <QuestionBoxBody>
                            <Select
                                label={document.data.new_calculation_project}
                                options={projects}
                                placeholder={document.data.new_calculation_determine_which_project}
                                selection={project}
                                onChangeSelection={(item) => setProject(item)}
                                style={{ maxWidth: "76%" }}
                            />

                            {project && project[0] && project[0].value === -1 && (
                                <>
                                    <strong className={styles.label} style={{ marginTop: "16px" }}>
                                        {document.data.new_project_name}
                                    </strong>
                                    <Text
                                        type="text"
                                        value={projectName}
                                        onChange={(e) => setProjectName(e.target.value)}
                                        placeholder={document.data.new_project_name_placeholder}
                                        required
                                        onValidate={validateProjectName}
                                        validationError={document.data.new_project_name_already_exists}
                                    />
                                    <strong className={styles.label} style={{ marginTop: "16px" }}>
                                        {document.data.new_project_description}
                                    </strong>
                                    <Text
                                        type="textarea"
                                        value={projectDescription}
                                        onChange={(e) => setProjectDescription(e.target.value)}
                                        placeholder={document.data.new_project_description_placeholder}
                                    />
                                </>
                            )}
                        </QuestionBoxBody>
                    </QuestionBox>
                )}
            </ModalBody>
            <ModalFooter>
                <Button id="create-calculation-modal-cancel-button" variant="tertiary" onClick={onClose}>
                    {document.data.button_common_cancel}
                </Button>
                <Button id="create-calculation-modal-save-button" onClick={onSave} disabled={!canSave}>
                    {isSaving === true ? document.data.button_common_busy_saving : document.data.button_common_save}
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default CreateCalculationModal;
