import { useContext, useEffect, useState } from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle, QuestionBox, QuestionBoxBody, Text, usePrismic } from "@buildwise/ui";
import { AppContext } from "../../context/AppContextProvider";
import { createProject, getProjects } from "../../adapters/ProjectsAdapter";

import styles from "./CreateProjectModal.module.css";
import { config } from "../../_configuration/configuration";

const CreateProjectModal = ({ isOpen, onClose }) => {
    const { state, dispatch } = useContext(AppContext);

    const [name, setName] = useState("");
    const [description, setDescription] = useState("");

    const [canSave, setCanSave] = useState(false);
    const [isSaving, setIsSaving] = useState(false);

    const [document] = usePrismic(config.prismic.documentType);

    useEffect(() => {
        if (isOpen) {
            if (state.projects?.length > 0) return;
            getProjects().then(storeProjects);
        }
    }, [isOpen]);

    useEffect(() => {
        if (isOpen) {
            setName("");
            setDescription("");
            setCanSave(true);
            setIsSaving(false);
        }
    }, [isOpen]);

    useEffect(() => {
        if (isSaving) {
            setCanSave(false);
            return;
        }

        if (!name || name.trim().length === 0 || !validateProjectName(name)) {
            setCanSave(false);
            return;
        }

        setCanSave(true);
    }, [name, isSaving]);

    const validateProjectName = (name) => {
        return !state.projects.find((x) => x.name?.toLowerCase() === name?.trim()?.toLowerCase());
    };

    const onSave = () => {
        setIsSaving(true);
        createProject({ name: name, description: description }).then(addProject);
    };

    const storeProjects = (projects) => {
        if (projects) dispatch({ type: "SET_PROJECTS", payload: projects });
    };

    const addProject = (project) => {
        if (!project) return;

        dispatch({ type: "ADD_PROJECT", payload: project });
        onClose && onClose();
    };

    return (
        <Modal open={isOpen} backdrop onClose={onClose} width="small">
            <ModalHeader closeButton>
                <ModalTitle>{document.data.new_project_title}</ModalTitle>
            </ModalHeader>
            <ModalBody>
                <QuestionBox style={{ display: "block" }}>
                    <QuestionBoxBody>
                        <strong className={styles.label}>{document.data.new_project_name}</strong>
                        <Text
                            type="text"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            placeholder={document.data.new_project_name_placeholder}
                            required
                            onValidate={validateProjectName}
                            validationError={document.data.new_project_name_already_exists}
                        />
                        <strong className={styles.label} style={{ marginTop: "16px" }}>
                            {document.data.new_project_description}
                        </strong>
                        <Text
                            type="textarea"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            placeholder={document.data.new_project_description_placeholder}
                        />
                    </QuestionBoxBody>
                </QuestionBox>
            </ModalBody>
            <ModalFooter>
                <Button id="create-project-modal-cancel-button" variant="tertiary" onClick={onClose}>
                    {document.data.button_new_project_cancel}
                </Button>
                <Button id="create-project-modal-save-button" onClick={onSave} disabled={!canSave}>
                    {isSaving ? document.data.button_new_project_busy_saving : document.data.button_new_project_save}
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default CreateProjectModal;
