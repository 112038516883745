import { Box, BoxBody, BoxTitle, Button, PanelBody, PanelFooter, usePrismic } from "@buildwise/ui";
import { Trash2, Edit } from "react-feather";
import { config } from "../../_configuration/configuration";

import styles from "./Windows.module.css";

const Result = ({ result, onEdit, onRemove }) => {
    const orderResult = result.resultItems.sort((a, b) => (a.calculationResultDefinitionId < b.calculationResultDefinitionId ? -1 : 1));
    const orderInputResult = result.inputParameters.sort((a, b) => (a.parameterId < b.parameterId ? -1 : 1));

    const [document] = usePrismic(config.prismic.documentType);

    return (
        <>
            <PanelBody>
                <Box useAccent>
                    <BoxTitle>{document.data.calculation_windows_result_description}</BoxTitle>
                    <BoxBody className={styles.results}>
                        <div className={styles.block}>
                            <p className={styles.input}>
                                <span className={styles.key}>{document.data.calculation_windows_air_permeability_class}</span>
                                <span className={styles.value}>{orderResult[0].value}</span>
                            </p>
                            <p className={styles.norm}>{document.data.calculation_windows_according_to_norm_air_permeability}</p>
                        </div>
                        <div className={styles.block}>
                            <p className={styles.input}>
                                <span className={styles.key}>{document.data.calculation_windows_water_tightness_class}</span>
                                <span className={styles.value}>{orderResult[1].value}</span>
                            </p>
                            <p className={styles.norm}>{document.data.calculation_windows_according_to_norm_water_tightness}</p>
                        </div>
                        <div className={styles.block}>
                            <p className={styles.input}>
                                <span className={styles.key}>{document.data.calculation_windows_exposure_class}</span>
                                <span className={styles.value}>{orderResult[2].value}</span>
                            </p>
                            <p className={styles.norm}>{document.data.calculation_windows_according_to_norm_external_window_work}</p>
                        </div>
                        <div className={styles.block}>
                            <p className={styles.input}>
                                <span className={styles.key}>{document.data.calculation_windows_wind_resistance_class}</span>
                                <span className={styles.value}>{orderResult[3].value}</span>
                            </p>
                            <p className={styles.norm}>{document.data.calculation_windows_according_to_norm_wind_resistance}</p>
                        </div>
                    </BoxBody>
                </Box>
                <Box>
                    <BoxTitle>{document.data.calculation_windows_based_on_properties}</BoxTitle>
                    <BoxBody className={styles.params}>
                        <p>
                            <strong>{document.data.calculation_windows_location}</strong>
                            <span>
                                {orderInputResult[1].value}: {orderInputResult[0].value} m/s
                            </span>
                        </p>
                        <p>
                            <strong>{document.data.calculation_windows_roughness_category}</strong>
                            <span>{orderInputResult[2].value}</span>
                        </p>
                        <p>
                            <strong>{document.data.calculation_windows_reference_height}</strong>
                            <span>{orderInputResult[3].value} m</span>
                        </p>
                        <p>
                            <strong>{document.data.calculation_windows_climate_control}</strong>
                            <span>{orderInputResult[4].value === "True" ? document.data.calculation_common_yes : document.data.calculation_common_no}</span>
                        </p>
                        <p>
                            <strong>{document.data.calculation_windows_water_protection}</strong>
                            <span>{orderInputResult[5].value === "True" ? document.data.calculation_common_yes : document.data.calculation_common_no}</span>
                        </p>
                    </BoxBody>
                </Box>
            </PanelBody>
            <PanelFooter>
                <Button id="windows-determination-delete-button" variant="tertiary" onClick={onRemove} startIcon={<Trash2 />}>
                    {document.data.button_common_delete_determination}
                </Button>
                <Button id="windows-determination-edit-button" variant="secondary" onClick={onEdit} startIcon={<Edit />} style={{ marginLeft: "auto" }}>
                    {document.data.button_common_edit_determination}
                </Button>
            </PanelFooter>
        </>
    );
};

export default Result;
